import { ChangeEventHandler } from 'react';
import { SalaryExpecationsCurrencyWithAny } from '../../../shared/classes/profile';
import SmCurrencySelect from './SmCurrencySelect';
import SmTextField from './SmTextField';

interface SmSalaryFieldProps {
	label: string;
	name: string;
	value: number | null;
	userLanguage: string;
	onSalaryChange: (value: number | null) => void;
	onCurrencyChange: (value: SalaryExpecationsCurrencyWithAny) => void;
	placeholder?: string;
	required?: boolean;
	notched?: boolean;
	disabled?: boolean;
	currency?: SalaryExpecationsCurrencyWithAny;
	allowAnyCurrency?: boolean;
}

export default function SmSalaryField({
	label,
	value,
	userLanguage,
	onSalaryChange,
	onCurrencyChange,
	name,
	placeholder = '',
	required = false,
	notched = true,
	disabled = false,
	currency = 'USD',
	allowAnyCurrency = false,
}: SmSalaryFieldProps) {
	const maxValue = 9999999;

	const handleNumberChange: ChangeEventHandler<
		HTMLInputElement | HTMLTextAreaElement
	> = (event) => {
		if (event.target.value === '') {
			onSalaryChange(null);
			return;
		}

		const newValue = Number(event.target.value.replace(/[,.]/g, ''));
		if (!isNaN(newValue) && newValue >= 0 && newValue <= maxValue) {
			onSalaryChange(newValue);
		}
	};

	const newValue =
		(value || value === 0) && !isNaN(Number(value))
			? Number(value).toLocaleString(userLanguage)
			: '';

	return (
		<SmTextField
			className={'max-width-300 mobile-full-width'}
			aria-label={label}
			inputProps={{
				shrink: 'true',
				inputMode: 'numeric',
				min: '0',
				style: { textAlign: 'right' },
			}}
			InputProps={{
				startAdornment: (
					<SmCurrencySelect
						disabled={disabled}
						selectedCurrency={currency}
						onChange={onCurrencyChange}
						allowAnyCurrency={allowAnyCurrency}
					/>
				),
				style: {
					backgroundColor: disabled ? '#e7e7e7' : '#fff',
				},
			}}
			id={name}
			onChange={handleNumberChange}
			placeholder={placeholder}
			value={newValue}
			name={name}
			variant="outlined"
			label={label}
			disabled={disabled}
			required={required}
			SelectProps={{ notched }}
			InputLabelProps={{ shrink: notched }}
			error={!disabled && value === 0}
		/>
	);
}
