import { EmployerTalentFilter } from './employerTalentFilter';
import { extractArray, extractBoolean, extractString } from './utils';

export class Employer {
	name: string;
	employerId: string;
	connectedUrlIds: string[];
	bookmarkedUrlIds: string[];
	hiddenUrlIds: string[];
	employerTalentFilters: EmployerTalentFilter[];
	isTestUser: boolean;

	constructor(data?: Employer) {
		this.name = extractString(data?.name);
		this.employerId = extractString(data?.employerId);
		this.connectedUrlIds = extractArray(data?.connectedUrlIds, []);
		this.bookmarkedUrlIds = extractArray(data?.bookmarkedUrlIds, []);
		this.hiddenUrlIds = extractArray(data?.hiddenUrlIds, []);
		this.employerTalentFilters = extractArray(
			data?.employerTalentFilters,
			[],
			(item) => new EmployerTalentFilter(item),
		);
		this.isTestUser = extractBoolean(data?.isTestUser);
	}
}
