import { StrictMode, useEffect } from 'react';
import TalentSidebarMenu from '../../components/LayoutComponents/SidebarComponents/TalentSidebarMenu';
import ProfileForm from '../../components/SmForms/ProfileForm';
import SmInterceptor from '../../components/SmInterceptor';
import SidebarLayout from '../../layouts/SidebarLayout';

export default function ProfileFormPage() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<StrictMode>
			<SmInterceptor />
			<SidebarLayout menu={<TalentSidebarMenu />} content={<ProfileForm />} />
		</StrictMode>
	);
}
