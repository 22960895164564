import { ScrumMaturityConfig } from '../shared/types/config';
import { baseUrl, buildHeaders, jsonHeaders, safeGet } from './utils';

const configUrl = `${baseUrl}/config`;

export const getScrumMaturity = async (): Promise<ScrumMaturityConfig> => {
	const result = await safeGet(
		`${configUrl}/scrum-maturity`,
		buildHeaders(jsonHeaders),
	);

	return result?.data ?? {};
};
