import { PathsEnum } from '../../core/enums';
import ArrowButton from '../SmButtons/ArrowButton';
import './Footer.css';

export default function Footer() {
	const contactEmail = 'hello@scrummatch.com';

	return (
		<footer className="sm-footer">
			<div className="sm-footer-content">
				<div className="sm-footer-content-section">
					<div className="sm-footer-content-title-box">
						<h2 className="sm-footer-content-title">SCRUMMATCH</h2>
					</div>
					<div className="sm-footer-content-list">
						<a href={PathsEnum.LANDING_PAGE} target="_blank" rel="noreferrer">
							<ArrowButton>for Scrum Masters</ArrowButton>
						</a>
						<a
							href={PathsEnum.LANDING_PAGE_EMPLOYERS}
							target="_blank"
							rel="noreferrer">
							<ArrowButton>for employers</ArrowButton>
						</a>
						<a
							href={PathsEnum.LANDING_PAGE_ABOUT}
							target="_blank"
							rel="noreferrer">
							<ArrowButton>Why & Who</ArrowButton>
						</a>
					</div>
				</div>

				<div className="sm-footer-content-section">
					<div className="sm-footer-content-title-box">
						<h2 className="sm-footer-content-title">LEGAL</h2>
					</div>
					<div className="sm-footer-content-list">
						<a
							href={PathsEnum.LANDING_PAGE_IMPRINT}
							target="_blank"
							rel="noreferrer">
							<ArrowButton>Legal Notice</ArrowButton>
						</a>
						<a href={PathsEnum.PRIVACY_POLICY} target="_blank" rel="noreferrer">
							<ArrowButton>Privacy Policy</ArrowButton>
						</a>
						<a
							href={PathsEnum.TALENT_TERMS_OF_USE}
							target="_blank"
							rel="noreferrer">
							<ArrowButton>Talent Terms of Service</ArrowButton>
						</a>
						<a href={PathsEnum.COOKIE_POLICY} target="_blank" rel="noreferrer">
							<ArrowButton>Cookies</ArrowButton>
						</a>
					</div>
				</div>

				<div className="sm-footer-content-section">
					<div className="sm-footer-content-title-box">
						<h2 className="sm-footer-content-title">CONTACT</h2>
					</div>
					<div className="sm-footer-content-list">
						<a href={`mailto:${contactEmail}`} target="_blank" rel="noreferrer">
							<ArrowButton>{contactEmail}</ArrowButton>
						</a>
					</div>
				</div>

				<div className="sm-footer-content-section">
					<div className="sm-footer-content-title-box">
						<h2 className="sm-footer-content-title">FOLLOW US:</h2>
					</div>
					<div className="sm-footer-content-list" id="sm-social-media-list">
						<a
							href="https://www.linkedin.com/company/scrummatch/"
							target="_blank"
							rel="noreferrer">
							<button className="sm-button sm-text-button">
								<img
									src={require('../../assets/linkedin-logo.png')}
									alt="LinkedIn logo"
								/>
							</button>
						</a>
						<a
							href="https://mastodon.social/@scrummatch"
							target="_blank"
							rel="noreferrer">
							<button className="sm-button sm-text-button">
								<img
									src={require('../../assets/mastodon-logo.png')}
									alt="Mastodon logo"
								/>
							</button>
						</a>
					</div>
				</div>
			</div>
			<div className="sm-footer-bottom">
				<p>© ScrumMatch 2023</p>
			</div>
		</footer>
	);
}
