import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	SelectProps,
} from '@mui/material';

type SmSelectProps = {
	label: string;
	id: string;
	options: string[];
	handleChange: (event: SelectChangeEvent<string>) => void;
	value?: string;
	containerClass?: string;
} & { selectProps?: SelectProps };

export default function SmSelect({
	label,
	id,
	options,
	handleChange,
	value,
	containerClass = '',
}: SmSelectProps) {
	return (
		<FormControl className={`sm-label ${containerClass}`}>
			<InputLabel htmlFor={id} variant="standard" shrink={false}>
				{label}
			</InputLabel>
			<Select
				id={id}
				value={value}
				labelId={id}
				aria-label={label}
				sx={{ backgroundColor: '#fff' }}
				onChange={handleChange}>
				{options.map((option, i) => (
					<MenuItem key={i + option} value={option}>
						{option}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}
