import { useNavigate } from 'react-router-dom';
import { PathsEnum } from '../../core/enums';
import ArrowBackButton from '../SmButtons/ArrowBackButton';

type ProfileHeaderProps = {
	isEvalPage?: boolean;
	showBackButton?: boolean;
};

export default function ProfileHeader({
	isEvalPage = false,
	showBackButton = true,
}: ProfileHeaderProps) {
	const navigate = useNavigate();

	return (
		<div className="sm-profile-preview-header">
			<div className="sm-profile-preview-header-content">
				{showBackButton ? (
					<>
						{isEvalPage ? (
							<ArrowBackButton
								className="sm-profile-preview-header-back-button"
								onClick={() => navigate(PathsEnum.REVIEWER_HOME)}>
								Back to Talents Table
							</ArrowBackButton>
						) : (
							<ArrowBackButton
								className="sm-profile-preview-header-back-button"
								onClick={() => navigate(PathsEnum.PROFILE_FORM)}>
								Back to Editing
							</ArrowBackButton>
						)}
					</>
				) : null}
				<img
					src={require('../../assets/avatar.png')}
					alt="user avatar"
					className="sm-profile-preview-header-avatar"
					style={{ left: showBackButton ? '-150px' : '0px' }}
				/>
			</div>
		</div>
	);
}
