export default function SmHeading({ title, marginTop }: { title: string, marginTop?: string }) {
	const color = '#BFBFBF';
	const topMargin = marginTop ?? '50px';
	return (
		<div
			style={{
				margin: `${topMargin} 0px 0px`,
				width: '100%',
				borderRadius: 10,
				height: 'fit-content',
			}}>
			<h3 style={{ margin: 0 }}>{title}</h3>
			<hr
				style={{
					height: '1px',
					width: '100%',
					color: color,
					backgroundColor: color,
					border: 'none',
				}}
			/>
		</div>
	);
}
