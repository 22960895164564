import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton } from '@mui/material';
import { useEffect } from 'react';
import { ProfileFieldNamesEnum } from '../../../core/enums';
import { WorkExperience } from '../../../shared/classes/profile';
import SmCheckbox from './SmCheckbox';
import SmTextField from './SmTextField';
import { StartDateInput } from './StartDateInput';

interface WorkExperienceProps {
	workExperience: WorkExperience;
	onChange: (key: keyof WorkExperience, value: any) => void;
	onRemove: () => void;
	flexColumn?: boolean;
	disabled?: boolean;
	hideRemoveButton?: boolean;
}

const SmWorkExperience = ({
	workExperience,
	onChange,
	onRemove,
	flexColumn = false,
	disabled = false,
	hideRemoveButton = false,
}: WorkExperienceProps) => {
	return (
		<div
			className="border-radius-4"
			style={{
				display: 'flex',
				flexDirection: 'column',
				padding: '30px 20px 20px',
				position: 'relative',
				margin: '15px 0',
				border: '1px solid #bbb',
			}}>
			{hideRemoveButton ? null : (
				<IconButton
					aria-label="close"
					onClick={onRemove}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}>
					<CloseIcon />
				</IconButton>
			)}
			<div
				className={`flex gap-desktop-20 ${flexColumn ? 'flex-column' : ''}`}
				style={{
					justifyContent: 'flex-start',
				}}>
				<SmTextField
					label="Role"
					value={workExperience.role}
					onChange={(e) => onChange('role', e.target.value)}
					sx={{ width: '100%' }}
					multiline
					charLimit={100}
					disabled={disabled}
				/>
				<SmTextField
					label="Industry"
					value={workExperience.industry}
					onChange={(e) => onChange('industry', e.target.value)}
					sx={{ width: '100%' }}
					placeholder="e.g., Automotive, Banking, E-Commerce"
					multiline
					charLimit={100}
					disabled={disabled}
				/>
			</div>
			<div
				className={`flex gap-desktop-20 ${flexColumn ? 'flex-column' : ''}`}
				style={{
					justifyContent: 'flex-start',
				}}>
				<StartDateInput
					label="From"
					startDate={workExperience.startDate}
					disableFuture={true}
					required={false}
					handleChange={(date) => onChange('startDate', date?.toISOString())}
					notched={true}
					format="MMMM-YYYY"
					views={['year', 'month']}
					disabled={disabled}
				/>
				<div className="flex" style={{ width: '100%' }}>
					<SmCheckbox
						checkboxLabel="Present"
						checkboxName="present"
						isChecked={workExperience.endDate === 'present'}
						handleChange={() =>
							onChange(
								'endDate',
								workExperience.endDate === 'present' ? '' : 'present',
							)
						}
						showInfoIcon={false}
						disabled={disabled}
					/>
					<StartDateInput
						label="To"
						disabled={disabled || workExperience.endDate === 'present'}
						disableFuture={true}
						startDate={workExperience.endDate}
						required={false}
						format="MMMM-YYYY"
						handleChange={(date) => onChange('endDate', date?.toISOString())}
						notched={true}
						views={['year', 'month']}
						isPresentAllowed={true}
					/>
				</div>
			</div>
			<p style={{ marginBottom: 0, color: '#666' }}>
				<strong>Top 3 key achievements</strong>
			</p>
			{workExperience.keyAchievements.map((keyAchievement, index) => (
				<SmTextField
					key={`key-achievement-${index}`}
					value={keyAchievement}
					multiline
					placeholder={
						'Value created for stakeholders, e.g. “Product revenue increased by 22%”'
					}
					charLimit={100}
					onChange={(e) => {
						const newKeyAchievements = workExperience.keyAchievements.map(
							(ka, i) => {
								if (i === index) {
									return e.target.value;
								}
								return ka;
							},
						);
						onChange('keyAchievements', newKeyAchievements);
					}}
					disabled={disabled}
				/>
			))}
		</div>
	);
};

interface SmWorkExperiencesInputProps {
	workExperiences: WorkExperience[];
	onChange: (workExperiences: WorkExperience[]) => void;
	containerStyle?: React.CSSProperties;
	flexColumn?: boolean;
	disabled?: boolean;
	title?: string;
	hideRemoveButton?: boolean;
	showExtraInfo?: boolean;
}

export default function SmWorkExperiencesInput({
	workExperiences,
	onChange,
	containerStyle,
	flexColumn = false,
	disabled = false,
	title = ProfileFieldNamesEnum.WORK_EXPERIENCE,
	hideRemoveButton = false,
	showExtraInfo = false,
}: SmWorkExperiencesInputProps) {
	useEffect(() => {
		for (const workExperience of workExperiences) {
			if (workExperience.keyAchievements.length < 3) {
				onChange(
					workExperiences.map((we) => ({
						...we,
						keyAchievements: Array.from(
							{ length: 3 },
							(_, i) => we.keyAchievements[i] || '',
						),
					})),
				);
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const updateWorkExperience = (
		index: number,
		key: keyof WorkExperience,
		value: any,
	) => {
		const newWorkExperiences = workExperiences.map((workExperience, i) => {
			if (i === index) {
				return {
					...workExperience,
					[key]: value,
				};
			}
			return workExperience;
		});
		onChange(newWorkExperiences);
	};
	return (
		<div style={containerStyle}>
			<h4>{title}</h4>
			{showExtraInfo ? (
				<p style={{ fontSize: '12px' }}>
					Describe your most impactful work experiences as a Scrum
					Master. <strong>You can show up to 3.</strong> This is optional, and
					you can choose to do this later, after you have passed the review.
				</p>
			) : null}
			{workExperiences.map((workExperience, index) => (
				<div key={`work-experience-${index}`}>
					<SmWorkExperience
						workExperience={workExperience}
						onChange={(key, value) => updateWorkExperience(index, key, value)}
						onRemove={() => {
							onChange(workExperiences.filter((_wxp, i) => i !== index));
						}}
						flexColumn={flexColumn}
						disabled={disabled}
						hideRemoveButton={hideRemoveButton}
					/>
				</div>
			))}
			{workExperiences.length < 3 && !hideRemoveButton ? (
				<div
					style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
					<Button
						onClick={() => {
							onChange([...workExperiences, new WorkExperience()]);
						}}
						variant={'outlined'}>
						Add work experience (optional)
					</Button>
				</div>
			) : null}
		</div>
	);
}
