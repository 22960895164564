import { AdminMenuItem } from '../../../pages/admin/DashboardPage';
import useUserInfoZustand from '../../../zustand/UserInfoZustand';
import SmLogoutButton from '../../SmButtons/SmLogoutButton';

interface AdminSidebarMenuProps {
	menuItems: readonly AdminMenuItem[];
	selectedMenuItem: string;
	setSelectedMenuItem: (menuItem: AdminMenuItem) => void;
}

export default function AdminSidebarMenu({
	menuItems,
	selectedMenuItem,
	setSelectedMenuItem,
}: AdminSidebarMenuProps) {
	const { userInfo } = useUserInfoZustand();
	return (
		<div className="sm-sidebar-menu">
			<div className="sm-sidebar-title">
				<h1>ScrumMatch for Admins</h1>
			</div>
			<ul>
				{menuItems.map((menuItem) => (
					<li
						key={menuItem}
						onClick={() => setSelectedMenuItem(menuItem)}
						style={{
							textDecoration:
								menuItem === selectedMenuItem ? 'underline' : 'unset',
							cursor: 'pointer',
							marginTop: 5,
						}}>
						{menuItem}
					</li>
				))}
			</ul>
			<SmLogoutButton />
			{userInfo?.email ? (
				<div>
					<p>
						Logged in as: <br />
						{userInfo?.email}
					</p>
				</div>
			) : null}
		</div>
	);
}
