import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import SaveIcon from '@mui/icons-material/Save';
import { Button } from '@mui/material';
import { Dayjs } from 'dayjs';
import { ChangeEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	deleteTalentFilter,
	fetchTalentFilters,
	saveTalentFilter,
} from '../../api/recruiters';
import { generateRandomNumberPaddedString } from '../../core/cryptoUtils';
import { PathsEnum } from '../../core/enums';
import {
	mapDropdownLabelsToScrumMaturities,
	mapDropdownLabelsToWorkModel,
	mapScrumMaturitiesToDropdownLabels,
	mapWorkModelToDropdownLabels,
	sortedDropdownLabelsWithoutRejectedAndEmpty,
} from '../../core/lookupUtils';
import { workModelLookup } from '../../core/lookups';
import { EmployerTalentFilter } from '../../shared/classes/employerTalentFilter';
import { WorkModelKeys } from '../../shared/classes/profile';
import { SmLocation } from '../../shared/classes/smLocation';
import { SmLanguage } from '../../shared/types/languages';
import useEmployerTalentFilterZustand from '../../zustand/EmployerTalentFilterZustand';
import useScrumMaturityZustand from '../../zustand/ScrumMaturityZustand';
import ExplanationLink from '../SmForms/SmFormComponents/ExplanationLink';
import SmCheckbox from '../SmForms/SmFormComponents/SmCheckbox';
import SmCheckboxDropdown from '../SmForms/SmFormComponents/SmCheckboxDropdown';
import SmInputDialog from '../SmForms/SmFormComponents/SmInputDialog';
import SmLanguageAutocomplete from '../SmForms/SmFormComponents/SmLanguagesAutocomplete';
import SmWorkLocationAutocomplete from '../SmForms/SmFormComponents/SmWorkLocationAutocomplete';
import { StartDateInput } from '../SmForms/SmFormComponents/StartDateInput';
import ContractTypeAndSalaryExpectationsFilterInput from './ContractTypeAndSalaryExpectationsFilterInput';
import SavedFilterList from './SavedFilterList';

interface RecruiterFiltersProps {
	totalShown: number;
}

export default function RecruiterFilters({
	totalShown,
}: RecruiterFiltersProps) {
	const { filter, setFilter } = useEmployerTalentFilterZustand();
	const { scrumMaturityLookup } = useScrumMaturityZustand();
	const [savedFilters, setSavedFilters] = useState<EmployerTalentFilter[]>([]);
	const [showFilterNameDialog, setShowFilterNameDialog] = useState(false);
	const [showSavedFilterList, setShowSavedFilterList] = useState(false);
	const [alreadySetUrlFilterId, setAlreadySetUrlFilterId] = useState(false);
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		if (savedFilters && savedFilters.length > 0 && !alreadySetUrlFilterId) {
			const urlFilterId = new URLSearchParams(location.search).get('filterId');
			if (urlFilterId) {
				const foundFilter = savedFilters.find(
					(filter) => filter.filterId === urlFilterId,
				);
				if (foundFilter) {
					setFilter(foundFilter);
				}
			}
			setAlreadySetUrlFilterId(true);
		}
	}, [savedFilters, location, setFilter, alreadySetUrlFilterId]);

	useEffect(() => {
		loadSavedFilters().catch((error) => console.error(error));
	}, []);

	const setFilterWithRandomId = (newFilter: EmployerTalentFilter) => {
		// deselects active filter on change
		setFilter({ ...newFilter, filterId: generateRandomNumberPaddedString(12) });
		navigate(
			{
				search: new URLSearchParams({}).toString(),
			},
			{ replace: true },
		);
	};

	const handleContractTypeChange = (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		checked: boolean,
	) => {
		const attribute = event.target.name;
		setFilterWithRandomId({
			...filter!,
			contractTypes: {
				...filter!.contractTypes,
				[attribute]: checked,
			},
		});
	};

	const handleWorkModelChange = (values: string[]) => {
		setFilterWithRandomId({
			...filter,
			workModel: mapDropdownLabelsToWorkModel(values as Array<WorkModelKeys>),
		});
	};

	const handleScrumMaturityChange = (values: string[]) =>
		setFilterWithRandomId({
			...filter,
			scrumMaturity: mapDropdownLabelsToScrumMaturities(
				scrumMaturityLookup,
				values,
			),
		});

	const handleStartDateChange = (value: Dayjs | null) => {
		try {
			const newLatestStartingDate = value?.toISOString() ?? '';
			setFilterWithRandomId({
				...filter,
				latestStartingDate: newLatestStartingDate,
			});
		} catch (e) {}
	};

	const handleIsConnectedChange = (element: ChangeEvent<HTMLInputElement>) => {
		setFilterWithRandomId({ ...filter, isConnected: element.target.checked });
	};

	const handleIsBookmarkedChange = (element: ChangeEvent<HTMLInputElement>) => {
		setFilterWithRandomId({ ...filter, isBookmarked: element.target.checked });
	};

	const handleIsHiddenChange = (element: ChangeEvent<HTMLInputElement>) => {
		setFilterWithRandomId({ ...filter, isHidden: element.target.checked });
	};

	const handleIsPermittedToWorkChange = (
		element: ChangeEvent<HTMLInputElement>,
	) => {
		setFilterWithRandomId({
			...filter,
			isPermittedToWork: element.target.checked,
		});
	};

	const handleWorkLocationsChange = (values: SmLocation[]) => {
		setFilterWithRandomId({ ...filter, preferredWorkLocations: values });
	};

	const handleLanguagesChange = (values: SmLanguage[]) => {
		setFilterWithRandomId({ ...filter, workLanguages: values });
	};

	const handleSaveFilter = async () => {
		setShowFilterNameDialog(true);
	};

	const handleClearFilters = () => {
		setFilterWithRandomId(new EmployerTalentFilter());
	};

	const handleDeleteFilter = async (filterId: string | undefined) => {
		await deleteTalentFilter(filterId ?? '');
		await loadSavedFilters();
	};

	const handleSelectSavedFilter = async (filter: EmployerTalentFilter) => {
		setFilter(filter);
		navigate(
			{
				search: new URLSearchParams({ filterId: filter.filterId }).toString(),
			},
			{ replace: true },
		);
	};

	const loadSavedFilters = async () => {
		try {
			const filters = await fetchTalentFilters();
			setSavedFilters(filters);
		} catch (error) {
			setSavedFilters([]);
		}
	};

	const handleDialogSaveFilter = async (filterName: string) => {
		if (!filterName) {
			return;
		}
		const filterToSave: EmployerTalentFilter = filter;
		filterToSave.filterId = generateRandomNumberPaddedString(12);
		filterToSave.filterName = filterName;
		setFilter(filterToSave);
		await saveTalentFilter(filterToSave);
		setSavedFilters(await fetchTalentFilters());
		setShowFilterNameDialog(false);
	};

	const scrumMaturityItemsWithoutRejectedAndEmpty =
		sortedDropdownLabelsWithoutRejectedAndEmpty(scrumMaturityLookup);

	return (
		<div className="sm-filters-container bold-labels">
			<SavedFilterList
				savedFilters={savedFilters}
				handleDeleteFilter={handleDeleteFilter}
				handleSelectSavedFilter={handleSelectSavedFilter}
				showSavedFilterList={showSavedFilterList}
				setShowSavedFilterList={setShowSavedFilterList}
			/>
			<div className="sm-form-inline-container">
				<SmWorkLocationAutocomplete
					label="Work locations (cities and countries)"
					required={false}
					containerClass="sm-filter-input"
					placeholder="Add as many as you like."
					value={filter.preferredWorkLocations}
					onChange={(values) => handleWorkLocationsChange(values)}
				/>
			</div>
			<div className="sm-form-inline-container" style={{ marginBottom: 8 }}>
				<SmLanguageAutocomplete
					label="Work languages"
					required={false}
					containerClass="sm-filter-input"
					placeholder="Add as many as you like."
					value={filter.workLanguages}
					onChange={(values) => handleLanguagesChange(values)}
				/>
			</div>
			<SmCheckboxDropdown
				items={scrumMaturityItemsWithoutRejectedAndEmpty}
				value={mapScrumMaturitiesToDropdownLabels(
					scrumMaturityLookup,
					filter.scrumMaturity,
				)}
				label="Scrum maturity"
				handleChange={handleScrumMaturityChange}
				explanationLink={
					<ExplanationLink
						onClick={() =>
							window.open(PathsEnum.LANDING_PAGE_FAQ_SCRUM_MATURITY, '_blank')
						}
					/>
				}
				boldLabel={true}
			/>
			<div className="flex flex-small-last-child tablet-flex-column gap-50 tablet-no-gap">
				<ContractTypeAndSalaryExpectationsFilterInput
					handleContractTypeChange={(event, checked) =>
						handleContractTypeChange(event, checked)
					}
					contractType={filter?.contractTypes}
					salaryExpectations={filter?.salaryExpectations}
					handleSalaryExpectationsChange={(val) => {
						setFilterWithRandomId({
							...filter,
							salaryExpectations: val,
						});
					}}
				/>
				<div className="sm-form-inline-container sm-drop-filter-container sm-form-inline-recruiter-container flex-column full-width">
					<SmCheckboxDropdown
						items={Object.values(workModelLookup)}
						value={mapWorkModelToDropdownLabels(filter.workModel)}
						label="Work model"
						handleChange={handleWorkModelChange}
						boldLabel={true}
					/>
					<StartDateInput
						label="Start date"
						containerClass="sm-date-outside-label"
						startDate={filter.latestStartingDate}
						required={false}
						handleChange={handleStartDateChange}
					/>
				</div>
			</div>

			<div className="sm-recruiter-results">
				<div className="flex-row-gap-10 flex-column-1009 mobile-gap-0">
					<div
						style={{
							display: 'flex',
							placeContent: 'center',
							width: 'fit-content',
						}}>
						<p className="sm-label-bold no-margin height-fit-content align-self-center text-align-left">
							Only show:
						</p>
					</div>
					<SmCheckbox
						isChecked={filter.isConnected}
						checkboxLabel="connected talents"
						handleChange={handleIsConnectedChange}
						noMargin={true}
					/>
					<SmCheckbox
						isChecked={filter.isBookmarked}
						checkboxLabel="bookmarked talents"
						handleChange={handleIsBookmarkedChange}
						noMargin={true}
					/>
					<SmCheckbox
						isChecked={filter.isHidden}
						checkboxLabel="hidden talents"
						handleChange={handleIsHiddenChange}
						noMargin={true}
					/>
					<SmCheckbox
						isChecked={filter.isPermittedToWork}
						checkboxLabel="does not need / has work permit"
						handleChange={handleIsPermittedToWorkChange}
						noMargin={true}
					/>
				</div>
			</div>
			<p>
				<strong>Total shown:</strong> {totalShown}
			</p>
			<div className="sm-recruiter-filter-buttons-container">
				<Button
					variant={'contained'}
					endIcon={<SaveIcon />}
					onClick={handleSaveFilter}>
					Save this filter
				</Button>
				<Button
					endIcon={<FilterListOffIcon />}
					variant={'contained'}
					onClick={handleClearFilters}>
					Clear filters
				</Button>
			</div>
			<SmInputDialog
				open={showFilterNameDialog}
				onClose={() => setShowFilterNameDialog(false)}
				onSubmit={handleDialogSaveFilter}
				title="Save this filter"
				inputLabel="Filter name"
				inputPlaceholder="e.g. On-Site in Berlin"
				submitButtonText="Save"
				cancelButtonText="Cancel"
				isErrorWhenEmpty={true}
				errorHelperText="Please enter a name for your filter before saving."
			/>
		</div>
	);
}
