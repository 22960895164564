import {
	AnonymizedFields,
	ProfileStatusesEnum,
} from '../shared/classes/profile';
import {
	EmailTemplate,
	EvalData,
	TalentForReviewers,
} from '../shared/classes/talent';
import {
	baseUrl,
	buildHeaders,
	jsonHeaders,
	safeDelete,
	safeGet,
	safePost,
	safePut,
} from './utils';

const reviewersUrl = `${baseUrl}/reviewers`;

export const isCurrentUserReviewer = async (): Promise<boolean> => {
	const result = await safeGet(`${reviewersUrl}/me`, buildHeaders(jsonHeaders));

	return result ? result.data : false;
};

export const fetchTalentsForReviewer = async (): Promise<
	TalentForReviewers[] | undefined
> => {
	const result = await safeGet(
		`${reviewersUrl}/talents`,
		buildHeaders(jsonHeaders),
	);

	return result ? result.data : undefined;
};

export const fetchTalentByUrlIdForReviewer = async (
	urlId: string,
): Promise<TalentForReviewers | undefined> => {
	const result = await safeGet(
		`${reviewersUrl}/talents/${urlId}`,
		buildHeaders(jsonHeaders),
	);

	return result ? result.data : undefined;
};

export const fetchEmailTemplatesForReviewerAndUrlId = async (
	urlId: string,
): Promise<EmailTemplate[] | undefined> => {
	const result = await safeGet(
		`${reviewersUrl}/me/email-templates/${urlId}`,
		buildHeaders(jsonHeaders),
	);

	return result ? result.data : undefined;
};

export const sendEmailFromReviewerToUrlId = async (
	urlId: string,
	emailTemplate: EmailTemplate,
) => {
	if (!urlId) return;
	const result = await safePost(
		`${reviewersUrl}/talents/${urlId}/send-email`,
		emailTemplate,
		buildHeaders(jsonHeaders),
	);

	return result ? result : undefined;
};

export const updateEvalDataByUrlId = async (
	urlId: string,
	evalData?: EvalData,
) => {
	if (!urlId) return;
	const result = await safePost(
		`${reviewersUrl}/talents/${urlId}/eval-data`,
		evalData,
		buildHeaders(jsonHeaders),
	);

	return result ? result.data : undefined;
};

export const updateStatusByUrlId = async (
	urlId: string,
	profileStatus?: ProfileStatusesEnum,
) => {
	if (!urlId) return;
	const result = await safePut(
		`${reviewersUrl}/talents/${urlId}/status`,
		{ status: profileStatus },
		buildHeaders(jsonHeaders),
	);

	return result ? result.data : undefined;
};

export const updateAnonymizedFieldsByUrlId = async (
	urlId: string,
	anonymizedFields?: AnonymizedFields,
) => {
	if (!urlId) return;
	const result = await safePut(
		`${reviewersUrl}/talents/${urlId}/anonymized-fields`,
		anonymizedFields,
		buildHeaders(jsonHeaders),
	);

	return result ? result.data : undefined;
};

export const updateEmailCommunicationsCurrentDraftByUrlId = async (
	urlId: string,
	currentDraft: EmailTemplate,
) => {
	if (!urlId) return;
	const result = await safePost(
		`${reviewersUrl}/talents/${urlId}/email-communications/current-draft`,
		currentDraft,
		buildHeaders(jsonHeaders),
	);

	return result ? result : undefined;
};

export const deleteMyReviewer = async () => {
	return await safeDelete(
		`${reviewersUrl}/me/delete`,
		buildHeaders(jsonHeaders),
	);
};

export const fetchMyRegions = async () => {
	const result = await safeGet(
		`${reviewersUrl}/me/regions`,
		buildHeaders(jsonHeaders),
	);

	return result ? result.data : undefined;
};
