import PasswordStrengthBar from 'react-password-strength-bar';

interface SmPasswordBarProps {
	password: string;
	setIsPasswordStrongEnough: (isStrong: boolean) => void;
}

export default function SmPasswordBar({
	password,
	setIsPasswordStrongEnough,
}: SmPasswordBarProps) {
	return (
		<PasswordStrengthBar
			password={password}
			onChangeScore={(score) => {
				setIsPasswordStrongEnough(score >= 3);
			}}
			scoreWords={['weak', 'weak', 'weak', 'okay', 'strong']}
		/>
	);
}
