interface ExplanationLinkProps {
	onClick: () => void;
	label?: string;
	containerClass?: string;
}

export default function ExplanationLink({
	onClick,
	label = 'What is this?',
	containerClass,
}: ExplanationLinkProps) {
	return (
		<span
			className={`cursor-pointer opacity-half font-size-small ${containerClass}`}
			onClick={onClick}>
			(<span className="text-decoration-underline ">{label}</span>)
		</span>
	);
}
