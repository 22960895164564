import { Alert, Button, Snackbar } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { deleteMyTalent, getMyLegalVersions } from '../../api/talents';
import { PathsEnum } from '../../core/enums';
import { ProfileStatusesEnum } from '../../shared/classes/profile';
import { LegalVersions } from '../../shared/types/legalVersions';
import useProfileZustand from '../../zustand/ProfileZustand';
import useUserInfoZustand from '../../zustand/UserInfoZustand';
import SmDialog from '../SmForms/SmFormComponents/SmDialog';
import SmHeading from '../SmForms/SmFormComponents/SmHeading';

export default function AccountSettings() {
	const { userInfo, logout } = useUserInfoZustand();
	const { profile, togglePauseProfile, fetchProfileStatus } =
		useProfileZustand();
	const [profileStatus, setProfileStatus] = useState<
		ProfileStatusesEnum | undefined
	>(profile?.status);
	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
	const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);
	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
	const navigate = useNavigate();
	const isPausible = profileStatus === ProfileStatusesEnum.ACTIVE;
	const [legalVersions, setLegalVersions] = useState<LegalVersions | undefined>(
		undefined,
	);

	useEffect(() => {
		if (!profile) {
			fetchProfileStatus().then((status) => {
				setProfileStatus(status);
			});
		} else {
			setProfileStatus(profile.status);
		}
	}, [fetchProfileStatus, profile]);

	useEffect(() => {
		getMyLegalVersions().then((mySpecificLegalInfo) => {
			setLegalVersions(mySpecificLegalInfo);
		});
	}, []);

	const handlePauseAccount = async () => {
		await togglePauseProfile();
		setShowDeleteConfirmation(false);
	};

	const handleDeleteAccount = async () => {
		setShowDeleteConfirmation(false);
		const result = await deleteMyTalent();
		if (result) {
			setShowDeleteSuccess(true);
		} else {
			setShowErrorSnackbar(true);
		}
	};

	const handleCloseSuccessDialog = () => {
		setShowDeleteSuccess(false);
		logout();
	};

	return (
		<div>
			<h1>Manage Account</h1>
			{userInfo?.email && userInfo?.loginProvider ? (
				<>
					<SmHeading title="Login" />
					<p>
						{userInfo?.email}
						<br />
						logged in with <strong>{userInfo?.loginProvider}</strong>
					</p>
				</>
			) : null}
			{legalVersions ? (
				<>
					<SmHeading title="Legal" />
					<p>
						{legalVersions?.termsOfUseTalent.url &&
						legalVersions?.termsOfUseTalent.acceptedAt ? (
							<>
								<a
									href={legalVersions?.termsOfUseTalent.url}
									target="_blank"
									rel="noreferrer">
									Terms of Use
								</a>
								<span className="opacity-half">
									&ensp; accepted on{' '}
									{new Date(
										legalVersions?.termsOfUseTalent.acceptedAt!,
									).toLocaleString('en-US', {
										day: 'numeric',
										month: 'long',
										year: 'numeric',
									})}
								</span>
							</>
						) : null}
						<br />
						{legalVersions?.privacyPolicy.url &&
						legalVersions?.privacyPolicy?.acceptedAt ? (
							<>
								<a
									href={legalVersions?.privacyPolicy.url}
									target="_blank"
									rel="noreferrer">
									Privacy Policy
								</a>
								<span className="opacity-half">
									&ensp; accepted on{' '}
									{new Date(
										legalVersions?.privacyPolicy.acceptedAt!,
									).toLocaleString('en-US', {
										day: 'numeric',
										month: 'long',
										year: 'numeric',
									})}
								</span>
							</>
						) : null}
					</p>
				</>
			) : null}

			{isPausible ? (
				<>
					<SmHeading title="Profile" />
					<p>
						<strong>profile status: </strong> {profileStatus}
						<br />
						Your profile is active and visible to employers.{' '}
						<Link to={PathsEnum.PROFILE_FORM}>Update my profile</Link>.
					</p>
					<SmHeading title="Pause your profile" />
					<p>
						You can pause your profile and make it invisible to employers. You
						can unpause it at any time.
					</p>
					<Button
						variant="contained"
						color="primary"
						onClick={handlePauseAccount}>
						Pause profile
					</Button>
				</>
			) : null}
			{profileStatus === ProfileStatusesEnum.INACTIVE ? (
				<>
					<SmHeading title="Profile" />
					<p>
						<strong>profile status: </strong> {profileStatus}
						<br />
						Your profile is paused and invisible to employers.
					</p>
					<SmHeading title="Unpause your profile" />
					<p>
						Your profile is currently paused. You can unpause your profile and
						make it visible to employers again at any time.
					</p>
					<Button
						variant="contained"
						color="primary"
						onClick={handlePauseAccount}>
						Unpause profile
					</Button>
				</>
			) : null}
			<SmHeading title="Delete account and all data" />
			<p>This action is permanent and cannot be undone.</p>
			<Button
				variant="contained"
				color="secondary"
				onClick={() => setShowDeleteConfirmation(true)}>
				Delete Account
			</Button>

			<div className="spacer" style={{ height: '150px' }}></div>

			<SmDialog
				open={showDeleteConfirmation}
				onClose={() => setShowDeleteConfirmation(false)}
				title={<>PERMANENTLY DELETE ACCOUNT</>}
				content={
					<>
						Are you sure you want to delete your account? This action is
						permanent and cannot be undone.
						{isPausible ? (
							<p>
								You can also pause your account and make it invisible to
								employers.{' '}
								<span
									onClick={handlePauseAccount}
									className="text-decoration-underline cursor-pointer">
									Click here to pause your account instead.
								</span>
							</p>
						) : null}
					</>
				}
				button1Text="Delete my account"
				button1OnClick={handleDeleteAccount}
				button2Text="Cancel"
				button2OnClick={() => setShowDeleteConfirmation(false)}
			/>
			<SmDialog
				open={showDeleteSuccess}
				onClose={handleCloseSuccessDialog}
				title={<>ACCOUNT DELETED</>}
				content={
					<>
						You account was successfully deleted. Thank you for using
						ScrumMatch.
					</>
				}
				button1Text="Close"
				button1OnClick={async () => {
					handleCloseSuccessDialog();
					navigate(PathsEnum.LOGIN);
				}}
			/>
			<Snackbar
				open={showErrorSnackbar}
				onClose={() => setShowErrorSnackbar(false)}>
				<Alert
					onClose={() => setShowErrorSnackbar(false)}
					severity="error"
					sx={{ width: '100%' }}>
					An error occurred. Please try again.
				</Alert>
			</Snackbar>
		</div>
	);
}
