import CloseIcon from '@mui/icons-material/Close';
import {
	Button,
	CircularProgress,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
} from '@mui/material';
import { KeyboardEvent, ReactNode, useState } from 'react';
import { SmBootstrapDialog } from '../../../core/consts';

type SmDialogProps = {
	open: boolean;
	onClose: () => void;
	title: ReactNode;
	content: ReactNode;
	button1Text: string;
	button1OnClick: () => Promise<void>;
	button2Text?: string;
	button2OnClick?: () => void;
	onKeyDown?: (event: KeyboardEvent<HTMLDivElement>) => void;
	showLoadingSpinner?: boolean;
};

export default function SmDialog({
	open,
	onClose,
	title,
	content,
	button1Text,
	button1OnClick,
	button2Text,
	button2OnClick,
	onKeyDown,
	showLoadingSpinner = false,
}: SmDialogProps) {
	const [isLoading, setIsLoading] = useState(false);

	const handleButton1Click = () => {
		setIsLoading(true);
		button1OnClick().finally(() => {
			setIsLoading(false);
		});
	};

	return (
		<SmBootstrapDialog
			disableRestoreFocus
			open={open}
			onClose={onClose}
			maxWidth="xs"
			fullWidth
			onKeyDown={onKeyDown}>
			<DialogTitle className="letter-spacing-wide uppercase">
				{title}{' '}
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>{content}</DialogContent>
			<DialogActions sx={{ margin: '0 auto 10px' }}>
				<Button
					onClick={handleButton1Click}
					variant="dialog1"
					disableElevation
					disabled={showLoadingSpinner && isLoading}>
					{showLoadingSpinner && isLoading ? (
						<CircularProgress color="inherit" size={22} />
					) : (
						button1Text
					)}
				</Button>
				{button2Text && button2OnClick ? (
					<Button
						onClick={button2OnClick}
						variant="dialog2"
						disableElevation
						disabled={showLoadingSpinner && isLoading}>
						{button2Text}
					</Button>
				) : null}
			</DialogActions>
		</SmBootstrapDialog>
	);
}
