import { StrictMode, useEffect } from 'react';
import RecruiterMenu from '../../components/LayoutComponents/SidebarComponents/RecruiterSidebarMenu';
import RecruiterTalentsList from '../../components/RecruiterComponents/RecruiterTalentsList';
import '../../components/RecruiterComponents/TalentCard.css';
import SmInterceptor from '../../components/SmInterceptor';
import { PathsEnum } from '../../core/enums';
import SidebarLayout from '../../layouts/SidebarLayout';

export default function RecruiterHomePage() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<StrictMode>
			<SmInterceptor
				redirectTo={PathsEnum.RECRUITER_LOGIN}
				redirectToIfUserNotActive={PathsEnum.RECRUITER_WAITING_PAGE}
			/>
			<SidebarLayout
				menu={<RecruiterMenu />}
				content={<RecruiterTalentsList />}
			/>
		</StrictMode>
	);
}
