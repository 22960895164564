const prefixLength = 20;
const suffixLength = 4;
const urlIdLength = prefixLength + suffixLength;

/**
 * @description Url ID is the first 20 characters of the user ID, plus the last 4 characters of the user ID.
 */
export type UrlId = string;

export const toUrlId = (userId: string): UrlId | undefined => {
	if (userId.length >= urlIdLength) {
		const urlId =
			userId.substring(0, prefixLength) +
			userId.substring(userId.length - suffixLength);
		return urlId;
	}
};

export const getPrefix = (urlId: UrlId): string | undefined => {
	if (urlId.length === urlIdLength) {
		return urlId.substring(0, prefixLength);
	}
};

export const getSuffix = (urlId: UrlId): string | undefined => {
	if (urlId.length === urlIdLength) {
		return urlId.substring(prefixLength);
	}
};
