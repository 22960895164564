import { Alert, Button, Snackbar } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { FormEventHandler, Fragment, ReactNode, useState } from 'react';
import { sendOneTimePasswordForRecruiter } from '../../../api/registration';
import useRecruiterProfileZustand from '../../../zustand/RecruiterProfileZustand';
import ScrumMatchLoginButton from '../../SmButtons/ScrumMatchLoginButton';
import SmTextField from '../SmFormComponents/SmTextField';
import SmPasswordBar from './SmPasswordBar';

interface SmLoginProps {
	handleScrumMatchLoginSuccess: (
		access_token: string,
		password: string,
	) => Promise<void>;
	isSignup?: boolean;
	disabled?: boolean;
	additionalFields?: (disabled: boolean) => ReactNode;
}

export default function SmLogin({
	handleScrumMatchLoginSuccess,
	isSignup = false,
	disabled = false,
	additionalFields = undefined,
}: SmLoginProps) {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [repeatedPassword, setRepeatedPassword] = useState('');
	const [oneTimePassword, setOneTimePassword] = useState('');
	const [showOTPField, setShowOTPField] = useState(false);
	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
	const [errorSnackbarText, setErrorSnackbarText] = useState(
		'Something went wrong.',
	);
	const [isPasswordStrongEnough, setIsPasswordStrongEnough] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const { recruiterProfile } = useRecruiterProfileZustand();
	const isValidEmail = /\S+@\S+\.\S+/.test(email);

	const isLoginCredentialsValid =
		isValidEmail && !!password && (!isSignup || isPasswordStrongEnough);

	const isPasswordMismatch =
		!!repeatedPassword && password !== repeatedPassword;

	const handleError = (error: any) => {
		if (!error?.response?.data) {
			console.log('error', error);
		}
		setErrorSnackbarText(error?.response?.data);
		setShowErrorSnackbar(true);
	};

	const sendOneTimePassword: FormEventHandler = async (event) => {
		event.preventDefault();
		try {
			setIsLoading(true);
			await sendOneTimePasswordForRecruiter(email, password, recruiterProfile);
			setShowOTPField(true);
		} catch (error: any) {
			console.log('error', error);
			handleError(error);
		} finally {
			setIsLoading(false);
		}
	};

	const loginButtonText =
		window.screen.width < 450 ? (
			<span>
				Login with
				<br />
				ScrumMatch&nbsp;
			</span>
		) : (
			'Login with ScrumMatch'
		);

	return (
		<form
			onSubmit={sendOneTimePassword}
			style={{
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
				alignItems: 'center',
			}}>
			<SmTextField
				disabled={disabled || showOTPField}
				required
				name="email"
				label="Email"
				variant="outlined"
				type="email"
				error={!!email && !isValidEmail}
				sx={{
					maxWidth: '420px',
					width: '90%',
					margin: '0 auto 20px',
				}}
				onChange={(event) => setEmail(event.target.value)}
			/>

			<SmTextField
				disabled={disabled || showOTPField}
				required
				name="password"
				label="Password"
				variant="outlined"
				type="password"
				error={isSignup && !!password && !isPasswordStrongEnough}
				sx={{
					maxWidth: '420px',
					width: '90%',
					margin: isSignup ? '20px auto 25px' : '0 auto 20px',
				}}
				onChange={(event) => setPassword(event.target.value)}
				helperText={
					isSignup ? (
						<SmPasswordBar
							password={password}
							setIsPasswordStrongEnough={setIsPasswordStrongEnough}
						/>
					) : null
				}
			/>
			{isSignup ? (
				<>
					<SmTextField
						disabled={disabled || showOTPField}
						required
						name="repeat password"
						label="Repeat password"
						variant="outlined"
						type="password"
						error={isPasswordMismatch}
						helperText={
							isPasswordMismatch ? 'The passwords are not the same.' : ' '
						}
						sx={{
							maxWidth: '420px',
							width: '90%',
							margin: '0 auto',
							position: 'relative',
							top: '-23px',
						}}
						onChange={(event) => setRepeatedPassword(event.target.value)}
					/>
					{additionalFields ? additionalFields(showOTPField) : null}
				</>
			) : null}

			{showOTPField ? (
				<>
					<SmTextField
						required
						name="one time password"
						label="one-time-code"
						variant="outlined"
						sx={{
							maxWidth: '420px',
							width: '90%',
							margin: '0 auto 20px',
						}}
						onChange={(event) => setOneTimePassword(event.target.value)}
						helperText={
							<>
								<span>We sent an email with a one-time-code to {email}. </span>
								<span
									className="text-decoration-underline cursor-pointer"
									onClick={sendOneTimePassword}>
									Resend the one-time-code.
								</span>
							</>
						}
					/>
					<ScrumMatchLoginButton
						handleSuccess={(access_token) =>
							handleScrumMatchLoginSuccess(access_token, password)
						}
						handleError={handleError}
						label={isSignup ? 'Complete sign up' : loginButtonText}
						email={email}
						password={password}
						oneTimePassword={oneTimePassword}
						disabled={disabled}
					/>
				</>
			) : (
				<>
					<Button
						style={{ maxWidth: '300px' }}
						type="submit"
						variant="contained"
						disabled={disabled || !isLoginCredentialsValid}>
						<Fragment>
							{isLoading ? (
								<CircularProgress color="inherit" size={20} />
							) : (
								'Continue'
							)}
						</Fragment>
					</Button>
				</>
			)}
			<Snackbar
				open={showErrorSnackbar}
				onClose={() => setShowErrorSnackbar(false)}>
				<Alert
					onClose={() => setShowErrorSnackbar(false)}
					severity="error"
					sx={{ width: '100%' }}>
					{errorSnackbarText}
				</Alert>
			</Snackbar>
		</form>
	);
}
