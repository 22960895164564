import {
	Checkbox,
	FormControl,
	FormHelperText,
	InputLabel,
	ListItemText,
	MenuItem,
	MenuProps,
	OutlinedInput,
	Select,
	SelectChangeEvent,
} from '@mui/material';
import { ReactNode } from 'react';

type SmCheckboxDropdownProps = {
	items: string[];
	value: string[];
	label: string;
	handleChange: (value: string[]) => void;
	containerClass?: string;
	helperText?: ReactNode;
	explanationLink?: ReactNode;
	boldLabel?: boolean;
} & { menuProps?: MenuProps };
export default function SmCheckboxDropdown({
	items,
	value,
	label,
	handleChange,
	containerClass = '',
	menuProps,
	helperText,
	explanationLink,
	boldLabel = false,
}: SmCheckboxDropdownProps) {
	const onChange = (event: SelectChangeEvent<string[]>): void => {
		const value = event.target.value;
		handleChange(typeof value === 'string' ? [value] : value);
	};

	const id = label.toLocaleLowerCase().replace(/ /g, '-');
	const smMinWidth = 280;
	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<FormControl
				sx={{ minWidth: smMinWidth }}
				className={`sm-label ${containerClass}`}>
				<InputLabel htmlFor={id} variant="standard" shrink={false}>
					{boldLabel ? <span className="sm-label-bold">{label}</span> : label}{' '}
					{explanationLink}
				</InputLabel>
				<Select
					id={id}
					labelId={id}
					aria-label={label}
					multiple
					value={value}
					onChange={onChange}
					input={<OutlinedInput />}
					displayEmpty
					sx={{ minWidth: smMinWidth }}
					renderValue={(selected) => {
						if (selected.length === 0) {
							return <span>All</span>;
						}

						return selected.join(', ');
					}}
					MenuProps={menuProps}>
					{items.map((item) => {
						const displayItem = !item || item === '-' ? 'N/A' : item;
						return (
							<MenuItem key={displayItem} value={item}>
								<Checkbox checked={value.indexOf(item) > -1} />
								<ListItemText primary={displayItem} />
							</MenuItem>
						);
					})}
				</Select>
				{helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
			</FormControl>
		</div>
	);
}
