import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Navbar.css';
import { useCookies } from 'react-cookie';
import {
	CookieUserTypeEnum,
	CookieUserTypeToHomePathMap as cookieUserTypeToHomePathMap,
	PathsEnum,
} from '../../core/enums';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

interface NavbarProps {
	navbarMenu?: JSX.Element;
	showMenuAlways?: boolean;
}

export default function Navbar({ navbarMenu }: NavbarProps) {
	const [cookies] = useCookies();
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const navigate = useNavigate();

	return (
		<nav id="sm-navbar">
			<img
				src={require('../../assets/logo.png')}
				alt="scrummatch logo"
				className="sm-navbar-image"
				onClick={() => {
					if (cookies?.sm_auth?.type) {
						if (
							typeof cookies?.sm_auth?.type === 'string' &&
							cookieUserTypeToHomePathMap[
								cookies?.sm_auth?.type as CookieUserTypeEnum
							]
						) {
							navigate(
								cookieUserTypeToHomePathMap[
									cookies?.sm_auth?.type as CookieUserTypeEnum
								],
							);
						} else {
							navigate(PathsEnum.LOGIN);
						}
					} else if (cookies?.sm_auth) {
						navigate(PathsEnum.LOGIN);
					} else {
						window.location.href = PathsEnum.LANDING_PAGE;
					}
				}}
			/>
			{navbarMenu ? (
				<div className='not-desktop'>
					{isMenuOpen ? (
						<CloseIcon
							onClick={() => setIsMenuOpen(false)}
							style={{ fontSize: '30px' }}
						/>
					) : (
						<MenuIcon
							onClick={() => setIsMenuOpen(true)}
							style={{ fontSize: '30px' }}
						/>
					)}
				</div>
			) : null}
			{navbarMenu && isMenuOpen ? navbarMenu : null}
		</nav>
	);
}
