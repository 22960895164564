import { GoogleOAuthProvider } from '@react-oauth/google';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import {
	authenticateWithGoogle,
	authenticateWithLinkedIn,
} from '../../api/auth';
import { createNewReviewer } from '../../api/registration';
import { COOKIE_CONFIG, SM_AUTH_COOKIE } from '../../core/consts';
import { CookieUserTypeEnum, PathsEnum } from '../../core/enums';
import { buildSmAuthCookie } from '../../core/utils';
import { LoginProviderEnum } from '../../shared/classes/talent';
import GoogleLoginButton from '../SmButtons/GoogleLoginButton';
import LinkedInButton from '../SmButtons/LinkedinLoginButton';
import ErrorPinkBox from './SmFormComponents/ErrorPinkBox';
import SmTextField from './SmFormComponents/SmTextField';

interface ReviewerLoginFormProps {
	isSignup?: boolean;
}

export default function ReviewerLoginForm({
	isSignup = false,
}: ReviewerLoginFormProps) {
	const [reviewerToken, setReviewerToken] = useState('');
	const [reviewerFirstName, setReviewerFirstName] = useState('');
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_cookies, setCookies, removeCookie] = useCookies([SM_AUTH_COOKIE]);
	const [isOnLinkedIn] = useState(
		window.navigator.userAgent.includes('LinkedInApp'),
	);
	const [showErrorMessage, setShowErrorMessage] = useState(false);
	const navigate = useNavigate();

	const buttonIsDisabled =
		isSignup && !(reviewerToken.trim() && reviewerFirstName.trim());

	const handleLogin = async () => {
		try {
			await createNewReviewer(reviewerToken, reviewerFirstName);
			navigate(PathsEnum.REVIEWER_HOME);
		} catch (error) {
			setShowErrorMessage(true);
			removeCookie(SM_AUTH_COOKIE, COOKIE_CONFIG);
		}
	};

	const handleGoogleLoginSuccess = async ({ code }: { code: string }) => {
		const response = await authenticateWithGoogle(code);

		const { access_token, id_token, refresh_token } = response.data;
		const tokens = { access_token, id_token, refresh_token };
		setCookies(
			SM_AUTH_COOKIE,
			buildSmAuthCookie(
				LoginProviderEnum.GOOGLE,
				CookieUserTypeEnum.REVIEWER,
				tokens,
			),
			COOKIE_CONFIG,
		);
		await handleLogin();
	};

	const handleLinkedInLoginSuccess = async (code: string) => {
		const response = await authenticateWithLinkedIn(code);
		const { access_token } = response.data;
		if (!access_token) {
			return;
		}
		const tokens = { access_token };
		setCookies(
			SM_AUTH_COOKIE,
			buildSmAuthCookie(
				LoginProviderEnum.LINKEDIN,
				CookieUserTypeEnum.REVIEWER,
				tokens,
			),
			COOKIE_CONFIG,
		);
		await handleLogin();
	};

	const handleReviewerFirstNameChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setReviewerFirstName(event.target.value);
	};

	const handleReviewerTokenChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setReviewerToken(event.target.value);
	};

	const linkedInWarning = (
		<>
			<p className={'sm-linked-in-error-text'}>
				It looks like you're trying to use ScrumMatch from within the LinkedIn
				app.
			</p>
			<p className={'sm-linked-in-error-text'}>
				Please open this link on your browser to sign up and login:
			</p>
			<a
				className={'sm-linked-in-error-text'}
				href={'https://app.scrummatch.com/'}>
				https://app.scrummatch.com
			</a>
		</>
	);

	return (
		<GoogleOAuthProvider
			clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ''}>
			<div className="flex-column flex-center">
				<h2 className="font-size-responsive-heading text-align-center">
					{isSignup ? 'Sign-up as a ScrumMatch Reviewer' : 'Reviewer login'}
				</h2>
				{isOnLinkedIn ? (
					linkedInWarning
				) : (
					<>
						{isSignup ? (
							<>
								<SmTextField
									required
									name="reviewer firstName"
									label="First name (used for email communications)"
									variant="outlined"
									sx={{
										maxWidth: '420px',
										width: '90%',
										margin: '0 auto 20px',
									}}
									onChange={handleReviewerFirstNameChange}
								/>
								<SmTextField
									required
									name="reviewer token"
									label="Reviewer token"
									variant="outlined"
									sx={{
										maxWidth: '420px',
										width: '90%',
										margin: '0 auto 20px',
									}}
									onChange={handleReviewerTokenChange}
								/>
							</>
						) : null}

						<LinkedInButton
							clientId={process.env.REACT_APP_LINKEDIN_CLIENT_ID ?? ''}
							redirectUri={`${window.location.origin}/linkedin`}
							scope="openid email"
							handleSuccess={handleLinkedInLoginSuccess}
							label={`${isSignup ? 'SIGN UP' : 'LOGIN'} WITH LINKEDIN`}
							disabled={buttonIsDisabled}
						/>
						<GoogleLoginButton
							handleSuccess={handleGoogleLoginSuccess}
							label={`${isSignup ? 'SIGN UP' : 'LOGIN'} WITH GOOGLE`}
							disabled={buttonIsDisabled}
						/>

						{showErrorMessage ? (
							<ErrorPinkBox
								errorNode={
									isSignup ? (
										<p>
											Something went wrong. Please contact support at{' '}
											<a
												href="mailto:support@scrummatch.com"
												target="_blank"
												rel="noreferrer">
												support@scrummatch.com
											</a>
										</p>
									) : (
										<p>
											We couldn't find your user, please contact support at{' '}
											<a
												href="mailto:support@scrummatch.com"
												target="_blank"
												rel="noreferrer">
												support@scrummatch.com
											</a>
										</p>
									)
								}
							/>
						) : null}

						<div className="sm-info-text">
							{isSignup ? (
								<p>
									ALREADY HAVE AN ACCOUNT?&nbsp;
									<a href={PathsEnum.REVIEWER_LOGIN}>LOGIN HERE</a>
								</p>
							) : (
								<p>
									DON'T HAVE AN ACCOUNT YET?&nbsp;
									<a href={PathsEnum.REVIEWER_SIGN_UP}>SIGN UP HERE</a>
								</p>
							)}
							<p>NEED HELP? CONTACT SUPPORT@SCRUMMATCH.COM</p>
						</div>
					</>
				)}
			</div>
		</GoogleOAuthProvider>
	);
}
