import { StrictMode } from 'react';
import RecruiterLoginForm from '../../components/SmForms/RecruiterLoginForm';
import SidebarLayout from '../../layouts/SidebarLayout';

interface RecruiterLoginPageProps {
	isSignup?: boolean;
}

export default function RecruiterLoginPage({
	isSignup = false,
}: RecruiterLoginPageProps) {
	return (
		<StrictMode>
			<SidebarLayout
				menu={undefined}
				content={<RecruiterLoginForm isSignup={isSignup} />}
				centerContent
			/>
		</StrictMode>
	);
}
