import { Button } from '@mui/material';
import useUserInfoZustand from '../../zustand/UserInfoZustand';

export default function SmLogoutButton() {
	const { logout } = useUserInfoZustand();

	return (
		<>
			<Button
				className={'sm-sidebar-logout'}
				variant={'sidebar'}
				onClick={logout}
				startIcon={
					<img src={require('../../assets/sm-logout.png')} alt={'logout'} />
				}>
				Logout
			</Button>
		</>
	);
}
