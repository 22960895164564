import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Dispatch, SetStateAction } from 'react';
import { EmployerTalentFilter } from '../../shared/classes/employerTalentFilter';
import useEmployerTalentFilterZustand from '../../zustand/EmployerTalentFilterZustand';

interface SavedFilterListProps {
	savedFilters: EmployerTalentFilter[];
	handleDeleteFilter: (filterId: string | undefined) => Promise<void>;
	handleSelectSavedFilter: (filter: EmployerTalentFilter) => void;
	showSavedFilterList: boolean;
	setShowSavedFilterList: Dispatch<SetStateAction<boolean>>;
}

export default function SavedFilterList({
	savedFilters,
	handleDeleteFilter,
	handleSelectSavedFilter,
	showSavedFilterList,
	setShowSavedFilterList,
}: SavedFilterListProps) {
	const { filter } = useEmployerTalentFilterZustand();

	return (
		<>
			{savedFilters && savedFilters.length ? (
				<div className="width-100 cursor-pointer">
					<span
						className="text-decoration-underline"
						onClick={() => setShowSavedFilterList(!showSavedFilterList)}>
						Use a saved filter
					</span>
					{showSavedFilterList ? (
						<div className="sm-mui-input-border">
							{savedFilters.map((fil) => {
								const isActiveFilter = fil.filterId === filter.filterId;
								return (
									<div
										className={`width-100 flex-space-between hover-light-grey border-radius-4 padding-vertical-5${
											isActiveFilter ? ' background-pink' : ''
										}`}
										key={fil.filterName}
										onClick={() => {
											handleSelectSavedFilter(fil);
											setShowSavedFilterList(false);
										}}>
										<span className="padding-horizontal-10 cursor-pointer overflow-wrap-anywhere">
											{fil.filterName}
										</span>
										<span
											onClick={async (event) => {
												event.stopPropagation();
												await handleDeleteFilter(fil.filterId);
											}}
											className="padding-horizontal-10 color-purple flex-center cursor-pointer">
											<DeleteForeverIcon fontSize="small" />
										</span>
									</div>
								);
							})}
						</div>
					) : null}
				</div>
			) : null}
		</>
	);
}
