import { toUrlId } from '../types/urlId';
import { AnonymousProfile, Profile, defaultProseMaxLength } from './profile';
import {
	Projection,
	ViewPermissionsEnum,
	extractArray,
	extractBoolean,
	extractDate,
	extractString,
} from './utils';

export class Talent {
	userId: string;
	email: string;
	accountType: AccountTypeEnum;
	loginProvider: LoginProviderEnum;
	profile: Profile;
	lastTermsOfServiceAcceptance: TermsOfServiceAcceptanceRecord;
	termsOfServiceHistory?: TermsOfServiceAcceptanceRecord[];
	isTestUser?: boolean;
	evalData: EvalData;
	activityLog?: ActivityLog;
	emailCommunications?: EmailCommunications;

	constructor(data?: Talent) {
		this.userId = extractString(data?.userId);
		this.email = extractString(data?.email);
		this.accountType = extractAccountType(data?.accountType);
		this.loginProvider = extractLoginProvider(data?.loginProvider);
		this.profile = new Profile(data?.profile);
		this.lastTermsOfServiceAcceptance = new TermsOfServiceAcceptanceRecord(
			data?.lastTermsOfServiceAcceptance,
		);
		this.termsOfServiceHistory = data?.termsOfServiceHistory?.map(
			(record: TermsOfServiceAcceptanceRecord) =>
				new TermsOfServiceAcceptanceRecord(record),
		);
		this.isTestUser = extractBoolean(data?.isTestUser);
		this.evalData = new EvalData(data?.evalData);
		this.activityLog = new ActivityLog(data?.activityLog);
		this.emailCommunications = new EmailCommunications(
			data?.emailCommunications,
		);
	}
}

export const ScrumMaturityValues = [
	'',
	'-1',
	'0',
	'1',
	'2',
	'3',
	'4',
	'5',
] as const;
export type ScrumMaturity = (typeof ScrumMaturityValues)[number];

export const extractScrumMaturity = (
	value: any,
	defaultValue: ScrumMaturity = ScrumMaturityValues[0],
): ScrumMaturity => {
	if (ScrumMaturityValues.includes(value)) {
		return value as ScrumMaturity;
	}
	return defaultValue;
};

export class EmailTemplate {
	name?: string;
	subject?: string;
	bodyHtml?: string;
	bodyPlainText?: string;
	senderEmail?: string;
	americasSenderEmail?: string;
	senderName?: string;
	cc?: string[];

	constructor(data?: EmailTemplate) {
		this.name = data?.name ? extractString(data?.name, '', 100000) : undefined;
		this.subject = extractString(data?.subject, '', 100000);
		this.bodyHtml = extractString(data?.bodyHtml, '', 100000);
		this.bodyPlainText = extractString(data?.bodyPlainText, '', 100000);
		this.senderEmail = extractString(data?.senderEmail, undefined);
		this.americasSenderEmail = extractString(
			data?.americasSenderEmail,
			undefined,
		);
		this.senderName = extractString(data?.senderName, undefined);
		this.cc = extractArray(data?.cc, [], (cc) => extractString(cc, ''));
	}
}

export class EmailCommunication {
	sentAt: Date;
	emailTemplate: EmailTemplate;

	constructor(data?: EmailCommunication) {
		this.sentAt = extractDate(data?.sentAt);
		this.emailTemplate = new EmailTemplate(data?.emailTemplate);
	}
}

export class EmailCommunications {
	history: EmailCommunication[];
	currentDraft: EmailTemplate;

	constructor(data?: EmailCommunications) {
		this.history = extractArray(
			data?.history,
			[],
			(item) => new EmailCommunication(item),
		);
		this.currentDraft = new EmailTemplate(data?.currentDraft);
	}
}

export class EvalData {
	evalNotes: string;
	successManagerNotes: string;
	scrumMaturity: ScrumMaturity;
	scheduledEvalDate: string;

	constructor(data?: EvalData) {
		this.evalNotes = extractString(data?.evalNotes, '', 10000);
		this.successManagerNotes = extractString(
			data?.successManagerNotes,
			'',
			10000,
		);
		this.scrumMaturity = extractScrumMaturity(data?.scrumMaturity);
		this.scheduledEvalDate = extractString(data?.scheduledEvalDate);
	}
}

export class TermsOfServiceAcceptanceRecord {
	privacyPolicyHash: string;
	termsOfUseHash: string;
	acceptedAt: Date | null;

	constructor(data?: TermsOfServiceAcceptanceRecord) {
		this.privacyPolicyHash = extractString(data?.privacyPolicyHash);
		this.termsOfUseHash = extractString(data?.termsOfUseHash);
		this.acceptedAt = extractDate(data?.acceptedAt);
	}
}

export enum AccountTypeEnum {
	TALENT = 'talent',
	EMPLOYER = 'employer',
	REVIEWER = 'reviewer',
}

export enum LoginProviderEnum {
	GOOGLE = 'google',
	LINKEDIN = 'linkedin',
	SCRUMMATCH = 'scrummatch',
}

export const isReviewerAccountType = (accountType?: AccountTypeEnum) => {
	return accountType === AccountTypeEnum.REVIEWER;
};

export const extractAccountType = (
	value: string | AccountTypeEnum | undefined,
	defaultValue: AccountTypeEnum = AccountTypeEnum.TALENT,
): AccountTypeEnum => {
	// @ts-ignore
	if (Object.values(AccountTypeEnum).includes(value)) {
		return value as AccountTypeEnum;
	}
	return defaultValue;
};

export const extractLoginProvider = (
	value: string | LoginProviderEnum | undefined,
	defaultValue: LoginProviderEnum = LoginProviderEnum.GOOGLE,
): LoginProviderEnum => {
	// @ts-ignore
	if (Object.values(LoginProviderEnum).includes(value)) {
		return value as LoginProviderEnum;
	}
	return defaultValue;
};

export class TalentForReviewers {
	userId: string;
	profile: Profile;
	evalData: EvalData;
	email: string;
	activityLog: ActivityLog;
	emailCommunications: EmailCommunications;

	constructor(data?: TalentForReviewers) {
		this.userId = extractString(data?.userId);
		this.profile = new Profile(data?.profile);
		this.evalData = new EvalData(data?.evalData);
		this.email = extractString(data?.email);
		this.activityLog = new ActivityLog(data?.activityLog);
		this.emailCommunications = new EmailCommunications(
			data?.emailCommunications,
		);
	}
}

export class TalentForRecruiters {
	isBookmarked: boolean;
	isHidden: boolean;
	talent: UnconnectedTalent | ConnectedTalent;

	constructor(data?: TalentForRecruiters) {
		this.isBookmarked = extractBoolean(data?.isBookmarked);
		this.isHidden = extractBoolean(data?.isHidden);
		this.talent =
			data?.talent?.isConnected === true
				? new ConnectedTalent(data?.talent)
				: new UnconnectedTalent(data?.talent);
	}
}

export class UnconnectedTalent {
	urlId?: string;
	submittedAt: Date | null;
	profile: AnonymousProfile;
	evalData: AnonymousReviewResults;
	isConnected: false = false;

	constructor(data?: UnconnectedTalent | Talent) {
		const typeIntersection = data as UnconnectedTalent & Talent;
		this.urlId = typeIntersection?.urlId
			? extractString(typeIntersection.urlId)
			: toUrlId(extractString(typeIntersection?.userId));
		this.submittedAt = extractDate(
			typeIntersection?.submittedAt ??
				typeIntersection?.activityLog?.submittedAt,
			null,
		);
		this.profile = new AnonymousProfile(typeIntersection.profile);
		this.evalData = new AnonymousReviewResults(typeIntersection?.evalData);
	}
}

export class ConnectedTalent {
	urlId?: string;
	submittedAt: Date | null;
	profile: Profile;
	evalData: ConnectedTalentReviewResults;
	isConnected: true = true;

	constructor(data?: ConnectedTalent | Talent) {
		const typeIntersection = data as ConnectedTalent & Talent;
		this.urlId = typeIntersection?.urlId
			? extractString(typeIntersection.urlId)
			: toUrlId(extractString(typeIntersection.userId));
		this.submittedAt = extractDate(
			typeIntersection?.submittedAt ??
				typeIntersection?.activityLog?.submittedAt,
			null,
		);
		this.profile = new Profile(typeIntersection?.profile);
		this.evalData = new ConnectedTalentReviewResults(
			typeIntersection?.evalData,
		);
	}
}

export const talentForReviewersProjection: Projection<
	keyof TalentForReviewers
> = {
	_id: 0,
	userId: 1,
	profile: 1,
	evalData: 1,
	email: 1,
	activityLog: 1,
	emailCommunications: 1,
};

export class ReviewResults {
	evalNotes: string;
	scrumMaturity: ScrumMaturity;
	successManagerNotes: string;

	constructor(data?: ReviewResults) {
		this.evalNotes = extractString(data?.evalNotes, '', 10000);
		this.scrumMaturity = extractScrumMaturity(data?.scrumMaturity);
		this.successManagerNotes = extractString(
			data?.successManagerNotes,
			'',
			10000,
		);
	}
}

export class AnonymousReviewResults {
	isAnonymous = true;
	scrumMaturity: ScrumMaturity;
	successManagerNotes: string;
	scheduledEvalDate: string;

	constructor(data?: AnonymousReviewResults) {
		this.scrumMaturity = extractScrumMaturity(data?.scrumMaturity);
		this.successManagerNotes = extractString(
			data?.successManagerNotes,
			'',
			10000,
		);
		this.scheduledEvalDate = extractString(data?.scheduledEvalDate);
	}
}

export class ConnectedTalentReviewResults {
	isConnected: true = true;
	successManagerNotes: string;
	scrumMaturity: ScrumMaturity;
	scheduledEvalDate: string;

	constructor(data?: ConnectedTalentReviewResults) {
		this.successManagerNotes = extractString(
			data?.successManagerNotes,
			'',
			10000,
		);
		this.scrumMaturity = extractScrumMaturity(data?.scrumMaturity);
		this.scheduledEvalDate = extractString(data?.scheduledEvalDate);
	}
}

class ActivityLogItem {
	type: string;
	date: Date | null;

	constructor(data?: ActivityLogItem) {
		this.type = extractString(data?.type);
		this.date = extractDate(data?.date);
	}
}

export class ActivityLog {
	createdAt: Date | null;
	submittedAt: Date | null;
	lastSavedAt: Date | null;
	evalAnswerHistory: { evalAnswer: string; submittedAt: Date }[] = [];
	history: ActivityLogItem[] = [];

	constructor(data?: ActivityLog) {
		this.createdAt = extractDate(data?.createdAt);
		this.submittedAt = extractDate(data?.submittedAt);
		this.lastSavedAt = extractDate(data?.lastSavedAt);
		this.evalAnswerHistory = extractArray(
			data?.evalAnswerHistory,
			[],
			(item) => ({
				evalAnswer: extractString(item?.evalAnswer, '', defaultProseMaxLength),
				submittedAt: extractDate(item?.submittedAt),
			}),
		);
		this.history = extractArray(
			data?.history,
			[],
			(item) => new ActivityLogItem(item),
		);
	}
}

export const reviewResultsProjection: Projection<`evalData.${keyof ReviewResults}`> =
	{
		_id: 0,
		'evalData.evalNotes': 1,
		'evalData.scrumMaturity': 1,
		'evalData.successManagerNotes': 1,
	};

export const anonymousReviewResultsProjection: Projection<`evalData.${keyof AnonymousReviewResults}`> =
	{
		_id: 0,
		'evalData.scrumMaturity': 1,
		'evalData.successManagerNotes': 1,
		'evalData.scheduledEvalDate': 1,
	};

export type ReviewResultsResponse =
	| {
			permissions: ViewPermissionsEnum.ANONYMOUS;
			reviewResults: AnonymousReviewResults;
	  }
	| {
			permissions: ViewPermissionsEnum.REVIEWER | ViewPermissionsEnum.MYSELF;
			reviewResults: ReviewResults;
	  }
	| {
			permissions: ViewPermissionsEnum.CONNECTED_RECRUITER;
			reviewResults: ConnectedTalentReviewResults;
	  };
