import { ReactNode, useState } from 'react';

export default function ShortenTextBox({ text }: { text: string }) {
	const [shortened, setShortened] = useState(true);
	const maxLength = 300;

	const expandCollapseButton = (
		<>
			{(text ?? '').length <= maxLength ? null : (
				<>
					{' '}
					<span
						className={'sm-clickable-text'}
						onClick={() => setShortened((prev) => !prev)}>
						{shortened ? 'more' : 'less'}
					</span>
				</>
			)}
		</>
	);

	const shortenText = (text: string): ReactNode => {
		return text && text.length > maxLength ? (
			<>{text.substring(0, maxLength)} ...</>
		) : (
			text
		);
	};

	const shortenedText = shortenText(text);

	return (
		<>
			{shortened ? shortenedText : text}
			{expandCollapseButton}
		</>
	);
}
