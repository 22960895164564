import { StrictMode, useEffect } from 'react';
import Footer from '../../components/LayoutComponents/Footer';
import Navbar from '../../components/LayoutComponents/Navbar';
import Sidebar from '../../components/LayoutComponents/Sidebar';
import ReviewerSidebarMenu from '../../components/LayoutComponents/SidebarComponents/ReviewerSidebarMenu';
import TalentsTable from '../../components/ReviewerComponents/TalentsTable';
import '../../components/ReviewerComponents/TalentsTable.css';
import SmInterceptor from '../../components/SmInterceptor';
import { PathsEnum } from '../../core/enums';
import ProfileLayout from '../../layouts/ProfileLayout';

export default function ReviewerTalentsTablePage() {
	useEffect(() => {
		window.addEventListener('error', (e) => {
			if (e.message.includes('ResizeObserver')) {
				const resizeObserverErrDiv = document.getElementById(
					'webpack-dev-server-client-overlay-div',
				);
				const resizeObserverErr = document.getElementById(
					'webpack-dev-server-client-overlay',
				);
				if (resizeObserverErr) {
					resizeObserverErr.setAttribute('style', 'display: none');
				}
				if (resizeObserverErrDiv) {
					resizeObserverErrDiv.setAttribute('style', 'display: none');
				}
			}
		});
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<StrictMode>
			<SmInterceptor redirectTo={PathsEnum.REVIEWER_LOGIN} />
			<ProfileLayout
				navbar={
					<Navbar
						showMenuAlways={true}
						navbarMenu={<Sidebar sidebarMenu={<ReviewerSidebarMenu />} />}
					/>
				}
				content={<TalentsTable />}
				footer={<Footer />}
			/>
		</StrictMode>
	);
}
