import { TalentTermsChangeReasons } from '../core/types';
import { Profile, ProfileResponse } from '../shared/classes/profile';
import { ReviewResults, ReviewResultsResponse } from '../shared/classes/talent';
import { SmRegionsEnum } from '../shared/enums';
import { UserInfo } from '../shared/types/UserInfoTypes';
import { LegalVersions } from '../shared/types/legalVersions';
import {
	baseUrl,
	buildHeaders,
	jsonHeaders,
	safeDelete,
	safeGet,
	safePost,
} from './utils';

const talentsUrl = `${baseUrl}/talents`;

export const isTalentLoggedIn = async (headers?: {
	headers: any;
}): Promise<boolean> => {
	const result = await safeGet(
		`${talentsUrl}/unprotected/logged-in`,
		headers ?? buildHeaders(jsonHeaders),
	);

	return result?.data === true;
};

export const getProfile = async (): Promise<Profile | undefined> => {
	const result = await safeGet(
		`${talentsUrl}/me/profile`,
		buildHeaders(jsonHeaders),
	);

	return result ? result.data : undefined;
};

export const saveProfile = async (profile?: Profile) => {
	return await safePost(
		`${talentsUrl}/me/profile/save`,
		profile,
		buildHeaders(jsonHeaders),
	);
};

export const updateProfile = async (profile?: Profile) => {
	return await safePost(
		`${talentsUrl}/me/profile/update`,
		profile,
		buildHeaders(jsonHeaders),
	);
};

export const submitProfile = async () => {
	return await safePost(
		`${talentsUrl}/me/profile/submit`,
		{},
		buildHeaders(jsonHeaders),
	);
};

// fails if profile not already in status PASSED
export const activateMyProfile = async () => {
	return await safePost(
		`${talentsUrl}/me/profile/activate`,
		{},
		buildHeaders(jsonHeaders),
	);
};

export const togglePauseProfile = async () => {
	return await safePost(
		`${talentsUrl}/me/profile/pause`,
		{},
		buildHeaders(jsonHeaders),
	);
};

export const getMyRegion = async (): Promise<SmRegionsEnum | undefined> => {
	const result = await safeGet(
		`${talentsUrl}/me/profile/region`,
		buildHeaders(jsonHeaders),
	);

	return result && result.data ? result.data : undefined;
};

export const hasSubmittedBeforePwyw = async (): Promise<boolean> => {
	const result = await safeGet(
		`${talentsUrl}/me/profile/submitted-before-pwyw`,
		buildHeaders(jsonHeaders),
	);

	return !!result && result.data === true;
};

export const acceptTermsOfService = async (headers?: { headers: any }) => {
	return await safePost(
		`${talentsUrl}/unprotected/terms`,
		{},
		buildHeaders(headers ?? jsonHeaders),
	);
};

export const getMyLegalVersions = async (): Promise<LegalVersions> => {
	const result = await safeGet(
		`${talentsUrl}/me/terms/versions`,
		buildHeaders(jsonHeaders),
	);

	return result ? result.data : undefined;
};

export const getReviewResults = async (): Promise<
	ReviewResults | undefined
> => {
	const result = await safeGet(
		`${talentsUrl}/me/review-results`,
		buildHeaders(jsonHeaders),
	);

	return result?.data ? new ReviewResults(result.data) : undefined;
};

export const getMyUrlId = async (): Promise<string | undefined> => {
	const result = await safeGet(
		`${talentsUrl}/me/url-id`,
		buildHeaders(jsonHeaders),
	);

	return result && result.data ? result.data : undefined;
};

export const getMyUserInfo = async (): Promise<UserInfo | undefined> => {
	const result = await safeGet(
		`${talentsUrl}/me/user-info`,
		buildHeaders(jsonHeaders),
	);

	return result && result.data ? result.data : undefined;
};

export const getProfileResponseByUrlId = async (
	urlId: string,
): Promise<ProfileResponse | undefined> => {
	const result = await safeGet(
		`${talentsUrl}/${urlId}/profile`,
		buildHeaders(jsonHeaders),
	);

	return result && result.data ? result.data : undefined;
};

export const getReviewResultsByUrlId = async (
	urlId: string,
): Promise<ReviewResultsResponse | undefined> => {
	const result = await safeGet(
		`${talentsUrl}/${urlId}/review-results`,
		buildHeaders(jsonHeaders),
	);

	return result && result.data ? result.data : undefined;
};

export const hasAcceptedNewestTerms = async (): Promise<boolean> => {
	const result = await safeGet(
		`${talentsUrl}/me/terms/newest`,
		buildHeaders(jsonHeaders),
		() => {},
	);

	return result ? result.data : false;
};

export const getTalentTermsChangeReasons = async (): Promise<
	TalentTermsChangeReasons | undefined
> => {
	const result = await safeGet(
		`${talentsUrl}/terms-change-reasons`,
		buildHeaders(jsonHeaders),
	);

	return result ? result.data : undefined;
};

export const deleteMyTalent = async (headers?: { headers: any }) => {
	return await safeDelete(
		`${talentsUrl}/unprotected/delete`,
		headers ?? buildHeaders(jsonHeaders),
	);
};
