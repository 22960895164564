import { GoogleOAuthProvider } from '@react-oauth/google';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import {
	authenticateWithGoogle,
	authenticateWithLinkedIn,
} from '../../api/auth';
import { createNewTalent } from '../../api/registration';
import { acceptTermsOfService, isTalentLoggedIn } from '../../api/talents';
import { COOKIE_CONFIG, SM_AUTH_COOKIE } from '../../core/consts';
import { CookieUserTypeEnum, PathsEnum } from '../../core/enums';
import { buildSmAuthCookie } from '../../core/utils';
import { LoginProviderEnum } from '../../shared/classes/talent';
import useUserInfoZustand from '../../zustand/UserInfoZustand';
import GoogleLoginButton from '../SmButtons/GoogleLoginButton';
import LinkedInButton from '../SmButtons/LinkedinLoginButton';
import ErrorPinkBox from './SmFormComponents/ErrorPinkBox';
import SmTalentTermsCheckbox from './SmFormComponents/SmTalentTermsCheckbox';
import './TalentLoginForm.css';

export default function TalentSignupForm() {
	const [, setCookies] = useCookies([SM_AUTH_COOKIE]);
	const [showErrorMessage, setShowErrorMessage] = useState(false);
	const [agreed, setAgreed] = useState(false);
	const navigate = useNavigate();
	const [isOnLinkedIn] = useState(
		window.navigator.userAgent.includes('LinkedInApp'),
	);
	const { loadUserInfo } = useUserInfoZustand();

	const handleSignup = async () => {
		try {
			if (!agreed) {
				return;
			}

			const userAlreadyExists = await isTalentLoggedIn();
			if (userAlreadyExists) {
				setShowErrorMessage(true);
				return;
			} else {
				await createNewTalent();
				await acceptTermsOfService();
				if (await isTalentLoggedIn()) {
					await loadUserInfo();
					navigate(PathsEnum.HOME);
				}
			}
		} catch (err) {
			console.log("Couldn't log in", err);
		}
	};

	const handleGoogleAuthSuccess = async ({ code }: { code: string }) => {
		const response = await authenticateWithGoogle(code);
		const { access_token, id_token, refresh_token } = response.data;
		const tokens = { access_token, id_token, refresh_token };
		setCookies(
			SM_AUTH_COOKIE,
			buildSmAuthCookie(
				LoginProviderEnum.GOOGLE,
				CookieUserTypeEnum.TALENT,
				tokens,
			),
			COOKIE_CONFIG,
		);
		await handleSignup();
	};

	const handleLinkedInAuthSuccess = async (code: string) => {
		const response = await authenticateWithLinkedIn(code);
		const { access_token } = response.data;
		if (!access_token) {
			return;
		}
		const tokens = { access_token };
		setCookies(
			SM_AUTH_COOKIE,
			buildSmAuthCookie(
				LoginProviderEnum.LINKEDIN,
				CookieUserTypeEnum.TALENT,
				tokens,
			),
			COOKIE_CONFIG,
		);
		await handleSignup();
	};

	const linkedInWarning = (
		<>
			<p className={'sm-linked-in-error-text'}>
				It looks like you're trying to use ScrumMatch from within the LinkedIn
				app.
			</p>
			<p className={'sm-linked-in-error-text'}>
				Please open this link on your browser to sign up and login:
			</p>
			<a
				className={'sm-linked-in-error-text'}
				href={'https://app.scrummatch.com/'}>
				https://app.scrummatch.com
			</a>
		</>
	);

	return (
		<GoogleOAuthProvider
			clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ''}>
			<div className="flex-column flex-center">
				<>
					<h1 className="font-size-responsive-heading text-align-center">
						Sign up and sprint to your dream&#160;job!
					</h1>
					{isOnLinkedIn ? (
						linkedInWarning
					) : (
						<>
							<p className="text-align-center" style={{ maxWidth: '506px' }}>
								ScrumMatch is a "pay what you want" service. You can choose to
								use the services for free or pay any amount you deem
								appropriate.
							</p>
							<SmTalentTermsCheckbox setAgreed={setAgreed} />
							<LinkedInButton
								clientId={process.env.REACT_APP_LINKEDIN_CLIENT_ID ?? ''}
								redirectUri={`${window.location.origin}/linkedin`}
								scope="openid email"
								handleSuccess={handleLinkedInAuthSuccess}
								label={'SIGN UP WITH LINKEDIN'}
								disabled={!agreed}
							/>
							<GoogleLoginButton
								handleSuccess={handleGoogleAuthSuccess}
								label={'SIGN UP WITH GOOGLE'}
								disabled={!agreed}
							/>
							<div className="sm-info-text">
								<p>
									ALREADY HAVE AN ACCOUNT?&nbsp;
									<a href={PathsEnum.LOGIN}>LOGIN HERE</a>
								</p>
								<p>NEED HELP? CONTACT SUPPORT@SCRUMMATCH.COM</p>
							</div>
						</>
					)}
					{showErrorMessage ? (
						<ErrorPinkBox
							errorNode={
								<p>
									Your user already exists{' '}
									<a href={PathsEnum.LOGIN} target="_blank" rel="noreferrer">
										click here to log in
									</a>
									.
								</p>
							}
						/>
					) : null}
				</>
			</div>
		</GoogleOAuthProvider>
	);
}
