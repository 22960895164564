import { extractSmLanguages, SmLanguage } from '../types/languages';
import {
	ContractType,
	ContractTypeKeys,
	SalaryExpecationsCurrencyWithAny,
	WorkModel,
	WorkModelKeys,
} from './profile';
import { SmLocation } from './smLocation';
import {
	extractScrumMaturity,
	ScrumMaturity,
	TalentForRecruiters,
} from './talent';
import {
	extractArray,
	extractBoolean,
	extractNumber,
	extractSalaryExpectationsCurrency,
	extractString,
	isFilteredForLanguages,
	isFilteredForPreferredWorkLocations,
} from './utils';

export class EmployerTalentFilter {
	filterName: string;
	filterId: string;
	freeTextSearchValue: string;
	contractTypes: ContractType;
	workModel: WorkModel;
	isConnected: boolean;
	isBookmarked: boolean;
	isHidden: boolean;
	isPermittedToWork: boolean;
	latestStartingDate: string;
	scrumMaturity: ScrumMaturity[];
	preferredWorkLocations: SmLocation[];
	workLanguages: SmLanguage[];
	salaryExpectations: SalaryExpectationsFilter;

	constructor(data?: EmployerTalentFilter) {
		this.filterName = extractString(data?.filterName);
		this.filterId = extractString(data?.filterId);
		this.freeTextSearchValue = extractString(data?.freeTextSearchValue);
		this.contractTypes = new ContractType(data?.contractTypes);
		this.workModel = new WorkModel(data?.workModel);
		this.isConnected = extractBoolean(data?.isConnected);
		this.isBookmarked = extractBoolean(data?.isBookmarked);
		this.isHidden = extractBoolean(data?.isHidden);
		this.isPermittedToWork = extractBoolean(data?.isPermittedToWork);
		this.latestStartingDate = extractString(data?.latestStartingDate);
		this.scrumMaturity = extractArray(data?.scrumMaturity, [], (item) =>
			extractScrumMaturity(item),
		);
		this.preferredWorkLocations = extractArray(
			data?.preferredWorkLocations,
			[],
			(item) => new SmLocation(item),
		);
		this.workLanguages = extractSmLanguages(data?.workLanguages);
		this.salaryExpectations = new SalaryExpectationsFilter(
			data?.salaryExpectations ?? {
				currency: 'any',
				yearlyGrossMax: null,
				hourlyGrossMax: null,
			},
		);
	}
}

export class SalaryExpectationsFilter {
	currency: SalaryExpecationsCurrencyWithAny;
	yearlyGrossMax: number | null;
	hourlyGrossMax: number | null;

	constructor(data?: SalaryExpectationsFilter) {
		this.currency =
			data?.currency === 'any'
				? 'any'
				: extractSalaryExpectationsCurrency(data?.currency, 'USD');
		this.yearlyGrossMax = extractNumber(data?.yearlyGrossMax, null);
		this.hourlyGrossMax = extractNumber(data?.hourlyGrossMax, null);
	}
}

const isFilteredByFreeText = (
	filter: EmployerTalentFilter,
	t: TalentForRecruiters,
) => {
	const lowerCaseSearchValue = filter.freeTextSearchValue.toLowerCase();
	const fullName = `${t?.talent.profile?.firstName} ${
		t.talent.profile.middleName ? t.talent.profile.middleName + ' ' : ''
	}${t.talent.profile.surnames}`;
	return fullName.toLowerCase().includes(lowerCaseSearchValue);
};

const isFilteredByContractType = (
	filter: EmployerTalentFilter,
	t: TalentForRecruiters,
) => {
	const isFilterUnset = Object.values(filter.contractTypes).every(
		(value) => value === false,
	);
	if (isFilterUnset) return true;
	return Object.entries(filter.contractTypes).some(
		([key, value]) =>
			value && t.talent.profile.contractType[key as ContractTypeKeys],
	);
};

const isFilteredByWorkModel = (
	filter: EmployerTalentFilter,
	t: TalentForRecruiters,
) => {
	const isFilterUnset = Object.values(filter.workModel).every(
		(value) => value === false,
	);
	if (isFilterUnset) return true;
	return Object.entries(filter.workModel).some(
		([key, value]) => value && t.talent.profile.workModel[key as WorkModelKeys],
	);
};

const isFilteredByCheckboxes = (
	filter: EmployerTalentFilter,
	t: TalentForRecruiters,
) => {
	const areAllUnchecked =
		!filter.isConnected && !filter.isBookmarked && !filter.isHidden;
	if (areAllUnchecked) {
		return !t.isHidden;
	} else {
		return (
			(filter.isConnected && t.talent.isConnected) ||
			(filter.isBookmarked && t.isBookmarked) ||
			(filter.isHidden && t.isHidden)
		);
	}
};

const isFilteredByIsPermittedToWork = (
	filter: EmployerTalentFilter,
	t: TalentForRecruiters,
) => {
	return !filter.isPermittedToWork || !t.talent.profile.workPermit.wouldNeedIt;
};

const isFilteredByLatestStartingDate = (
	filter: EmployerTalentFilter,
	t: TalentForRecruiters,
) => {
	if (!filter.latestStartingDate) return true;
	return (
		new Date(t.talent.profile.startDate) <= new Date(filter.latestStartingDate)
	);
};

const isFilteredByScrumMaturity = (
	filter: EmployerTalentFilter,
	t: TalentForRecruiters,
) => {
	const areAllUnchecked = filter.scrumMaturity.length === 0;
	if (areAllUnchecked) return true;
	return filter.scrumMaturity.includes(t.talent.evalData.scrumMaturity);
};

const isFilteredBySalaryExpectations = (
	filter: EmployerTalentFilter,
	t: TalentForRecruiters,
) => {
	const isFilteredByCurrency = (
		filter: EmployerTalentFilter,
		t: TalentForRecruiters,
	) => {
		if (filter.salaryExpectations.currency === 'any') return true;
		if (
			!filter.contractTypes.employment &&
			!filter.contractTypes.freelance &&
			!filter.contractTypes.supplier
		)
			return true;
		if (!t.talent.profile.salaryExpectations?.currency) return false;
		return (
			t.talent.profile.salaryExpectations.currency ===
			filter.salaryExpectations.currency
		);
	};

	const isFilteredByYearlyGrossMax = (
		filter: EmployerTalentFilter,
		t: TalentForRecruiters,
	) => {
		if (!filter.contractTypes.employment) return true;
		if (!filter.salaryExpectations.yearlyGrossMax) return true;
		if (!t.talent.profile.salaryExpectations?.yearlyGross) return true;
		return (
			t.talent.profile.salaryExpectations.yearlyGross <=
			filter.salaryExpectations.yearlyGrossMax
		);
	};

	const isFilteredByHourlyGrossMax = (
		filter: EmployerTalentFilter,
		t: TalentForRecruiters,
	) => {
		if (!filter.contractTypes.freelance && !filter.contractTypes.supplier)
			return true;
		if (!filter.salaryExpectations.hourlyGrossMax) return true;
		if (!t.talent.profile.salaryExpectations?.hourlyGross) return true;
		return (
			t.talent.profile.salaryExpectations.hourlyGross <=
			filter.salaryExpectations.hourlyGrossMax
		);
	};

	return (
		isFilteredByCurrency(filter, t) &&
		isFilteredByYearlyGrossMax(filter, t) &&
		isFilteredByHourlyGrossMax(filter, t)
	);
};

export const isMatchingTalent = (
	filter: EmployerTalentFilter,
	talentInfo: TalentForRecruiters,
): boolean => {
	return (
		isFilteredByFreeText(filter, talentInfo) &&
		isFilteredByContractType(filter, talentInfo) &&
		isFilteredByWorkModel(filter, talentInfo) &&
		isFilteredByCheckboxes(filter, talentInfo) &&
		isFilteredByIsPermittedToWork(filter, talentInfo) &&
		isFilteredByLatestStartingDate(filter, talentInfo) &&
		isFilteredByScrumMaturity(filter, talentInfo) &&
		isFilteredForPreferredWorkLocations(
			filter.preferredWorkLocations,
			talentInfo.talent.profile.preferredWorkLocations,
		) &&
		isFilteredForLanguages(
			filter.workLanguages,
			talentInfo.talent.profile.preferredWorkLanguages,
		) &&
		isFilteredBySalaryExpectations(filter, talentInfo)
	);
};
