import { Button } from '@mui/material';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PathsEnum } from '../../core/enums';
import SmDialog from '../SmForms/SmFormComponents/SmDialog';

interface GetInTouchButtonProps {
	isRecruiter: boolean;
}

export default function GetInTouchButton({
	isRecruiter,
}: GetInTouchButtonProps) {
	const urlId = useParams<{
		urlId: string;
	}>().urlId;
	const [showGetInTouchDialog, setShowGetInTouchDialog] = useState(false);
	const [showConnectWithTalentDialog, setShowConnectWithTalentDialog] =
		useState(false);
	const navigate = useNavigate();

	const handleGetInTouch = () => {
		// please leave in place
		console.log('Get In Touch');
		setShowGetInTouchDialog(true);
	};

	const handleConnectWithTalent = () => {
		console.log('connect with talent');
		setShowConnectWithTalentDialog(true);
	};

	return (
		<>
			<Button
				variant="gradient"
				color="primary"
				style={{ flexDirection: 'column' }}
				onClick={isRecruiter ? handleConnectWithTalent : handleGetInTouch}>
				Interview this Scrum Master
			</Button>
			<SmDialog
				open={showGetInTouchDialog}
				onClose={() => setShowGetInTouchDialog(false)}
				title={<>CONTACT SCRUM&nbsp;MASTER</>}
				content={
					<>
						Login to your recruiter account to contact this Scrum Master and see
						their detailed profile.
						<br />
						<br />
						Don't have a recruiter account yet?
						<br />
						<a href={PathsEnum.LANDING_PAGE_EMPLOYERS}>
							Contact ScrumMatch to start hiring true Scrum Masters that
							actually deliver value.
						</a>
					</>
				}
				button1Text="Recruiter Login"
				button1OnClick={async () => navigate(PathsEnum.RECRUITER_LOGIN)}
				button2Text="Contact ScrumMatch"
				button2OnClick={() =>
					(window.location.href = PathsEnum.LANDING_PAGE_EMPLOYERS)
				}
			/>
			<SmDialog
				open={showConnectWithTalentDialog}
				onClose={() => setShowConnectWithTalentDialog(false)}
				title={<>CONNECT WITH SCRUM&nbsp;MASTER</>}
				content={
					<>
						You can connect with this Scrum Master to see all of their
						information. Once you send your connection request, the Scrum Master
						will be able to accept or decline.
						<br />
						<br />
						We will notifiy you once they have responded and you can view their
						full profile.
						<br />
						<br />
						<span className="opacity-half font-size-small">
							Hint: Please click “Connect” to open your email client and inform
							us of your interview request. If your browser settings prevent
							this, please email employer-success@scrummatch.com and include
							this ID in the email: {urlId}.
						</span>
					</>
				}
				button1Text="Connect"
				button1OnClick={async () => {
					window.open(
						`mailto:employer-success@scrummatch.com?subject=Connect with Scrum Master&body=Hi ScrumMatch, I would like to connect with this Scrum Master: ${urlId}`,
						'_blank',
					);
				}}
				button2Text="Maybe later"
				button2OnClick={() => setShowConnectWithTalentDialog(false)}
			/>
		</>
	);
}
