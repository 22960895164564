import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@mui/material';

type OutlinedBoxProps = {
	title: string;
	slot: any;
	classes?: string;
	tooltip?: string;
	noPadding?: boolean;
};
export default function OutlinedBox({
	title,
	slot,
	classes,
	tooltip,
	noPadding,
}: OutlinedBoxProps) {
	return (
		<div className={noPadding ? '' : 'sm-profile-preview-padding-x'}>
			<div className={classes + ' sm-profile-preview-box'}>
				<div style={{ width: '100%' }}>
					{title ? (
						<div style={{ display: 'flex', gap: '10px' }}>
							<h2>{title}</h2>
							{tooltip ? (
								<Tooltip title={tooltip} arrow>
									<InfoIcon
										className={'sm-scrum-maturity-display-info-icon'}
										style={{
											alignSelf: 'center',
											height: '16px',
											width: '16px',
											color: '#797979',
										}}
									/>
								</Tooltip>
							) : null}
						</div>
					) : null}
					<div>{slot}</div>
				</div>
			</div>
		</div>
	);
}
