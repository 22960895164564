import { FormControl, Input, MenuItem, Select, SxProps } from '@mui/material';
import {
	SalaryExpecationsCurrencyWithAny,
	SalaryExpectationsCurrency,
	salaryExpectationsCurrencies,
} from '../../../shared/classes/profile';

interface SmSalaryExpectationsInputProps {
	onChange: (newSalaryExpectations: SalaryExpecationsCurrencyWithAny) => void;
	selectedCurrency: SalaryExpecationsCurrencyWithAny;
	disabled?: boolean;
	sx?: SxProps;
	allowAnyCurrency?: boolean;
}

export default function SmCurrencySelect({
	onChange,
	selectedCurrency = 'USD',
	disabled = false,
	sx,
	allowAnyCurrency,
}: SmSalaryExpectationsInputProps) {
	const handleCurrencyChange = (value: SalaryExpectationsCurrency) => {
		onChange(value);
	};
	const defaultCurrency = allowAnyCurrency ? 'any' : 'USD';
	const currencies = allowAnyCurrency
		? ['any', ...salaryExpectationsCurrencies]
		: salaryExpectationsCurrencies;

	return (
		<FormControl sx={{ ...sx, minWidth: 70, maxWidth: 70 }} disabled={disabled}>
			{/* <InputLabel id="sm-currency">{currencyLabel}</InputLabel> */}
			<Select
				labelId="sm-currency"
				id="sm-currency"
				value={selectedCurrency ?? defaultCurrency}
				onChange={(event) =>
					handleCurrencyChange(event.target.value as SalaryExpectationsCurrency)
				}
				style={{ backgroundColor: disabled ? '#e7e7e7' : '#fff' }}
				input={<Input />}>
				{currencies.map((currency) => (
					<MenuItem key={currency} value={currency}>
						{currency}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}
