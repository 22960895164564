import { Switch } from '@mui/material';

type SmSwitchProps = React.DetailedHTMLProps<
	React.HTMLAttributes<HTMLDivElement>,
	HTMLDivElement
> & {
	value: boolean;
	handleChange: (
		event: React.ChangeEvent<HTMLInputElement>,
		checked: boolean,
	) => void;
	onLabel: string;
	offLabel: string;
};

export default function SmSwitch({
	offLabel,
	onLabel,
	handleChange,
	value,
	...divProps
}: SmSwitchProps) {
	return (
		<div {...divProps} style={{ display: 'flex', alignItems: 'center' }}>
			<p>{offLabel}</p>
			<Switch value={value} onChange={handleChange} />
			<p>{onLabel}</p>
		</div>
	);
}
