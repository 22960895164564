import { toSha256 } from '../core/cryptoUtils';
import { RecruiterProfile } from '../shared/classes/recruiterProfile';
import { baseUrl, buildHeaders, jsonHeaders, safePost } from './utils';

const registrationUrl = `${baseUrl}/registration`;

export const createNewTalent = async () => {
	return await safePost(
		`${registrationUrl}/newtalent`,
		{},
		buildHeaders(jsonHeaders),
	);
};

export const createNewReviewer = async (reviewerToken: string, firstName?: string) => {
	return await safePost(
		`${registrationUrl}/newreviewer`,
		{ reviewerToken, firstName },
		buildHeaders(jsonHeaders),
	);
};

export const sendOneTimePasswordForRecruiter = async (
	email: string,
	password: string,
	recruiterProfile: RecruiterProfile | null = null,
	isTestUser?: boolean,
) => {
	return await safePost(
		`${registrationUrl}/recruiters/account-verification`,
		{
			email,
			password: await toSha256(password),
			recruiterProfile,
			isTestUser,
		},
		buildHeaders(jsonHeaders),
	);
};
