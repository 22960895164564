import { useEffect } from 'react';
import ReviewerLoginForm from '../../components/SmForms/ReviewerLoginForm';
import SidebarLayout from '../../layouts/SidebarLayout';

interface ReviewerLoginPageProps {
	isSignup?: boolean;
}

export default function ReviewerLoginPage({
	isSignup = false,
}: ReviewerLoginPageProps) {

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<SidebarLayout
			menu={undefined}
			content={<ReviewerLoginForm isSignup={isSignup} />}
			centerContent
		/>
	);
}
