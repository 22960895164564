import { ErrorOutlined } from '@mui/icons-material';
import { ReactNode } from 'react';
import OutlinedBox from '../../TalentComponents/OutlinedBox';

export default function ErrorPinkBox({
	errorNode,
	optionsNode,
}: {
	errorNode: ReactNode;
	optionsNode?: ReactNode;
}) {
	return (
		<OutlinedBox
			title=""
			classes="sm-profile-preview-pink-outline-box sm-recruiter-login-form-error-message"
			slot={
				<>
					<div style={{ display: 'flex', gap: '10px' }}>
						<ErrorOutlined
							style={{
								alignSelf: 'center',
								color: '#b34090',
							}}
						/>
						{errorNode}
					</div>
					{optionsNode}
				</>
			}
		/>
	);
}
