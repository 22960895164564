import { StrictMode, useEffect } from 'react';
import TalentSignupForm from '../../components/SmForms/TalentSignupForm';
import SidebarLayout from '../../layouts/SidebarLayout';
import './TalentLoginPage.css';

export default function TalentSignupPage() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<StrictMode>
			<SidebarLayout
				menu={undefined}
				content={<TalentSignupForm />}
				centerContent
			/>
		</StrictMode>
	);
}
