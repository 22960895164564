import Footer from '../components/LayoutComponents/Footer';
import Navbar from '../components/LayoutComponents/Navbar';
import Sidebar from '../components/LayoutComponents/Sidebar';
import './SidebarLayout.css';

interface SidebarLayoutProps {
	menu: JSX.Element | undefined;
	content: JSX.Element;
	footer?: JSX.Element;
	centerContent?: boolean;
}
export default function  SidebarLayout({
	content,
	menu,
	footer = <Footer />,
	centerContent = false,
}: SidebarLayoutProps) {
	const navbar = (
		<Navbar
			navbarMenu={menu ? <Sidebar sidebarMenu={menu} /> : undefined}
		/>
	);
	const sidebar = <Sidebar sidebarMenu={menu} />;

	return (
		<div className="sm-sidebar-and-form">
			<div className="sm-sidebar-and-form-navbar">{navbar}</div>
			<div className="sm-sidebar-and-form-body">
				<div className="sm-sidebar">{sidebar}</div>
				<div
					className={`sm-sidebar-and-form-body-form${
						centerContent ? ' sm-sidebar-and-form-body-form-centered' : ''
					}`}>
					{content}
				</div>
			</div>
			{footer ? <div className={'sm-footer'}>{footer}</div> : null}
		</div>
	);
}
