import { StrictMode, useEffect, useState } from 'react';
import { getReviewResults } from '../../api/talents';
import { ReviewResults } from '../../shared/classes/talent';
import ReviewNotesDisplay from './ReviewNotesDisplay';
import './ReviewView.css';
import ScrumMaturityDisplay from './ScrumMaturityDisplay';

export default function ReviewView() {
	const [reviewResults, setReviewResults] = useState<
		ReviewResults | undefined
	>();

	useEffect(() => {
		getReviewResults()
			.then((results) =>
				setReviewResults(results ? new ReviewResults(results) : undefined),
			)
			.catch((error) => console.log('review results error 3: ', error));
	}, []);

	return (
		<StrictMode>
			<h1>Review Results</h1>
			{reviewResults && reviewResults?.evalNotes ? (
				<div className={'sm-review-view-container'}>
					{reviewResults?.evalNotes &&
					reviewResults?.scrumMaturity &&
					reviewResults.scrumMaturity !== '-1' ? (
						<ScrumMaturityDisplay
							scrumMaturity={reviewResults.scrumMaturity}
							style={{ justifySelf: 'center' }}
						/>
					) : null}
					{reviewResults?.evalNotes ? (
						<ReviewNotesDisplay evalNotes={reviewResults.evalNotes} noPadding />
					) : null}
					<div />
				</div>
			) : (
				<div className={'sm-review-view-container'}>
					<p>
						You can see the results of your review here once it is finished.
					</p>
				</div>
			)}
		</StrictMode>
	);
}
