import { SmLocation } from '../shared/classes/smLocation';
import { baseUrl, buildHeaders, jsonHeaders, safePost } from './utils';

const locationsUrl = `${baseUrl}/locations`;

export const findLocations = async (
	location: string,
): Promise<SmLocation[]> => {
	const result = await safePost(
		`${locationsUrl}/`,
		{ location },
		buildHeaders(jsonHeaders),
	);
	return result?.data ?? [];
};
