import { Button, Step, Stepper } from '@mui/material';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { SM_AUTH_COOKIE } from '../../../core/consts';
import { PathsEnum, StepStatusEnum } from '../../../core/enums';
import {
	ProfileStatusesEnum,
	canSeePaymentLink,
} from '../../../shared/classes/profile';
import useProfileZustand from '../../../zustand/ProfileZustand';
import SmLogoutButton from '../../SmButtons/SmLogoutButton';
import LoginInfo from './LoginInfo';
import './SidebarMenu.css';
import StepIcon from './StepIcon';

const step0Statuses = [
	ProfileStatusesEnum.FEEDBACK_GIVEN,
	ProfileStatusesEnum.DRAFT,
	ProfileStatusesEnum.FAILED,
];
const step1Statuses = [
	ProfileStatusesEnum.SUBMITTED,
	ProfileStatusesEnum.SCHEDULING,
	ProfileStatusesEnum.SCHEDULED,
];
const step2Statuses = [ProfileStatusesEnum.PASSED, ProfileStatusesEnum.ACTIVE];
const step4Statuses = [
	ProfileStatusesEnum.MATCHED,
	ProfileStatusesEnum.HIRED_ELSEWHERE,
];

export default function TalentSidebarMenu() {
	const [activeStep, setActiveStep] = useState(-1);
	const navigate = useNavigate();
	const [cookies] = useCookies([SM_AUTH_COOKIE]);
	const { profile, fetchProfileStatus } = useProfileZustand();
	const [profileStatus, setProfileStatus] = useState<
		ProfileStatusesEnum | undefined
	>(profile?.status);

	const supportEmail = 'support@scrummatch.com';

	useEffect(() => {
		if (!profile) {
			fetchProfileStatus().then((status) => {
				setProfileStatus(status);
			});
		} else {
			setProfileStatus(profile.status);
		}
	}, [fetchProfileStatus, profile]);

	useEffect(() => {
		let step = -1;
		if (profileStatus) {
			if (step0Statuses.includes(profileStatus)) {
				step = 0;
			}
			if (step1Statuses.includes(profileStatus)) {
				step = 1;
			}
			if (step2Statuses.includes(profileStatus)) {
				step = 2;
			}
			if (step4Statuses.includes(profileStatus)) {
				step = 4;
			}
		}
		setActiveStep(step);
	}, [profileStatus]);

	const steps = ['Profile', 'Review', 'Interviews', 'Job offers'];

	const getOnClick = (index: number): (() => void) => {
		switch (index) {
			case 0:
				return () => navigate(PathsEnum.PROFILE_FORM);
			case 1:
				return () => navigate(PathsEnum.REVIEW);
			// case 2:
			// 	return () => navigate(Paths.INTERVIEWS);
			// case 3:
			// 	return () => navigate(Paths.JOB_OFFERS);
			default:
				return () => {};
		}
	};

	return (
		<div className="sm-sidebar-menu">
			{cookies[SM_AUTH_COOKIE] ? (
				<>
					<Button
						onClick={() => navigate(PathsEnum.HOME)}
						variant="sidebar"
						startIcon={
							<img
								src={require('../../../assets/sm-home.png')}
								alt="home button symbol"
							/>
						}>
						Home
					</Button>

					<Button
						onClick={() => navigate(PathsEnum.ACCOUNT)}
						variant="sidebar"
						startIcon={
							<img
								src={require('../../../assets/sm-settings.png')}
								alt="account button symbol"
							/>
						}>
						Account Settings
					</Button>

					<div className="sm-sidebar-menu-stepper">
						<Stepper activeStep={activeStep} orientation="vertical">
							{steps.map((label, index) => {
								const stepStatus =
									index > activeStep
										? StepStatusEnum.OPEN
										: index === activeStep
											? StepStatusEnum.IN_PROGRESS
											: StepStatusEnum.DONE;
								const stepProps: { completed?: boolean } = {};
								return (
									<Step key={label} {...stepProps}>
										<Button
											onClick={getOnClick(index)}
											className="sm-sidebar-step-button"
											variant="sidebar"
											startIcon={<StepIcon stepStatus={stepStatus} />}>
											{label}
										</Button>
									</Step>
								);
							})}
						</Stepper>
					</div>

					<SmLogoutButton />

					{canSeePaymentLink(profileStatus) ? (
						<div className="sm-info-text sm-sidebar-text">
							<p className="no-margin">
								<a
									className="color-white"
									href={
										'https://scrummatch.com/en/support/did-we-deliver-value'
									}
									target="_blank"
									rel={'noreferrer'}>
									Did we deliver value?
								</a>
							</p>
						</div>
					) : null}
					<LoginInfo />

					<div className="sm-info-text sm-sidebar-text margin-bottom-50">
						<p>
							NEED HELP?{' '}
							<a
								className="color-white"
								href={`mailto:${supportEmail}`}
								target="_blank"
								rel="noreferrer">
								CONTACT&nbsp;US
							</a>
						</p>
					</div>
				</>
			) : (
				<SmLogoutButton />
			)}
		</div>
	);
}
