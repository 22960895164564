import { Autocomplete, Button, Card, CardContent } from '@mui/material';
import { useState } from 'react';
import { activateRecruiter } from '../../api/admin';
import { Employer } from '../../shared/classes/employer';
import { Recruiter } from '../../shared/classes/recruiter';
import SmTextField from '../SmForms/SmFormComponents/SmTextField';

interface PendingRecruiterListItemProps {
	employers: Employer[];
	recruiter: Recruiter;
	reloadRecruiters: () => void;
}

export default function PendingRecruiterListItem({
	employers,
	recruiter,
	reloadRecruiters,
}: PendingRecruiterListItemProps) {
	const [selectedEmployer, setSelectedEmployer] = useState<Employer | null>(
		null,
	);

	const handleActivate = async () => {
		if (!selectedEmployer) return;
		await activateRecruiter(recruiter.userId, selectedEmployer.employerId);
		reloadRecruiters();
	};

	return (
		<Card className={`sm-card`}>
			<CardContent>
				<h2>
					{recruiter.recruiterProfile?.firstName}{' '}
					{recruiter.recruiterProfile?.surnames}
				</h2>
				<p>Email: {recruiter.email}</p>
				<p>
					Company name: {recruiter.recruiterProfile?.companyName} &emsp; Company
					website:{' '}
					<a href={recruiter.recruiterProfile?.companyWebsite}>
						{recruiter.recruiterProfile?.companyWebsite}
					</a>
				</p>
				<Autocomplete
					style={{ maxWidth: 500, minWidth: 300 }}
					options={employers}
					getOptionLabel={(option) => `${option.name} (${option.employerId})`}
					value={selectedEmployer}
					onChange={(_, value) => setSelectedEmployer(value)}
					renderInput={(params) => (
						<SmTextField
							className={
								'sm-profile-form-single-line-input-field sm-input-label'
							}
							required
							{...params}
							label={'Employer'}
							type="text"
							SelectProps={{ notched: true }}
							InputLabelProps={{ shrink: true }}
						/>
					)}
				/>
				<p>
					<Button
						variant={'contained'}
						disabled={!selectedEmployer}
						onClick={handleActivate}>
						Activate
					</Button>
				</p>
			</CardContent>
		</Card>
	);
}
