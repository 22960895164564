import { Theme } from '@emotion/react';
import { SxProps } from '@mui/material';
import { MuiChipsInput, MuiChipsInputChip } from 'mui-chips-input';
import {
	Dispatch,
	KeyboardEventHandler,
	ReactNode,
	SetStateAction,
	useEffect,
	useState,
} from 'react';

interface SmChipInputProps {
	handleChange: (preferredWorkLocations: string) => void;
	inputValue: string;
	setInputValue: Dispatch<SetStateAction<string>>;
	validateChip: (workLanguage: string) => {
		isError: boolean;
		textError: string;
	};
	value?: string;
	label: string | ReactNode;
	placeholder?: string;
	required?: boolean;
	notched?: boolean;
	sx?: SxProps<Theme> | undefined;
}

export default function SmChipInput({
	handleChange,
	inputValue,
	setInputValue,
	validateChip,
	value,
	label,
	placeholder = 'Press enter after each.',
	required = true,
	notched = false,
	sx,
}: SmChipInputProps) {
	const [chips, setChips] = useState<MuiChipsInputChip[]>([]);
	const requiredButEmpty = required && (!chips || chips.length < 1);

	useEffect(() => {
		setChips(value ? value.split(';') : []);
	}, [value]);

	const handleChipUpdate = (newChips: MuiChipsInputChip[]) => {
		const newWorkLanguages = newChips.join(';');
		handleChange(newWorkLanguages);
	};

	const catchNext: KeyboardEventHandler<HTMLDivElement> = (event) => {
		if (
			inputValue !== '' &&
			(event.key === 'Tab' ||
				event.key === 'Next' ||
				event.key === 'Go' ||
				event.key === 'Search')
		) {
			event.preventDefault();
			if (validateChip(inputValue)) {
				handleChipUpdate([...chips, inputValue]);
			}
			setInputValue('');
		}
	};

	return (
		<MuiChipsInput
			className="sm-custom-input"
			label={label}
			required={requiredButEmpty}
			fullWidth
			inputValue={inputValue}
			onChange={handleChipUpdate}
			placeholder={placeholder}
			disableEdition
			hideClearAll
			validate={validateChip}
			addOnBlur={true}
			value={chips}
			onInputChange={setInputValue}
			onKeyDown={catchNext}
			SelectProps={{ notched: notched }}
			InputLabelProps={{ shrink: notched }}
			sx={{ marginTop: notched ? '16px !important' : '', ...sx }}
		/>
	);
}
