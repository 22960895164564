import {
	Autocomplete,
	Chip,
	FilterOptionsState,
	FormControl,
	SxProps,
	Theme,
} from '@mui/material';
import { SmLanguage, smLanguages } from '../../../shared/types/languages';
import SmTextField from './SmTextField';

interface SmLanguageAutocompleteProps {
	label: string;
	value: SmLanguage[];
	onChange: (value: SmLanguage[]) => void;
	required: boolean;
	containerClass?: string;
	sx?: SxProps<Theme>;
	notched?: boolean;
	placeholder?: string;
}
export default function SmLanguageAutocomplete({
	label,
	value,
	onChange,
	required,
	containerClass = '',
	sx,
	notched = false,
	placeholder,
}: SmLanguageAutocompleteProps) {
	const noOptionsText = 'No languages found.';

	return (
		<FormControl
			className={`${notched ? '' : 'sm-label '} ${containerClass}`}
			sx={{ minWidth: 300, ...sx }}>
			<Autocomplete
				multiple
				id={label.toLowerCase().replace(/ /g, '-')}
				options={smLanguages}
				noOptionsText={noOptionsText}
				onChange={(_, val) => onChange(val)}
				value={value}
				filterOptions={(
					options: SmLanguage[],
					state: FilterOptionsState<SmLanguage>,
				) =>
					options.filter((opt) =>
						opt.toLowerCase().startsWith(state.inputValue.trim().toLowerCase()),
					)
				}
				renderTags={(value, getTagProps) =>
					value.map((option, index) => (
						<Chip
							label={option}
							{...getTagProps({ index })}
							className={`${
								getTagProps({ index }).className
							} sm-multiline-chip`}
						/>
					))
				}
				popupIcon={false}
				renderInput={(params) => (
					<SmTextField
						className={
							'sm-profile-form-single-line-input-field sm-input-label sm-show-placeholder'
						}
						required={required}
						{...params}
						label={label}
						type="text"
						SelectProps={{ notched }}
						InputLabelProps={{ shrink: notched, className: 'sm-label-bold' }}
						placeholder={placeholder}
					/>
				)}
			/>
		</FormControl>
	);
}
