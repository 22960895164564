import { AxiosResponse } from 'axios';
import { toSha256 } from '../core/cryptoUtils';
import { RecruiterTermsChangeReasons } from '../core/types';
import { EmployerTalentFilter } from '../shared/classes/employerTalentFilter';
import { TalentForRecruiters } from '../shared/classes/talent';
import { UrlId } from '../shared/types/urlId';
import {
	baseUrl,
	buildHeaders,
	jsonHeaders,
	safeDelete,
	safeGet,
	safePost,
} from './utils';

const recruitersUrl = `${baseUrl}/recruiters`;

export const loginRecruiter = async (
	password: string,
): Promise<AxiosResponse | undefined> => {
	return await safePost(
		`${recruitersUrl}/newrecruiter`,
		{ password: await toSha256(password) },
		buildHeaders(jsonHeaders),
	);
};

export const acceptRecruiterTerms = async (headers?: { headers: any }) => {
	return await safePost(
		`${recruitersUrl}/me/terms`,
		{},
		buildHeaders(headers ?? jsonHeaders),
	);
};

export const hasAcceptedRecruiterTerms = async () => {
	const result = await safeGet(
		`${recruitersUrl}/me/terms`,
		buildHeaders(jsonHeaders),
	);

	return result ? result.data : false;
};

export const fetchTalentsForRecruiters = async (): Promise<
	TalentForRecruiters[] | undefined
> => {
	const result = await safeGet(
		`${recruitersUrl}/talents`,
		buildHeaders(jsonHeaders),
	);
	return result ? result.data : [];
};

export const fetchTalentForRecruiters = async (
	talentUrlId: UrlId,
): Promise<TalentForRecruiters | undefined> => {
	const result = await safeGet(
		`${recruitersUrl}/talents/${talentUrlId}`,
		buildHeaders(jsonHeaders),
	);

	return result ? result.data : undefined;
};

export const toggleBookmarkTalent = async (talentUrlId: string) => {
	return await safePost(
		`${recruitersUrl}/talents/${talentUrlId}/bookmark`,
		{},
		buildHeaders(jsonHeaders),
	);
};

export const toggleHideTalent = async (talentUrlId: string) => {
	return await safePost(
		`${recruitersUrl}/talents/${talentUrlId}/hide`,
		{},
		buildHeaders(jsonHeaders),
	);
};

export const saveTalentFilter = async (filter: EmployerTalentFilter) => {
	return await safePost(
		`${recruitersUrl}/talent-filters`,
		filter,
		buildHeaders(jsonHeaders),
	);
};

export const fetchTalentFilters = async (): Promise<EmployerTalentFilter[]> => {
	const result = await safeGet(
		`${recruitersUrl}/talent-filters`,
		buildHeaders(jsonHeaders),
	);
	return result ? result.data : [];
};

export const deleteTalentFilter = async (filterId: string) => {
	const result = await safeDelete(
		`${recruitersUrl}/talent-filters/${filterId}`,
		buildHeaders(jsonHeaders),
	);

	return result ? result : undefined;
};

export const deleteMyRecruiter = async () => {
	return await safeDelete(
		`${recruitersUrl}/me/delete`,
		buildHeaders(jsonHeaders),
	);
};

export const getRecruiterTermsChangeReasons = async (): Promise<
	RecruiterTermsChangeReasons | undefined
> => {
	const result = await safeGet(
		`${recruitersUrl}/terms-change-reasons`,
		buildHeaders(jsonHeaders),
	);

	return result ? result.data : undefined;
};
