import { useEffect, useState } from 'react';
import { fetchEmployers, fetchRecruiters } from '../../api/admin';
import { Employer } from '../../shared/classes/employer';
import { Recruiter } from '../../shared/classes/recruiter';
import PendingRecruiterListItem from './PendingRecruiterListItem';

export default function ActivateRecruiterForm() {
	const [recruiters, setRecruiters] = useState<Recruiter[]>([]);
	const [employers, setEmployers] = useState<Employer[]>([]);

	const reloadRecruiters = () => {
		fetchRecruiters().then((recs) => {
			setRecruiters(recs ?? []);
		});
	};

	useEffect(() => {
		reloadRecruiters();
		fetchEmployers().then((emps) => {
			setEmployers(emps ?? []);
		});
	}, []);

	return (
		<>
			<h2>Activate Recruiters</h2>
			{recruiters
				.filter((rec) => rec.recruiterProfile?.firstName && !rec.isActive)
				.map((recruiter) => (
					<PendingRecruiterListItem
						recruiter={recruiter}
						employers={employers}
						reloadRecruiters={reloadRecruiters}
					/>
				))}
		</>
	);
}
