import { Button, ButtonProps } from '@mui/material';
import { useLinkedIn } from 'react-linkedin-login-oauth2';

type LinkedInLoginButtonProps = ButtonProps & {
	clientId: string;
	redirectUri: string;
	scope: string;
	handleSuccess: (code: string) => Promise<void>;
	label?: string;
	isSubmit?: boolean;
	handleError?: (code: any) => void;
};
export default function LinkedInButton({
	clientId,
	redirectUri,
	scope,
	handleSuccess,
	label = 'Sign in with LinkedIn',
	disabled = false,
	isSubmit = false,
	handleError = (error) => {
		console.error("LinkedIn: Couldn't login.", error);
	},
}: LinkedInLoginButtonProps) {
	const { linkedInLogin } = useLinkedIn({
		clientId,
		redirectUri,
		scope,
		onSuccess: handleSuccess,
		onError: handleError,
	});

	return (
		<Button
			onClick={linkedInLogin}
			variant="login"
			disableElevation
			type={isSubmit ? 'submit' : 'button'}
			disabled={disabled}
			className="linkedin-login-button"
			startIcon={
				<img
					src={require('../../assets/linkedin-blue-logo.png')}
					alt={'LinkedIn logo'}
					style={{ maxHeight: '24px' }}
				/>
			}>
			{label}
		</Button>
	);
}
