import {
	FormControl,
	FormGroup,
	FormHelperText,
	FormLabel,
} from '@mui/material';
import { useState } from 'react';
import { userLocale } from '../../../core/consts';
import { ProfileFieldNamesEnum } from '../../../core/enums';
import {
	ContractType,
	SalaryExpecationsCurrencyWithAny,
	SalaryExpectations,
	SalaryExpectationsCurrency,
} from '../../../shared/classes/profile';
import SmCheckbox from './SmCheckbox';
import SmSalaryField from './SmSalaryField';

type ContractTypeProps = {
	handleContractTypeChange: (
		event: React.ChangeEvent<HTMLInputElement>,
		checked: boolean,
	) => void;
	contractType?: ContractType;
	salaryExpectations?: SalaryExpectations;
	handleSalaryExpectationsChange: (
		newSalaryExpectations: SalaryExpectations,
	) => void;
};
export default function ContractTypeAndSalaryExpectationsInput({
	handleContractTypeChange,
	contractType,
	salaryExpectations,
	handleSalaryExpectationsChange,
}: ContractTypeProps) {
	const [hasBeenTouched, setHasBeenTouched] = useState(false);
	const isNoneSelected =
		!contractType ||
		!(
			contractType?.employment ||
			contractType?.freelance ||
			contractType?.supplier
		);
	const error = hasBeenTouched && isNoneSelected;

	const handleContractTypeUpdate = (
		event: React.ChangeEvent<HTMLInputElement>,
		checked: boolean,
	) => {
		setHasBeenTouched(true);
		handleContractTypeChange(event, checked);
	};

	const tooltipText = {
		employment:
			'I am looking to be permanently employed by a company through an employment contract. The company will pay my salary and benefits',
		freelance:
			'I am looking for a contract as an external consultant to a company. I will invoice the company for my services.',
		supplier:
			'I am employed at a consulting firm that provides my services to companies.',
	};

	const handleCurrencyChange = (value: SalaryExpecationsCurrencyWithAny) => {
		handleSalaryExpectationsChange({
			...salaryExpectations!,
			currency: value as SalaryExpectationsCurrency,
		});
	};

	const isYearlyInputDisabled = !contractType?.employment;
	const handleYearlyChange = (value: number | null) => {
		handleSalaryExpectationsChange({
			...salaryExpectations!,
			yearlyGross: value,
		});
	};

	const isDailyInputDisabled =
		!contractType?.freelance && !contractType?.supplier;
	const handleDailyChange = (value: number | null) => {
		handleSalaryExpectationsChange({
			...salaryExpectations!,
			hourlyGross: value,
		});
	};

	return (
		<FormControl
			className="sm-custom-input"
			required
			error={error}
			component="fieldset"
			variant="standard">
			<FormLabel component="legend" sx={{ marginBottom: -0.5 }}>
				{ProfileFieldNamesEnum.CONTRACT_TYPE}
			</FormLabel>
			<FormGroup style={{ maxWidth: 700 }}>
				<div className={'flex flex-equal-size-children'}>
					<SmCheckbox
						tooltipText={tooltipText.employment}
						checkboxLabel="Employment"
						checkboxName="employment"
						isRequired={isNoneSelected}
						isChecked={contractType?.employment ?? false}
						handleChange={handleContractTypeUpdate}
						showInfoIcon
					/>
					<SmSalaryField
						required={!isYearlyInputDisabled}
						name="salary-yearly"
						label={'Yearly gross salary'}
						disabled={isYearlyInputDisabled}
						onSalaryChange={handleYearlyChange}
						onCurrencyChange={handleCurrencyChange}
						value={salaryExpectations?.yearlyGross ?? null}
						userLanguage={userLocale}
						currency={salaryExpectations?.currency}
					/>
				</div>
				<div className={'flex flex-equal-size-children'}>
					<div>
						<SmCheckbox
							tooltipText={tooltipText.freelance}
							checkboxLabel="Independent Consultant"
							checkboxName="freelance"
							isRequired={isNoneSelected}
							isChecked={contractType?.freelance ?? false}
							handleChange={handleContractTypeUpdate}
							showInfoIcon
						/>
						<SmCheckbox
							tooltipText={tooltipText.supplier}
							checkboxLabel="Consulting Firm"
							checkboxName="supplier"
							isRequired={isNoneSelected}
							isChecked={contractType?.supplier ?? false}
							handleChange={handleContractTypeUpdate}
							showInfoIcon
						/>
					</div>
					<SmSalaryField
						required={!isDailyInputDisabled}
						name="salary-daily"
						label={'Hourly rate'}
						disabled={isDailyInputDisabled}
						onSalaryChange={handleDailyChange}
						onCurrencyChange={handleCurrencyChange}
						value={salaryExpectations?.hourlyGross ?? null}
						userLanguage={userLocale}
						currency={salaryExpectations?.currency}
					/>
				</div>
			</FormGroup>
			<FormHelperText>Please select at least one</FormHelperText>
		</FormControl>
	);
}
