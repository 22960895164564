import { generateRandomNumberPaddedString } from '../core/cryptoUtils';
import { EmployerTalentFilter } from '../shared/classes/employerTalentFilter';
import { Profile, ProfileStatusesEnum } from '../shared/classes/profile';
import { RecruiterProfile } from '../shared/classes/recruiterProfile';
import {
	EmailCommunications,
	EmailTemplate,
	EvalData,
} from '../shared/classes/talent';

export const americasReviewerToken =
	process.env.REACT_APP_REVIEWER_TOKEN_AMERICAS ?? '';
export const allRegionsReviewerToken =
	process.env.REACT_APP_REVIEWER_TOKEN_ALL_REGIONS ?? '';
export const apiToken = process.env.REACT_APP_API_TOKEN ?? '';

export let currentTestUserId: string | undefined = undefined;
export const testAdminUserId = process.env.REACT_APP_TEST_ADMIN_USER_ID;

export const testPassword = 'super-secret-scrum-match-password-1234';
export const testRecruiterEmail = 'pnp@amazing-outcomes.de';

export const generateTestId = () =>
	`${generateRandomNumberPaddedString(16)}${generateRandomNumberPaddedString(16)}`;

export const unsetTestUserId = () => {
	currentTestUserId = undefined;
};

export const setCurrentTestUserId = (id: string) => {
	currentTestUserId = id;
};

export const setCurrentTestUserToAdmin = () => {
	currentTestUserId = testAdminUserId;
};

export const exampleRecruiterProfile: RecruiterProfile = {
	firstName: 'Haley',
	surnames: 'Recruiter',
	companyPhone: '+1 111 111 1111',
	companyName: 'Scrum Master Inc.',
	companyWebsite: 'https://scrummatch.com',
	hiringLocations: [
		{
			label: 'Barcelona, Catalonia, ES',
			name: 'Barcelona',
			lat: '41.38879',
			lng: '2.15899',
			country: 'ES',
			admin1: '56',
			admin2: 'B',
			population: 1621537,
		},
	],
};

export const exampleProfile: Profile = {
	firstName: 'John',
	middleName: 'Jacob',
	surnames: 'Doe',
	tagLine: 'Tagline: I am a test user',
	contractType: {
		employment: true,
		freelance: false,
		supplier: false,
	},
	salaryExpectations: {
		currency: 'EUR',
		yearlyGross: 50000,
		hourlyGross: null,
	},
	currentLocation: {
		label: 'Barcelona, Catalonia, ES',
		name: 'Barcelona',
		lat: '41.38879',
		lng: '2.15899',
		country: 'ES',
		admin1: '56',
		admin2: 'B',
		population: 1621537,
	},
	preferredWorkLocations: [
		{
			label: 'Barcelona, Catalonia, ES',
			name: 'Barcelona',
			lat: '41.38879',
			lng: '2.15899',
			country: 'ES',
			admin1: '56',
			admin2: 'B',
			population: 1621537,
		},
	],
	workModel: {
		remote: true,
		onSite: false,
		hybrid: false,
	},
	startDate: new Date().toISOString(),
	preferredWorkLanguages: ['Icelandic', 'Zulu'],
	scrumExperienceInYears: 8,
	education: 'BA in Basketweaving, Harvard; MA in Mischief, Oxford',
	certifications: 'PSM VI',
	workPermit: {
		haveIt: true,
		wouldNeedIt: false,
		dontNeedIt: false,
	},
	intro:
		'Intro: I am a test user. Scrum Master with 8 years of experience with a keen ability to understand and build complex systems and focusing relentlessly on removing impediments from teams and delivering customer-centric products and features. Led teams of 8-15 people across technology, business, and design departments. Promoted within 12 months due to strong performance and organizational impact.',

	evalAnswer:
		'evalAnswer: Intro: I am a test user. Scrum Master with 8 years of experience with a keen ability to understand and build complex systems and focusing relentlessly on being awesome',
	personalInterests: 'swimming;running;hiking;reading;writing;coding;cooking',
	anonymizedFields: {
		introAnonymous:
			'Intro Anonymous: I am a anonymous user. Scrum Master with 8 years of experience with a keen ability to understand and build complex systems and focusing relentlessly on removing impediments from teams and delivering customer-centric products and features. Led teams of several people across technology, business, and design departments. Promoted within short time due to strong performance and organizational impact.',
		evalAnswerAnonymous:
			'evalAnswer Anonymous: Intro: I am a anonymous user. Scrum Master with several years of experience with a keen ability to understand and build complex systems and focusing relentlessly on being awesome',

		personalInterestsAnonymous:
			'anonymous;swimming;running;hiking;reading;writing;coding;cooking',
		workExperiencesAnonymous: [
			{
				role: 'Scrum Master',
				industry: 'Software Development',
				startDate: '2020-01-01',
				endDate: 'present',
				keyAchievements: ['I worked at [redacted] and did some things'],
			},
		],
	},
	checksums: {
		introChecksum: 'introChecksum',
		evalAnswerChecksum: 'evalAnswerChecksum',
		personalInterestsChecksum: 'personalInterestsChecksum',
		introAnonymousChecksum: 'introAnonymousChecksum',
		evalAnswerAnonymousChecksum: 'evalAnswerAnonymousChecksum',
		personalInterestsAnonymousChecksum: 'personalInterestsAnonymousChecksum',
	},
	status: ProfileStatusesEnum.DRAFT,
	workExperiences: [
		{
			role: 'Scrum Master',
			industry: 'Software Development',
			startDate: new Date('2020-01-01').toISOString(),
			endDate: 'present',
			keyAchievements: ['I did some stuff', 'I did some more stuff'],
		},
	],
};

export const exampleEmailCommunications: EmailCommunications = {
	currentDraft: {
		name: 'Feedback after submission',
		subject: 'ScrumMatch: Feedback on your submission',
		bodyHtml: '',
		bodyPlainText:
			"Hi D,\n\nThanks for submitting your profile. Here's some lovely feedback\n\nWe have reviewed your profile and have some feedback for you. We've highlighted the areas we'd like you to refine in your example of how you have used Scrum to deliver business value sooner:\n\nPlease describe your practical experience as a Scrum Master. Use a specific example. What we are looking for is\nWhat you did in the events and activities that happened.\nThe stances you took and why you chose them over alternatives.\nThe Scrum principles you (were) led by.\nA clear explanation of the value generated and evidence that supports your claims.\n\nWe have changed your profile state so you can refine it.\nIf you have any questions, please don't hesitate to let us know.\n\nBest regards,\n\nYour ScrumMatch Reviewer\n\nE-Mail: reviewers@scrummatch.com\nLinkedIn: https://www.linkedin.com/company/scrummatch/",
	},
	history: [
		{
			sentAt: new Date('2024-03-27T11:14:29.127Z'),
			emailTemplate: new EmailTemplate({
				name: 'Feedback on submission',
				subject: 'ScrumMatch: Test',
				bodyHtml: '',
				bodyPlainText: 'Hi,\n\nJust testing',
			}),
		},
	],
};

export const exampleEvalData: EvalData = {
	evalNotes: 'evalNotes',
	successManagerNotes: 'successManagerNotes',
	scrumMaturity: '-1',
	scheduledEvalDate: 'scheduledEvalDate',
};

export const sleep = (seconds: number) => {
	return new Promise((resolve) => setTimeout(resolve, seconds * 1000 || 5000));
};
export const exampleFilter: EmployerTalentFilter = {
	filterName: 'test-filter',
	filterId: 'test-filter-id',
	contractTypes: {
		supplier: true,
		employment: false,
		freelance: false,
	},
	workModel: {
		remote: true,
		onSite: false,
		hybrid: false,
	},
	isConnected: true,
	isBookmarked: false,
	isHidden: false,
	isPermittedToWork: true,
	latestStartingDate: '2021-01-01',
	scrumMaturity: ['3'],
	preferredWorkLocations: [
		{
			label: 'Barcelona, Catalonia, ES',
			name: 'Barcelona',
			lat: '41.38879',
			lng: '2.15899',
			country: 'ES',
			admin1: '56',
			admin2: 'B',
			population: 1621537,
		},
	],
	workLanguages: ['Afrikaans'],
	freeTextSearchValue: '',
	salaryExpectations: {
		currency: 'EUR',
		yearlyGrossMax: 100000,
		hourlyGrossMax: 100,
	},
};
