import { sendEmailFromReviewerToUrlId } from '../../api/reviewers';
import {
	EmailCommunications,
	EmailTemplate,
} from '../../shared/classes/talent';
import EmailTemplateForm from './EmailTemplateForm';

interface TalentReviewEmailFormProps {
	urlId: string | undefined;
	emailCommunications?: EmailCommunications;
	handleEmailCommunicationsChange: (
		emailCommunications: EmailCommunications,
	) => void;
	emailTemplates: EmailTemplate[];
}

export default function TalentReviewEmailForm({
	urlId,
	emailCommunications,
	handleEmailCommunicationsChange,
	emailTemplates,
}: TalentReviewEmailFormProps) {
	const handleCurrentDraftChange = (currentDraft: EmailTemplate) => {
		handleEmailCommunicationsChange({
			...emailCommunications!,
			currentDraft,
		});
	};

	const handleSendEmail =
		urlId && emailCommunications?.currentDraft
			? () =>
					sendEmailFromReviewerToUrlId(urlId, emailCommunications.currentDraft)
			: async () => undefined;

	return (
		<EmailTemplateForm
			emailTemplates={emailTemplates}
			sendEmail={handleSendEmail}
			onChange={handleCurrentDraftChange}
			currentDraft={emailCommunications?.currentDraft}
		/>
	);
}
