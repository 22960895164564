import { Checkbox, FormControl, TextField } from '@mui/material';
import { ChangeEvent, ReactNode, useEffect } from 'react';
import { PathsEnum } from '../../../core/enums';
import ExplanationLink from './ExplanationLink';
import './SmTextArea.css';

interface SmTextAreaProps {
	id: string;
	required?: boolean;
	label: ReactNode;
	subHeading?: ReactNode;
	value: string | undefined;
	name: string;
	placeholder?: string;
	handleChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
	maxCharacters: number;
	minCharacters?: number;
	rows?: number;
	showCharacterCountExplanation?: boolean;
}

export default function SmTextArea({
	id,
	required = false,
	label,
	subHeading,
	value,
	name,
	placeholder,
	handleChange,
	maxCharacters,
	minCharacters = 0,
	rows = 13,
	showCharacterCountExplanation = false,
}: SmTextAreaProps) {
	const isTooShort = value ? value.length < minCharacters : false;
	const isTooLong = value ? value.length > maxCharacters : false;

	useEffect(() => {
		//@ts-ignore
		const hiddenCheckbox = document.querySelector(
			`#${id} .sm-profile-hidden-textarea-checkbox input`,
		);
		if (hiddenCheckbox) {
			//@ts-ignore
			hiddenCheckbox.setCustomValidity(
				isTooLong
					? `Maximum ${maxCharacters} characters.`
					: `Minimum ${minCharacters} characters.`,
			);
		}
	}, [isTooShort, isTooLong, id, maxCharacters, minCharacters]);

	const characterCounter = () => {
		return (
			<>
				{isTooShort || isTooLong ? (
					<Checkbox
						sx={{ opacity: 0, cursor: 'default' }}
						checked={false}
						required
						className={'sm-profile-hidden-textarea-checkbox'}
					/>
				) : null}
				{value?.length ?? 0} / {maxCharacters}
			</>
		);
	};

	return (
		<FormControl id={id} fullWidth variant="outlined" style={{ marginTop: 0 }}>
			<span style={{ fontSize: '12px' }}>{subHeading}</span>
			<p className="sm-profile-textarea-character-counter">
				<span>
					{characterCounter()}
					&nbsp;&nbsp;
					{showCharacterCountExplanation ? (
						<ExplanationLink
							label="Why this limit?"
							onClick={() =>
								window.open(PathsEnum.LANDING_PAGE_FAQ_CHAR_LIMIT, '_blank')
							}
						/>
					) : null}
				</span>
			</p>
			<TextField
				required={required}
				label={label}
				SelectProps={{ notched: true }}
				InputLabelProps={{ shrink: true }}
				name={name}
				rows={rows}
				onChange={handleChange}
				value={value}
				placeholder={placeholder}
				multiline
				error={isTooShort || isTooLong}
				helperText={
					isTooShort
						? `Minimum ${minCharacters} characters.`
						: isTooLong
							? `Maximum ${maxCharacters} characters.`
							: null
				}
			/>
		</FormControl>
	);
}
