import React from 'react';
import './StepIcon.css';

import { StepStatusEnum } from '../../../core/enums';

type StepIconProps = React.DetailedHTMLProps<
	React.HTMLAttributes<HTMLDivElement>,
	HTMLDivElement
> & {
	stepStatus: StepStatusEnum;
};

export default function StepIcon(props: StepIconProps) {
	const content =
		props.stepStatus === StepStatusEnum.DONE ? (
			<img
				src={require('../../../assets/sm-checkmark.png')}
				alt={'checkmark'}
			/>
		) : (
			''
		);
	return (
		<div className={`${props.stepStatus} sm-step-icon`}>
			<div className="sm-step-icon-disc">{content}</div>
		</div>
	);
}
