import { Button, TextField } from '@mui/material';
import React, { useState } from 'react';
import { createEmployer } from '../../api/admin';
import { Employer } from '../../shared/classes/employer';

export default function NewEmployerForm() {
	const [newEmployer, setNewEmployer] = useState(new Employer());

	const handlePressSubmitButton = async (
		event: React.FormEvent<HTMLFormElement>,
	) => {
		event.preventDefault();
		try {
			await createEmployer(newEmployer.employerId, newEmployer.name);
			alert('Employer created successfully.');
			setNewEmployer(new Employer());
		} catch (error) {
			alert('Creating employer failed. Please try again.');
		}
	};

	const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setNewEmployer({
			...newEmployer,
			name: event.target.value,
		});
	};

	const handleEmployerIdChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setNewEmployer({
			...newEmployer,
			employerId: event.target.value,
		});
	};

	return (
		<div style={{ maxWidth: 800 }}>
			<h1>Create new employer</h1>
			<form className="sm-form" onSubmit={handlePressSubmitButton}>
				<TextField
					required
					name="name"
					label="Employer name"
					variant="outlined"
					fullWidth
					margin="normal"
					onChange={handleNameChange}
					value={newEmployer.name}
				/>
				<TextField
					required
					name="employerId"
					label="Employer id"
					variant="outlined"
					fullWidth
					margin="normal"
					onChange={handleEmployerIdChange}
					value={newEmployer.employerId}
				/>

				<div className="sm-button-container">
					<Button
						variant="contained"
						color="primary"
						type="submit"
						formEncType="multipart/form-data">
						Create employer
					</Button>
				</div>
			</form>
		</div>
	);
}
