import axios from 'axios';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import { refreshGoogleToken } from '../api/auth';
import { COOKIE_CONFIG, SM_AUTH_COOKIE } from '../core/consts';
import { PathsEnum } from '../core/enums';
import { buildSmAuthCookie } from '../core/utils';
import { LoginProviderEnum } from '../shared/classes/talent';

interface SmInterceptorProps {
	redirectTo?: PathsEnum;
	onlyRefreshToken?: boolean;
	redirectToIfUserNotActive?: PathsEnum;
}

// More information here: https://dev.azure.com/amazing-outcomes/2360-INC-Polus/_wiki/wikis/2360-INC-Polus.wiki/139/Interceptor

export default function SmInterceptor({
	redirectTo,
	onlyRefreshToken = false,
	redirectToIfUserNotActive,
}: SmInterceptorProps) {
	const [cookies, setCookies, removeCookie] = useCookies([SM_AUTH_COOKIE]);
	const [isInterceptorActive, setIsInterceptorActive] = useState(false);
	const [alreadyNavigated, setAlreadyNavigated] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();

	// navigate to login if cookie is gone
	useEffect(() => {
		if (!alreadyNavigated && navigate && !cookies?.sm_auth) {
			if (!onlyRefreshToken) {
				setAlreadyNavigated(true);
				navigate({
					pathname: redirectTo || PathsEnum.LOGIN,
					search: location.search,
				});
			}
		}
	}, [
		alreadyNavigated,
		navigate,
		cookies,
		onlyRefreshToken,
		redirectTo,
		location,
	]);

	// only set up interceptor ONCE after navigate and cookies are instantiated
	useEffect(() => {
		if (!isInterceptorActive && cookies && setCookies && removeCookie) {
			setIsInterceptorActive(true);

			const deleteCookie = () =>
				onlyRefreshToken ? {} : removeCookie(SM_AUTH_COOKIE, COOKIE_CONFIG);

			axios.interceptors.response.use(
				(response) => {
					return response;
				},
				async (error) => {
					const originalRequest = error.config;

					if (originalRequest?._retry) {
						return;
					}

					const didTokenRefreshFail =
						originalRequest?.url?.includes('refresh-token');
					const isUnauthorizedAndRefreshTokenMissing =
						error.response?.status === 401 &&
						cookies?.sm_auth &&
						!cookies?.sm_auth?.google_auth?.refresh_token;
					const shouldAttemptToRefreshToken =
						error.response?.status === 401 && cookies?.sm_auth;
					const isTosNotAccepted =
						error.response?.status === 403 &&
						error.response?.data?.code === 'tos_not_accepted';
					const userDoesNotExist =
						error.response?.status === 403 &&
						error.response?.data?.code === 'user_not_found';
					const isUserNotActive =
						error.response?.status === 403 &&
						error.response?.data?.code === 'user_not_active';

					if (didTokenRefreshFail) {
						console.log('Failed to refresh token');
						deleteCookie();
						return;
					} else if (isUnauthorizedAndRefreshTokenMissing) {
						console.log('Invalid access token and no refresh token');
						deleteCookie();
						return;
					} else if (
						shouldAttemptToRefreshToken &&
						cookies?.sm_auth?.google_auth &&
						originalRequest
					) {
						originalRequest._retry = true;

						const type = cookies?.sm_auth?.type;
						const newAuth = await refreshGoogleToken();
						const authProvider = LoginProviderEnum.GOOGLE;

						if (newAuth?.status !== 200) {
							deleteCookie();
							return;
						}

						if (newAuth?.data && authProvider) {
							setCookies(
								SM_AUTH_COOKIE,
								buildSmAuthCookie(authProvider, type, newAuth?.data),
								COOKIE_CONFIG,
							);
							return axios({ ...originalRequest, _retry: true });
						}
					} else if (isTosNotAccepted) {
						console.log('ToS not accepted');
						deleteCookie();
						return;
					} else if (userDoesNotExist) {
						console.log('User does not exist');
						deleteCookie();
						return;
					} else if (isUserNotActive) {
						console.log('User not active');
						if (redirectToIfUserNotActive) {
							navigate({
								pathname: redirectToIfUserNotActive,
								search: location.search,
							});
							return;
						} else {
							deleteCookie();
							return;
						}
					}
					return Promise.reject({
						...error,
						config: { ...error.config, _retry: true },
					});
				},
			);
		}
	}, [
		cookies,
		setCookies,
		removeCookie,
		isInterceptorActive,
		onlyRefreshToken,
		navigate,
		redirectToIfUserNotActive,
		location.search,
	]);

	return <></>;
}
