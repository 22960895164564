import { Button, ButtonProps } from '@mui/material';

export default function ArrowBackButton(props: ButtonProps) {
	return (
		<Button
			{...props}
			sx={{ color: '#fff' }}
			startIcon={
				<img
					src={require('../../assets/sm-arrow-back.png')}
					alt={'arrow pointing left'}
				/>
			}>
			{props.children}
		</Button>
	);
}
