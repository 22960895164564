import OutlinedBox from './OutlinedBox';
import './ReviewNotesDisplay.css';

type ReviewNotesDisplayProps = {
	evalNotes: string;
	firstName?: string;
	noPadding?: boolean;
};

export default function ReviewNotesDisplay({
	evalNotes,
	firstName,
	noPadding,
}: ReviewNotesDisplayProps) {
	return (
		<div style={{ width: '100%' }}>
			<OutlinedBox
				noPadding={noPadding}
				title={`ScrumMatch feedback for ${firstName ?? 'you'}`}
				classes="sm-preferred-white-outline-box sm-review-notes-display"
				slot={evalNotes}
				tooltip="This is only visible to you, not employers."
			/>
		</div>
	);
}
