import {
	ContractType,
	ContractTypeKeys,
	WorkModel,
	WorkModelKeys,
} from '../shared/classes/profile';
import { ScrumMaturity, ScrumMaturityValues } from '../shared/classes/talent';
import {
	ScrumMaturityDescription,
	contractTypeLookup,
	workModelLookup,
} from './lookups';

export const mapContractTypeToDropdownLabels = (
	contractTypes: ContractType,
): string[] => {
	const keys = Object.keys(contractTypes) as ContractTypeKeys[];
	return keys
		.filter((key) => contractTypes[key])
		.map((key) => contractTypeLookup[key]);
};

export const mapWorkModelToDropdownLabels = (
	workModels: WorkModel,
): string[] => {
	const keys = Object.keys(workModels) as WorkModelKeys[];
	return keys
		.filter((key) => workModels[key])
		.map((key) => workModelLookup[key]);
};

export const mapDropdownLabelsToContractType = (
	dropdownLabels: string[],
): ContractType => {
	const contractType = new ContractType();
	const keys = dropdownLabels.map(
		(label) =>
			Object.keys(contractTypeLookup).find(
				(key) => contractTypeLookup[key as ContractTypeKeys] === label,
			) as ContractTypeKeys,
	);
	keys.forEach((key) => {
		contractType[key] = true;
	});
	return new ContractType(contractType);
};

export const mapDropdownLabelsToWorkModel = (
	dropdownLabels: WorkModelKeys[],
): WorkModel => {
	const workModel = new WorkModel();
	const keys = dropdownLabels.map(
		(label) =>
			Object.keys(workModelLookup).find(
				(key) => workModelLookup[key as WorkModelKeys] === label,
			) as WorkModelKeys,
	);
	keys.forEach((key) => {
		workModel[key] = true;
	});
	return new WorkModel(workModel);
};

export const findScrumMaturityByValue = (
	scrumMaturityLookup: Record<ScrumMaturity, ScrumMaturityDescription>,
	value: string,
	lookupValue: keyof ScrumMaturityDescription = 'dropdownLabel',
) => {
	let scrumMaturity: ScrumMaturity = '';
	Object.entries(scrumMaturityLookup).forEach(([key, val]) => {
		if (val[lookupValue] === value) {
			scrumMaturity = key as ScrumMaturity;
		}
	});

	const isValid = ScrumMaturityValues.includes(scrumMaturity);
	if (!isValid) {
		console.error('SM Error: Scrum maturity is invalid');
		return '';
	}

	return scrumMaturity;
};

const sortedScrumMaturityEntries = (
	scrumMaturityLookup: Record<ScrumMaturity, ScrumMaturityDescription>,
) =>
	Object.entries(scrumMaturityLookup).sort(([key1, _], [key2, __]) =>
		key1 > key2 ? -1 : 1,
	);

export const sortedDropdownLabels = (
	scrumMaturityLookup: Record<ScrumMaturity, ScrumMaturityDescription>,
) =>
	sortedScrumMaturityEntries(scrumMaturityLookup).map(
		([_, val]) => val.dropdownLabel,
	);

export const sortedDropdownLabelsWithoutRejectedAndEmpty = (
	scrumMaturityLookup: Record<ScrumMaturity, ScrumMaturityDescription>,
) =>
	sortedScrumMaturityEntries(scrumMaturityLookup)
		.filter(([key, _]) => key !== '' && key !== '-1')
		.map(([_, val]) => val.dropdownLabel);

export const mapScrumMaturitiesToDropdownLabels = (
	scrumMaturityLookup: Record<ScrumMaturity, ScrumMaturityDescription>,
	scrumMaturities: ScrumMaturity[],
): string[] => {
	return scrumMaturities.map(
		(maturity) => scrumMaturityLookup[maturity].dropdownLabel,
	);
};

export const mapDropdownLabelsToScrumMaturities = (
	scrumMaturityLookup: Record<ScrumMaturity, ScrumMaturityDescription>,
	dropdownLabels: string[],
): ScrumMaturity[] => {
	return dropdownLabels.map(
		(label) =>
			Object.keys(scrumMaturityLookup).find(
				(key) =>
					scrumMaturityLookup[key as ScrumMaturity].dropdownLabel === label,
			) as ScrumMaturity,
	);
};
