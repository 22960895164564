import { Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CookieSetOptions } from 'universal-cookie';
import { ProfileStatusesEnum } from '../shared/classes/profile';

export const uneditableProfileStatuses = [
	ProfileStatusesEnum.SUBMITTED,
	ProfileStatusesEnum.SCHEDULING,
	ProfileStatusesEnum.SCHEDULED,
];

export const SM_AUTH_COOKIE = 'sm_auth';

const isRunningLocallyOnSafari = () =>
	process.env.REACT_APP_COOKIE_DOMAIN === 'localhost' &&
	window.navigator.vendor.includes('Apple');

export const COOKIE_CONFIG: CookieSetOptions = {
	sameSite: 'none',
	secure: !isRunningLocallyOnSafari(),
	domain: process.env.REACT_APP_COOKIE_DOMAIN,
	path: '/',
	maxAge: 60 * 60 * 24 * 30, // 30 days
};

const smBootstrapDialogMarginSpacing = '20px';
export const SmBootstrapDialog = styled(Dialog)(({ theme }) => ({
	'div[role="dialog"]': {
		backgroundColor: theme.palette.green.main,
		borderRadius: '16px',
		padding: `${smBootstrapDialogMarginSpacing} 0`,
	},
	'& .MuiDialogTitle-root': {
		textTransform: 'uppercase',
		letterSpacing: '0.15em',
		marginBottom: smBootstrapDialogMarginSpacing,
	},
	'& .MuiDialogActions-root': {
		marginTop: smBootstrapDialogMarginSpacing,
		'@media (max-width: 479.98px)': {
			flexDirection: 'column',
			gap: '10px',
			'.MuiButton-root': {
				marginLeft: 0,
			},
		},
	},
}));

export const userLocale = navigator.language.startsWith('en')
	? 'en-US'
	: 'de-DE';
