import useUserInfoZustand from '../../../zustand/UserInfoZustand';

export default function LoginInfo() {
	const { userInfo } = useUserInfoZustand();

	return (
		<>
			{userInfo?.email && userInfo?.loginProvider ? (
				<div className="sm-info-text sm-sidebar-text">
					<p className="overflow-wrap-anywhere" style={{ marginBottom: '5px' }}>
						{userInfo?.email}
					</p>
					<p>
						logged in with <strong>{userInfo?.loginProvider}</strong>
					</p>
				</div>
			) : null}
		</>
	);
}
