import { FormControl } from '@mui/base';
import { InputLabel } from '@mui/material';
import {
	DateTimePickerProps,
	DateTimePicker,
	LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';

type SmDatePickerProps = {
	label: string;
	handleChange: (event: dayjs.Dayjs | null) => void;
	value?: string;
	format?: string;
	containerClass?: string;
	fieldClass?: string;
} & { datePickerProps?: DateTimePickerProps<Dayjs> };

export default function SmDatePicker({
	label,
	handleChange,
	value = '',
	format = 'MMMM DD, YYYY HH:mm',
	containerClass = 'sm-full-screen',
	fieldClass = 'sm-full-screen',
	datePickerProps,
}: SmDatePickerProps) {
	const id = label.toLowerCase().replace(/ /g, '-');

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<FormControl className={`sm-label-datepicker ${containerClass}`}>
				<InputLabel htmlFor={id} variant="standard" shrink={false}>
					{label}
				</InputLabel>
				<DateTimePicker
					className={fieldClass}
					sx={{ backgroundColor: '#fff', marginTop: '25px' }}
					format={format}
					area-label={id}
					disablePast={true}
					value={value ? dayjs(value) : undefined}
					onChange={handleChange}
					slotProps={{
						inputAdornment: {
							position: 'end',
							style: { justifyContent: 'flex-end' },
						},
					}}
					{...datePickerProps}
				/>
			</FormControl>
		</LocalizationProvider>
	);
}
