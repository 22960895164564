import { create } from 'zustand';
import { getProfile, togglePauseProfile } from '../api/talents';
import { Profile, ProfileStatusesEnum } from '../shared/classes/profile';

interface ProfileZustandType {
	profile: Profile | null;
	setProfile: (profile: Profile | null) => void;
	loadMyProfile: () => Promise<void>;
	fetchProfileStatus: () => Promise<ProfileStatusesEnum | undefined>;
	loadAndInitializeMyProfile: () => Promise<void>;
	doesProfileExist: () => Promise<boolean>;
	togglePauseProfile: () => Promise<void>;
}

const useProfileZustand = create<ProfileZustandType>((set) => ({
	profile: null,
	setProfile: (profile: Profile | null) => {
		set({ profile });
	},
	loadMyProfile: async () => {
		const profileFromBackend = await getProfile();
		set({
			profile: profileFromBackend ? new Profile(profileFromBackend) : null,
		});
	},
	fetchProfileStatus: async () => {
		const profileFromBackend = await getProfile();
		return profileFromBackend?.status;
	},
	loadAndInitializeMyProfile: async () => {
		set({ profile: new Profile(await getProfile()) });
	},
	doesProfileExist: async () => {
		const profileFromBackend = await getProfile();
		return !!profileFromBackend;
	},
	togglePauseProfile: async () => {
		await togglePauseProfile();
		const profileFromBackend = await getProfile();
		set({
			profile: profileFromBackend ? new Profile(profileFromBackend) : null,
		});
	},
}));

export default useProfileZustand;
