import { countryToAlpha2Code } from '../shared/assets/utils';
import { SmLocation } from '../shared/classes/smLocation';
import { LoginProviderEnum } from '../shared/classes/talent';
import { SmLanguage } from '../shared/types/languages';
import { CookieUserTypeEnum } from './enums';
import { LoginProviderToCookieNameMap as loginProviderToCookieNameMap } from './utils';

const dateInPast = (date: Date) =>
	date.setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0);

export const startDateText = (start: string) => {
	const date = new Date(start);
	if (dateInPast(date)) return 'Immediately';
	else
		return date.toLocaleDateString('en-US', {
			day: '2-digit',
			month: 'long',
			year: 'numeric',
		});
};

export const germanDateTimeString = (dateTime: string | Date) =>
	new Date(dateTime).toLocaleString('de-DE', {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric',
		hour: '2-digit',
		minute: '2-digit',
		hourCycle: 'h24',
	});

export const universalDateTimeString = (dateTime: string | Date) => {
	const date = new Date(dateTime);
	const dateString = date
		.toLocaleDateString('en-UK', {
			day: '2-digit',
			month: 'short',
			year: 'numeric',
		})
		.replaceAll(' ', '-');
	const timeString = date.toLocaleString('de-DE', {
		hour: '2-digit',
		minute: '2-digit',
		hourCycle: 'h24',
	});
	return `${dateString} ${timeString}`;
};

export const isFilteredForLanguages = (
	filterLanguages: SmLanguage[],
	talentLangages: SmLanguage[],
	andFilter = true,
): boolean => {
	if (andFilter) {
		return (
			filterLanguages.length === 0 ||
			filterLanguages.every((lang) => talentLangages.includes(lang))
		);
	} else {
		return (
			filterLanguages.length === 0 ||
			filterLanguages.some((lang) => talentLangages.includes(lang))
		);
	}
};

export const isFilteredForPreferredWorkLocations = (
	filterWorkLocations: SmLocation[],
	talentWorkLocations: SmLocation[],
): boolean =>
	filterWorkLocations.length === 0 ||
	filterWorkLocations
		.map((location) => location.label)
		.some((label) =>
			talentWorkLocations.map((location) => location.label).includes(label),
		) ||
	filterWorkLocations
		.filter((location) => !location.label.includes(','))
		.map((country) => countryToAlpha2Code(country.label)) // saving locations in URL only saves the label => country.label not country.country
		.some((code) =>
			talentWorkLocations
				.map((location) => location.country)
				.includes(code ?? 'undefined'),
		) ||
	talentWorkLocations
		.filter((location) => !location.label.includes(','))
		.map((country) => country.country)
		.some((code) =>
			filterWorkLocations
				.map((location) => location.label)
				.some((label) => label.endsWith(`, ${code}`)),
		);

export const buildSmAuthCookie = (
	loginProvider: LoginProviderEnum,
	cookieUserType: CookieUserTypeEnum,
	tokens: {
		access_token: string;
		refresh_token?: string;
		id_token?: string;
	},
) => ({
	[loginProviderToCookieNameMap[loginProvider]]: tokens,
	type: cookieUserType,
});
export const LoginProviderToCookieNameMap = {
	[LoginProviderEnum.GOOGLE]: 'google_auth',
	[LoginProviderEnum.LINKEDIN]: 'linkedin_auth',
	[LoginProviderEnum.SCRUMMATCH]: 'scrummatch_auth',
};
