import { StrictMode, useEffect } from 'react';
import TalentLoginForm from '../../components/SmForms/TalentLoginForm';
import SidebarLayout from '../../layouts/SidebarLayout';
import './TalentLoginPage.css';
import { PathsEnum } from '../../core/enums';
import { useNavigate } from 'react-router-dom';

export default function TalentLoginPage() {
	const navigate = useNavigate();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const handleLoginSuccess = async () => {
		navigate(PathsEnum.HOME);
	};

	return (
		<StrictMode>
			<SidebarLayout
				menu={undefined}
				content={<TalentLoginForm handleLoginSuccess={handleLoginSuccess} />}
				centerContent
			/>
		</StrictMode>
	);
}
