export enum StepStatusEnum {
	OPEN = 'sm-step-status-open',
	IN_PROGRESS = 'sm-step-status-in-progress',
	DONE = 'sm-step-status-done',
}

export enum PathsEnum {
	PROFILE = '/profile',
	ACTIVATE = '/activate',
	PROFILE_FORM = '/profile-form',
	REVIEW = '/review',
	HOME = '/home',
	ACCOUNT = '/account',
	LINKEDIN = '/linkedin',
	LOGIN = '/login',
	SIGN_UP = '/sign-up',
	ADMIN_NEW_EMPLOYER = '/admin/new-employer',
	ADMIN_EMPLOYER_LIST = '/admin/employer-list',
	ADMIN_ACTIVATE_RECRUITER = '/admin/activate-recruiter',
	ADMIN_CONNECT_TALENT = '/admin/connect-talent',
	ADMIN = '/admin',
	ADMIN_DEBUG = '/admin/debug',
	ADMIN_DASHBOARD = '/admin/dashboard',
	RECRUITER = '/recruiter',
	RECRUITER_HOME = '/recruiter/home',
	RECRUITER_SIGN_UP = '/recruiter/sign-up',
	RECRUITER_LOGIN = '/recruiter/login',
	RECRUITER_WAITING_PAGE = '/recruiter/activation',
	REVIEWER_SIGN_UP = '/reviewer/sign-up',
	REVIEWER = '/reviewer',
	REVIEWER_LOGIN = '/reviewer/login',
	REVIEWER_HOME = '/reviewer/home',
	REVIEWER_TALENTS = '/reviewer/talents',
	LANDING_PAGE_FAQ_SCRUM_MATURITY = 'https://scrummatch.com/en/support/understanding-scrum-master-maturity',
	LANDING_PAGE_FAQ_CONTRACT_TYPE = 'https://scrummatch.com/en/support/understanding-contract-types',
	LANDING_PAGE_FAQ_CHAR_LIMIT = 'https://scrummatch.com/support/form-character-limits',
	COOKIE_POLICY = 'https://scrummatch.com/privacy-policy-platform#cookies',
	PRIVACY_POLICY = 'https://scrummatch.com/privacy-policy-platform',
	TALENT_TERMS_OF_USE = 'https://scrummatch.com/terms-of-service-platform-talents',
	RECRUITER_TERMS_OF_USE = 'https://scrummatch.com/terms-of-service-platform-employers',
	LANDING_PAGE_IMPRINT = 'https://scrummatch.com/imprint',
	LANDING_PAGE_ABOUT = 'https://scrummatch.com/about-scrummatch',
	LANDING_PAGE_EMPLOYERS = 'https://scrummatch.com/hire-true-scrum-masters-and-product-owners',
	LANDING_PAGE = 'https://scrummatch.com',
	LANDING_PAGE_ACTIVATION_CONFIRMATION = 'https://scrummatch.com/en/support/activation-confirmation',
	REPORT_CONTENT = 'https://scrummatch.com/en/legal/digital-service-act-dsa/report-content?reporturl=',
}

export enum MiscTalentFiltersEnum {
	MISSING_SM_NOTES = 'Missing notes for employers',
}

export enum ProfileFieldNamesEnum {
	SLOGAN = 'My slogan',
	DELIVERING_VALUE = 'Please give us an example of how you have used Scrum to deliver business value sooner.',
	INTRODUCTION = 'Introduction',
	PREFERRED_WORK_LOCATIONS = 'Preferred work locations',
	WORK_PERMIT = 'Work permit',
	CONTRACT_TYPE = 'Work type',
	WORK_MODEL = 'Work model',
	SALARY = 'Salary expectations',
	START_DATE = 'Earliest start date',
	WORK_LANGUAGES = 'Work languages',
	WORK_EXPERIENCE = 'Work Experience',
	SCRUM_EXPERIENCE = 'Years of hands-on Scrum Master experience',
	EDUCATION = 'Education',
	CERTIFICATIONS = 'Certifications',
	COUNTRY = 'Country',
	CURRENT_LOCATION = 'Current location',
	PERSONAL_INTERESTS = 'Personal interests outside of work',
	SOCIAL_MEDIA_LINK = 'Social media',
	CONTACT_EMAIL = 'Contact email',
	REFERENCE_CONTACT = 'Reference contact',
}

export enum ProfilePreviewSectionNamesEnum {
	SCRUM_MASTER_INFORMATION = 'Scrum Master information',
	SHOULD_HIRE = 'Should you hire this Scrum Master?',
}

export enum CookieUserTypeEnum {
	TALENT = 'tal92l',
	REVIEWER = 'revwe9',
	RECRUITER = 'recgh2',
	ADMIN = 'admow3',
}

export const CookieUserTypeToHomePathMap: Record<
	CookieUserTypeEnum,
	PathsEnum
> = {
	[CookieUserTypeEnum.TALENT]: PathsEnum.HOME,
	[CookieUserTypeEnum.REVIEWER]: PathsEnum.REVIEWER_HOME,
	[CookieUserTypeEnum.RECRUITER]: PathsEnum.RECRUITER_HOME,
	[CookieUserTypeEnum.ADMIN]: PathsEnum.ADMIN_DASHBOARD,
};
