import { create } from 'zustand';
import { RecruiterProfile } from '../shared/classes/recruiterProfile';

interface RecruiterProfileZustandType {
	recruiterProfile: RecruiterProfile | null;
	setRecruiterProfile: (profile: RecruiterProfile | null) => void;
}

const useRecruiterProfileZustand = create<RecruiterProfileZustandType>(
	(set) => ({
		recruiterProfile: null,
		setRecruiterProfile: (recruiterProfile: RecruiterProfile | null) => {
			set({ recruiterProfile: recruiterProfile });
		},
	}),
);

export default useRecruiterProfileZustand;
