import { useState } from 'react';
import {
	RecruiterTermsChangeReasons,
	TalentTermsChangeReasons,
} from '../../../core/types';
import ErrorPinkBox from './ErrorPinkBox';
import LoadingButton from './LoadingButton';
import SmTalentTermsCheckbox from './SmTalentTermsCheckbox';

interface TosChangeReasonAlertProps {
	handleAcceptTerms: () => Promise<void>;
	termChangeReason:
		| TalentTermsChangeReasons
		| RecruiterTermsChangeReasons
		| undefined;
}

export default function TosChangeReasonAlert({
	handleAcceptTerms,
	termChangeReason,
}: TosChangeReasonAlertProps) {
	const [agreed, setAgreed] = useState(false);

	const reasonIntersect = termChangeReason as
		| (TalentTermsChangeReasons & RecruiterTermsChangeReasons)
		| undefined;

	const isAnyChangeReasonGiven =
		reasonIntersect?.privacyPolicy ||
		reasonIntersect?.termsOfUseTalent ||
		reasonIntersect?.termsOfUseEmployer;
	const defaultChangeReason =
		'We have updated our terms and conditions. Please accept the new terms to continue.';

	return (
		<ErrorPinkBox
			errorNode={
				<p>
					{isAnyChangeReasonGiven
						? `${reasonIntersect.termsOfUseTalent ?? ''} ${reasonIntersect.termsOfUseEmployer ?? ''} ${reasonIntersect.privacyPolicy ?? ''}`
						: defaultChangeReason}
				</p>
			}
			optionsNode={
				<div
					className={'flex gap-50 mobile-gap-0'}
					style={{ justifyContent: 'center' }}>
					<SmTalentTermsCheckbox setAgreed={setAgreed} />
					<LoadingButton
						className="sm-terms-of-service-checkbox-button"
						variant="contained"
						disabled={!agreed}
						onClick={handleAcceptTerms}
						style={{ height: 'fit-content' }}>
						Accept
					</LoadingButton>
				</div>
			}
		/>
	);
}
