import { Components, PaletteOptions, Theme, ThemeOptions } from '@mui/material';

declare module '@mui/material/styles' {
	interface Palette {
		green: Palette['primary'];
		pink: Palette['primary'];
	}

	interface PaletteOptions {
		green?: PaletteOptions['primary'];
		pink?: PaletteOptions['primary'];
	}
}

const palette: PaletteOptions = {
	primary: {
		main: '#b34090',
		contrastText: '#fff',
	},
	secondary: {
		main: '#e7e7e7',
		contrastText: '#000',
	},
	green: {
		main: '#e7f5f6',
		contrastText: '#000',
	},
	pink: {
		main: '#b34090',
		contrastText: '#fff',
	},
};

declare module '@mui/material/Button' {
	interface ButtonPropsVariantOverrides {
		login: true;
		sidebar: true;
		gradient: true;
		'gradient-outlined': true;
		dialog1: true;
		dialog2: true;
	}
}

const componentStyles = {
	step: {
		circleBackgroundColor: '#fff',
		textInCircleColor: '#602483',
		labelByCircleColor: '#fff',
	},
};

const components: Components<Omit<Theme, 'components'>> = {
	MuiStepIcon: {
		styleOverrides: {
			root: {
				color: componentStyles.step.circleBackgroundColor,
			},
			text: {
				fill: componentStyles.step.textInCircleColor,
			},
		},
	},
	MuiStepLabel: {
		styleOverrides: {
			labelContainer: {
				color: componentStyles.step.labelByCircleColor,
			},
			iconContainer: {
				color: componentStyles.step.circleBackgroundColor,
			},
			vertical: {
				paddingLeft: '25%',
			},
		},
	},
	MuiStepConnector: {
		styleOverrides: {
			vertical: {
				paddingLeft: '25%',
			},
		},
	},
	MuiButton: {
		styleOverrides: {
			root: {
				borderRadius: '9999px',
				borderWidth: '1.5px',
			},
		},
		variants: [
			{
				props: { variant: 'login' },
				style: {
					backgroundColor: '#fff',
					border: '1.5px solid #e7e7e7',
					color: '#000',
					fontSize: '16px',
					fontWeight: 600,
					letterSpacing: '1px',
				},
			},
			{
				props: { variant: 'sidebar' },
				style: {
					color: '#fff',
					textTransform: 'initial',
					justifyContent: 'flex-start',
					paddingLeft: '25%',
				},
			},
			{
				props: { variant: 'gradient' },
				style: {
					background: 'linear-gradient(90deg, #66c2c9, #602483, #8f1e8f)',
					color: '#fff',
					fontSize: '16px',
					fontWeight: 600,
					letterSpacing: '1px',
					maxWidth: '370px',
					'&:disabled': {
						background: 'rgba(0, 0, 0, 0.12)',
					},
				},
			},
			{
				props: { variant: 'gradient-outlined' },
				style: {
					background: '#fff',
					color: '#602483',
					border: '1.5px solid #602483',
					fontSize: '16px',
					fontWeight: 600,
					letterSpacing: '1px',
					maxWidth: '370px',
				},
			},
			{
				props: { variant: 'dialog1' },
				style: {
					backgroundColor: 'transparent',
					border: '1.5px solid #b34090',
					letterSpacing: '1px',
					color: '#b34090',

					'&:hover': {
						backgroundColor: 'rgba(179, 64, 144, 0.3)',
					},
				},
			},

			{
				props: { variant: 'dialog2' },
				style: {
					backgroundColor: '#e7e7e7',
					border: '1.5px solid rgba(0, 0, 0, 0)',
					letterSpacing: '1px',
					color: '#1a1a1a',

					'&:hover': {
						backgroundColor: 'rgba(179, 64, 144, 0.3)',
					},
				},
			},
		],
	},
	MuiInputBase: {
		styleOverrides: {
			root: {
				backgroundColor: '#fff',
			},
		},
	},
	MuiInputLabel: {
		styleOverrides: {
			root: {
				color: 'unset',
				'&.Mui-focused': {
					color: 'unset',
				},
			},
		},
	},
	MuiFormControl: {
		styleOverrides: {
			root: {
				'.sm-show-placeholder input[type="text"]': {
					'&:focus::placeholder': {
						color: '#000 !important',
						opacity: '0.42 !important',
						fontSize: '16px !important',
					},
					'&:not(:active)::placeholder': {
						color: '#000 !important',
						opacity: '0.42 !important',
						fontSize: '16px !important',
					},
				},
			},
		},
	},
};

export const smTheme: ThemeOptions = {
	palette,
	components,
};
