import {
	Alert,
	FormControl,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	SelectChangeEvent,
	Snackbar,
} from '@mui/material';
import { AxiosResponse } from 'axios';
import { ChangeEvent, useEffect, useState } from 'react';
import { EmailTemplate } from '../../shared/classes/talent';
import LoadingButton from './SmFormComponents/LoadingButton';
import SmTextareaAutosize from './SmFormComponents/SmTextareaAutosize';

interface EmailTemplateFormProps {
	emailTemplates: EmailTemplate[];
	sendEmail: () => Promise<AxiosResponse | undefined>;
	onChange: (value: EmailTemplate) => void;
	currentDraft?: EmailTemplate;
	disabled?: boolean;
}

export default function EmailTemplateForm({
	emailTemplates,
	sendEmail,
	currentDraft,
	onChange,
	disabled = false,
}: EmailTemplateFormProps) {
	const [showSendEmailSuccessSnackbar, setShowSendEmailSuccessSnackbar] =
		useState(false);
	const [showSendEmailFailedSnackbar, setShowSendEmailFailedSnackbar] =
		useState(false);
	const [selectedTemplate, setSelectedTemplate] = useState<string>('');

	useEffect(() => {
		if (selectedTemplate !== currentDraft?.name) {
			setSelectedTemplate(currentDraft?.name ?? '');
		}
	}, [currentDraft, selectedTemplate]);

	const handleBodyPlainTextChange = (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => {
		onChange({ ...currentDraft, bodyPlainText: event.target.value });
	};

	const handleSubjectChange = (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => {
		onChange({ ...currentDraft, subject: event.target.value });
	};

	const handleTemplateChange = (event: SelectChangeEvent<string>) => {
		onChange(
			emailTemplates.find((templ) => templ.name === event.target.value) ?? {},
		);
	};

	const handleSendEmail = async () => {
		try {
			const result = await sendEmail();

			if (result?.status === 200) {
				setShowSendEmailSuccessSnackbar(true);
				onChange(new EmailTemplate());
				setSelectedTemplate('');
			} else {
				setShowSendEmailFailedSnackbar(true);
			}
		} catch {
			setShowSendEmailFailedSnackbar(true);
		}
	};

	return (
		<>
			<div className="sm-form-inline-container flex-column">
				<FormControl className={`sm-label`} disabled={disabled}>
					<InputLabel htmlFor="templates" variant="standard" shrink={false}>
						Email templates
					</InputLabel>
					<Select
						id="templates"
						labelId="templates"
						aria-label="Email templates"
						onChange={(event) => {
							handleTemplateChange(event);
							setSelectedTemplate(event.target.value);
						}}
						value={selectedTemplate}
						input={<OutlinedInput />}
						displayEmpty>
						{emailTemplates.map((item, index) => {
							return (
								<MenuItem key={index} value={item.name}>
									{item.name}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
				<SmTextareaAutosize
					placeholder={'Subject'}
					id={'currentDraftSubject'}
					value={currentDraft?.subject}
					handleChange={handleSubjectChange}
					containerClass="full-width"
					disabled={disabled}
				/>
				<SmTextareaAutosize
					placeholder={'Body Plain Text'}
					id={'currentDraftBodyPlainText'}
					value={currentDraft?.bodyPlainText}
					handleChange={handleBodyPlainTextChange}
					textareaAutosizeProps={{
						minRows: 20,
					}}
					containerClass="full-width"
					disabled={disabled}
				/>
				<LoadingButton
					disabled={disabled}
					variant="contained"
					onClick={handleSendEmail}
					className="align-self-flex-start">
					Send email
				</LoadingButton>
			</div>
			<Snackbar
				open={showSendEmailSuccessSnackbar}
				autoHideDuration={6000}
				onClose={() => setShowSendEmailSuccessSnackbar(false)}>
				<Alert
					onClose={() => setShowSendEmailSuccessSnackbar(false)}
					severity="success">
					Email sent successfully
				</Alert>
			</Snackbar>
			<Snackbar
				open={showSendEmailFailedSnackbar}
				onClose={() => setShowSendEmailFailedSnackbar(false)}>
				<Alert
					onClose={() => setShowSendEmailFailedSnackbar(false)}
					severity="error"
					sx={{ width: '100%' }}>
					There was an error sending the email
				</Alert>
			</Snackbar>
		</>
	);
}
