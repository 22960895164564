import { GoogleOAuthProvider } from '@react-oauth/google';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import {
	authenticateWithGoogle,
	authenticateWithLinkedIn,
} from '../../api/auth';
import { isTalentLoggedIn } from '../../api/talents';
import { COOKIE_CONFIG, SM_AUTH_COOKIE } from '../../core/consts';
import { CookieUserTypeEnum, PathsEnum } from '../../core/enums';
import { buildSmAuthCookie } from '../../core/utils';
import { LoginProviderEnum } from '../../shared/classes/talent';
import GoogleLoginButton from '../SmButtons/GoogleLoginButton';
import LinkedInButton from '../SmButtons/LinkedinLoginButton';
import ErrorPinkBox from './SmFormComponents/ErrorPinkBox';
import './TalentLoginForm.css';

interface TalentLoginFormProps {
	handleLoginSuccess: () => void;
}

export default function TalentLoginForm({
	handleLoginSuccess,
}: TalentLoginFormProps) {
	const [, setCookies] = useCookies([SM_AUTH_COOKIE]);
	const [showErrorMessage, setShowErrorMessage] = useState(false);
	const [isOnLinkedIn] = useState(
		window.navigator.userAgent.includes('LinkedInApp'),
	);

	const handleLogin = async () => {
		try {
			if (await isTalentLoggedIn()) {
				handleLoginSuccess();
			} else {
				console.log('else');
				setShowErrorMessage(true);
			}
		} catch (err) {
			console.log("Couldn't log in", err);
			setShowErrorMessage(true);
		}
	};

	const handleGoogleAuthSuccess = async ({ code }: { code: string }) => {
		const response = await authenticateWithGoogle(code);
		const { access_token, id_token, refresh_token } = response.data;
		const tokens = { access_token, id_token, refresh_token };
		setCookies(
			SM_AUTH_COOKIE,
			buildSmAuthCookie(
				LoginProviderEnum.GOOGLE,
				CookieUserTypeEnum.TALENT,
				tokens,
			),
			COOKIE_CONFIG,
		);
		await handleLogin();
	};

	const handleLinkedInAuthSuccess = async (code: string) => {
		const response = await authenticateWithLinkedIn(code);
		const { access_token } = response.data;
		if (!access_token) {
			return;
		}
		const tokens = { access_token };
		setCookies(
			SM_AUTH_COOKIE,
			buildSmAuthCookie(
				LoginProviderEnum.LINKEDIN,
				CookieUserTypeEnum.TALENT,
				tokens,
			),
			COOKIE_CONFIG,
		);
		await handleLogin();
	};

	const linkedInWarning = (
		<>
			<p className={'sm-linked-in-error-text'}>
				It looks like you're trying to use ScrumMatch from within the LinkedIn
				app.
			</p>
			<p className={'sm-linked-in-error-text'}>
				Please open this link on your browser to sign up and login:
			</p>
			<a
				className={'sm-linked-in-error-text'}
				href={'https://app.scrummatch.com/'}>
				https://app.scrummatch.com
			</a>
		</>
	);

	return (
		<GoogleOAuthProvider
			clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ''}>
			<div className="flex-column flex-center">
				<>
					<h1 className="font-size-responsive-heading text-align-center">
						Login
					</h1>
					{isOnLinkedIn ? (
						linkedInWarning
					) : (
						<>
							<LinkedInButton
								clientId={process.env.REACT_APP_LINKEDIN_CLIENT_ID ?? ''}
								redirectUri={`${window.location.origin}/linkedin`}
								scope="openid email"
								handleSuccess={handleLinkedInAuthSuccess}
								label={'LOGIN WITH LINKEDIN'}
							/>
							<GoogleLoginButton
								handleSuccess={handleGoogleAuthSuccess}
								label={'LOGIN WITH GOOGLE'}
							/>
							<div className="sm-info-text">
								<p>
									DON'T HAVE AN ACCOUNT YET?&nbsp;
									<a href={PathsEnum.SIGN_UP}>SIGN UP HERE</a>
								</p>
								<p>NEED HELP? CONTACT SUPPORT@SCRUMMATCH.COM</p>
							</div>
						</>
					)}
					{showErrorMessage ? (
						<ErrorPinkBox
							errorNode={
								<p>
									We couldn't find your user. You can{' '}
									<a href={PathsEnum.SIGN_UP} target="_blank" rel="noreferrer">
										click here to sign up
									</a>{' '}
									and create your account.
								</p>
							}
						/>
					) : null}
				</>
			</div>
		</GoogleOAuthProvider>
	);
}
