import { FormControl, FormGroup, FormLabel } from '@mui/material';
import { userLocale } from '../../core/consts';
import { ProfileFieldNamesEnum } from '../../core/enums';
import { SalaryExpectationsFilter } from '../../shared/classes/employerTalentFilter';
import {
	ContractType,
	SalaryExpecationsCurrencyWithAny,
	SalaryExpectationsCurrency,
} from '../../shared/classes/profile';
import SmCheckbox from '../SmForms/SmFormComponents/SmCheckbox';
import SmSalaryField from '../SmForms/SmFormComponents/SmSalaryField';

interface ContractTypeAndSalaryExpectationsFilterInputProps {
	handleContractTypeChange: (
		event: React.ChangeEvent<HTMLInputElement>,
		checked: boolean,
	) => void;
	contractType: ContractType;
	salaryExpectations: SalaryExpectationsFilter;
	handleSalaryExpectationsChange: (
		newSalaryExpectations: SalaryExpectationsFilter,
	) => void;
}

export default function ContractTypeAndSalaryExpectationsFilterInput({
	handleContractTypeChange,
	contractType,
	salaryExpectations,
	handleSalaryExpectationsChange,
}: ContractTypeAndSalaryExpectationsFilterInputProps) {
	const handleCurrencyChange = (value: SalaryExpecationsCurrencyWithAny) => {
		handleSalaryExpectationsChange({
			...salaryExpectations!,
			currency: value as SalaryExpectationsCurrency,
		});
	};

	const isYearlyInputDisabled = !contractType?.employment;
	const handleYearlyChange = (value: number | null) => {
		handleSalaryExpectationsChange({
			...salaryExpectations!,
			yearlyGrossMax: value,
		});
	};

	const isDailyInputDisabled =
		!contractType?.freelance && !contractType?.supplier;
	const handleDailyChange = (value: number | null) => {
		handleSalaryExpectationsChange({
			...salaryExpectations!,
			hourlyGrossMax: value,
		});
	};

	return (
		<FormControl
			className="sm-custom-input padding-right-50 mobile-no-padding border-right tablet-no-border"
			component="fieldset"
			variant="standard"
			sx={{ marginTop: '19px !important' }}>
			<FormLabel
				component="legend"
				className="sm-label-bold"
				sx={{ marginBottom: -0.5, color: '#000' }}>
				{ProfileFieldNamesEnum.CONTRACT_TYPE}
			</FormLabel>
			<FormGroup style={{ maxWidth: 700 }}>
				<div className={'flex flex-equal-size-children'}>
					<SmCheckbox
						checkboxLabel="Employment"
						checkboxName="employment"
						isChecked={contractType?.employment ?? false}
						handleChange={handleContractTypeChange}
						showInfoIcon={false}
					/>
					<SmSalaryField
						required={!isYearlyInputDisabled}
						name="salary-yearly"
						label={'Max yearly gross salary'}
						disabled={isYearlyInputDisabled}
						onSalaryChange={handleYearlyChange}
						onCurrencyChange={handleCurrencyChange}
						value={salaryExpectations?.yearlyGrossMax ?? null}
						userLanguage={userLocale}
						currency={salaryExpectations?.currency}
						allowAnyCurrency={true}
					/>
				</div>
				<div className={'flex flex-equal-size-children'}>
					<div>
						<SmCheckbox
							checkboxLabel="Independent Consultant"
							checkboxName="freelance"
							isChecked={contractType?.freelance ?? false}
							handleChange={handleContractTypeChange}
							showInfoIcon={false}
						/>
						<SmCheckbox
							checkboxLabel="Consulting Firm"
							checkboxName="supplier"
							isChecked={contractType?.supplier ?? false}
							handleChange={handleContractTypeChange}
							showInfoIcon={false}
						/>
					</div>
					<SmSalaryField
						required={!isDailyInputDisabled}
						name="salary-daily"
						label={'Max hourly rate'}
						disabled={isDailyInputDisabled}
						onSalaryChange={handleDailyChange}
						onCurrencyChange={handleCurrencyChange}
						value={salaryExpectations?.hourlyGrossMax ?? null}
						userLanguage={userLocale}
						currency={salaryExpectations?.currency}
						allowAnyCurrency={true}
					/>
				</div>
			</FormGroup>
		</FormControl>
	);
}
