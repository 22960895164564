import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import TranslateOutlinedIcon from '@mui/icons-material/TranslateOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Card, CardContent, Tooltip } from '@mui/material';
import { useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { userLocale } from '../../core/consts';
import { PathsEnum } from '../../core/enums';
import { contractTypeLookup, workModelLookup } from '../../core/lookups';
import { startDateText } from '../../core/utils';
import {
	ContractType,
	ContractTypeKeys,
	SalaryExpectations,
	WorkModel,
	WorkModelKeys,
	currencyToSymbol,
} from '../../shared/classes/profile';
import { TalentForRecruiters } from '../../shared/classes/talent';
import useScrumMaturityZustand from '../../zustand/ScrumMaturityZustand';
import './TalentCard.css';

interface TalentCardProps {
	talentInfo?: TalentForRecruiters;
	handleBookmark: (talentUrlId: string) => Promise<void>;
	handleHide: (talentUrlId: string) => Promise<void>;
	hasAlreadyScrolled: boolean;
	setHasAlreadyScrolled: (hasScrolled: boolean) => void;
}

export default function TalentCard({
	talentInfo,
	handleBookmark,
	handleHide,
	hasAlreadyScrolled,
	setHasAlreadyScrolled,
}: TalentCardProps) {
	const navigate = useNavigate();
	const location = useLocation();
	const { scrumMaturityLookup } = useScrumMaturityZustand();
	const talent = talentInfo?.talent;
	const capitaliseFirstLetter = (str: string) =>
		str.charAt(0).toUpperCase() + str.slice(1);
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (
			!hasAlreadyScrolled &&
			location.hash &&
			talent &&
			location.hash.includes(talent?.urlId!) &&
			ref.current
		) {
			ref.current.scrollIntoView();
			setHasAlreadyScrolled(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fullName = () => {
		if (!talent) return '';
		return `${talent.profile.firstName} ${
			talent.profile.middleName ? talent.profile.middleName + ' ' : ''
		}${talent.profile.surnames}`;
	};

	const linearGradient = (
		<svg width={0} height={0}>
			<linearGradient id="linearColors" x1={0} y1={0} x2={1} y2={0}>
				<stop offset={0} stopColor="#66c2c9" />
				<stop offset={1} stopColor="#602483" />
			</linearGradient>
		</svg>
	);

	const bookmarkIcon = (
		<Tooltip
			title={
				talentInfo?.isBookmarked ? 'Remove bookmark' : 'Bookmark this talent'
			}>
			<span onClick={() => handleBookmark(talent?.urlId ?? '')}>
				{talentInfo?.isBookmarked ? (
					<>
						{linearGradient}
						<BookmarkIcon
							className="no-webkit-tap-highlight-color"
							fontSize="large"
							sx={{ fill: 'url(#linearColors)' }}
						/>
					</>
				) : (
					<BookmarkBorderIcon
						className="no-webkit-tap-highlight-color"
						fontSize="large"
						style={{ color: '#ccc' }}
					/>
				)}
			</span>
		</Tooltip>
	);
	const hideIcon = (
		<Tooltip
			title={talentInfo?.isHidden ? 'Reveal this talent' : 'Hide this talent'}>
			<span onClick={() => handleHide(talent?.urlId ?? '')}>
				{
					<>
						{linearGradient}
						<VisibilityOffIcon
							className="no-webkit-tap-highlight-color"
							fontSize="large"
							style={
								talentInfo?.isHidden
									? { fill: 'url(#linearColors' }
									: { color: '#ccc' }
							}
						/>
					</>
				}
			</span>
		</Tooltip>
	);

	const contractTypeList = () => {
		const contractType = talent?.profile.contractType;
		if (!contractType) return '';
		const valueStrings = Object.keys(contractType)
			.filter((key) => contractType[key as keyof ContractType] === true)
			.map((key) => contractTypeLookup[key as ContractTypeKeys]);

		return valueStrings.join(', ');
	};

	const workModelList = () => {
		const workModel = talent?.profile.workModel;
		if (!workModel) return '';
		const valueStrings = Object.keys(workModel ?? {})
			.filter((key) => workModel[key as keyof WorkModel] === true)
			.map((key) => workModelLookup[key as WorkModelKeys]);
		return valueStrings.join(', ').replace('onSite', 'on-site');
	};

	const preferredWorkLocations = talent
		? talent.profile.preferredWorkLocations.map((loc) => loc.label).join('; ')
		: '';

	const workPermit = talent?.profile.workPermit.wouldNeedIt
		? 'I would need a work permit.'
		: 'I have / do not need a work permit.';

	const workLanguages = talent?.profile.preferredWorkLanguages
		.map((lang) => lang)
		.join('; ');

	const scrumMaturity = () => {
		const maturity = talent?.evalData.scrumMaturity;
		if (!maturity) return '';
		return capitaliseFirstLetter(scrumMaturityLookup[maturity].dropdownLabel);
	};

	const trimmedMoreText = (text: string, maxLength = 100) => {
		if (text.length > maxLength) {
			return (
				<span>
					{text.substring(0, maxLength)}…{' '}
					<span className="sm-clickable-text">more</span>
				</span>
			);
		} else {
			return <span>{text}</span>;
		}
	};

	const formatSalary = (salaryExpectations: SalaryExpectations) => {
		return (
			<span>
				{salaryExpectations.currency && salaryExpectations.hourlyGross ? (
					<>
						{currencyToSymbol[salaryExpectations.currency]}
						{salaryExpectations.hourlyGross.toLocaleString(userLocale)}/hour
						<br />
					</>
				) : null}
				{salaryExpectations.currency && salaryExpectations.yearlyGross ? (
					<>
						{currencyToSymbol[salaryExpectations.currency]}
						{salaryExpectations.yearlyGross.toLocaleString(userLocale)}/year
					</>
				) : null}
			</span>
		);
	};

	return (
		<>
			{talent ? (
				<Card
					ref={ref}
					id={talent.urlId}
					className={`sm-card position-relative ${
						talent.isConnected ? 'sm-card-connected' : ''
					} ${
						location.hash?.includes(talent.urlId!) ? 'sm-card-last-visited' : ''
					}`}>
					<div className="sm-talent-card-action-icons right-top-corner-20">
						{bookmarkIcon} {hideIcon}
					</div>
					<Link
						onClick={() => {
							navigate(`${PathsEnum.RECRUITER_HOME}#${talent.urlId}`, {
								replace: true,
							});
						}}
						to={`${PathsEnum.PROFILE}/${talent.urlId}`}
						data-testid={talent.urlId}
						className="sm-card-link">
						<CardContent>
							<div className="sm-card-content">
								<div className="sm-card-image">
									<img
										src={require('../../assets/avatar.png')}
										alt="user avatar"
									/>
								</div>
								<div className="sm-card-info">
									<h3>{fullName()}</h3>
									<div className="sm-form-inline-container">
										<div className="sm-third-screen">
											<ul className="sm-card-column-1 sm-card-column">
												{preferredWorkLocations ? (
													<li>
														<PlaceOutlinedIcon />
														{trimmedMoreText(preferredWorkLocations)}
													</li>
												) : null}
												{workPermit ? (
													<li>
														<TaskOutlinedIcon />
														<span>{workPermit}</span>
													</li>
												) : null}
												{workLanguages ? (
													<li>
														<TranslateOutlinedIcon />
														{trimmedMoreText(workLanguages)}
													</li>
												) : null}
											</ul>
										</div>
										<div className="sm-third-screen">
											<ul className="sm-card-column-2 sm-card-column">
												{talent.profile.contractType ? (
													<li>
														<HandshakeOutlinedIcon />
														<span>{contractTypeList()}</span>
													</li>
												) : null}
												{talent.profile.workModel ? (
													<li>
														<BusinessCenterOutlinedIcon />
														<span>{workModelList()}</span>
													</li>
												) : null}
												{talent.profile.salaryExpectations?.hourlyGross ||
												talent.profile.salaryExpectations?.yearlyGross ? (
													<li>
														<LocalAtmOutlinedIcon />
														{formatSalary(talent.profile.salaryExpectations)}
													</li>
												) : null}
												{talent.profile.startDate ? (
													<li>
														<CalendarMonthOutlinedIcon />
														<span>
															{startDateText(
																talent.profile.startDate ??
																	new Date().toISOString(),
															)}
														</span>
													</li>
												) : null}
											</ul>
										</div>
										<div className="sm-third-screen">
											<ul className="sm-card-column-3 sm-card-column">
												<li>
													<VerifiedOutlinedIcon />
													<span>{scrumMaturity()}</span>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</CardContent>
					</Link>
				</Card>
			) : null}
		</>
	);
}
