import { extractNumber, extractString } from './utils';

export class SmLocation {
	label: string;
	asciiLabel?: string;
	admin1?: string;
	admin2?: string;
	country: string;
	lat?: string;
	lng?: string;
	name?: string;
	population?: number;

	constructor(data?: SmLocation) {
		this.label = extractString(data?.label);
		this.asciiLabel = extractString(data?.asciiLabel);
		this.admin1 = extractString(data?.admin1);
		this.admin2 = extractString(data?.admin2);
		this.country = extractString(data?.country);
		this.lat = extractString(data?.lat);
		this.lng = extractString(data?.lng);
		this.name = extractString(data?.name);
		this.population = extractNumber(data?.population, 0);
	}

	isReadyToSubmit?(): boolean {
		return !!this.label && !!this.country;
	}
}
