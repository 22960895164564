import { Chip } from '@mui/material';
import { ReactNode, useState } from 'react';
import { userLocale } from '../../core/consts';
import {
	ProfileFieldNamesEnum,
	ProfilePreviewSectionNamesEnum,
} from '../../core/enums';
import { contractTypeLookup, workModelLookup } from '../../core/lookups';
import { startDateText } from '../../core/utils';
import {
	AnonymizedFields,
	Checksums,
	Profile,
	SalaryExpectationsCurrency,
	WorkExperience,
	WorkModel,
	WorkModelKeys,
	currencyToSymbol,
} from '../../shared/classes/profile';
import { SmLocation } from '../../shared/classes/smLocation';
import { SmLanguage } from '../../shared/types/languages';
import OutlinedBox from './OutlinedBox';
import ShortenTextBox from './ShortenTextBox';
import './WorkWithMeBox.css';

interface UserAvailabilityBoxProps {
	profile?: Profile;
	email?: string;
	isAnonymousView: boolean;
}

export default function WorkWithMeBox({
	profile,
	email,
	isAnonymousView,
}: UserAvailabilityBoxProps) {
	const [showAllWorkExperiences, setShowAllWorkExperiences] = useState(false);

	if (!profile) return <div></div>;

	const { contractType, workModel, startDate, workPermit, salaryExpectations } =
		profile;

	const workModelList = Object.keys(workModel ?? {}).filter(
		(key) => workModel[key as keyof WorkModel] === true,
	);

	const anonymousFieldContent = (
		field: keyof Pick<Profile, 'intro' | 'personalInterests'>,
		fieldAnonymous: keyof Omit<AnonymizedFields, 'workExperiencesAnonymous'>,
		fieldChecksum: keyof Checksums,
		fieldAnonymousChecksum: keyof Checksums,
	): string => {
		const anonymousComingSoonText =
			'This will be anonymized soon. Check again in a few minutes.';
		let content = profile?.[field] ?? '';
		const checksumsMatch =
			profile?.checksums[fieldChecksum] ===
			profile?.checksums[fieldAnonymousChecksum];
		if (isAnonymousView && checksumsMatch) {
			content =
				profile?.anonymizedFields?.[fieldAnonymous] || profile?.[field] || '';
		} else if (isAnonymousView && !checksumsMatch) {
			content = anonymousComingSoonText;
		}
		return content;
	};

	const anonymousWorkExperienceContent = (
		index: number,
		field: keyof WorkExperience,
		fieldIndex?: number,
	) => {
		if (
			isAnonymousView &&
			profile?.checksums.workExperiencesChecksum !==
				profile?.checksums.workExperiencesAnonymousChecksum
		) {
			if (field === 'keyAchievements' && fieldIndex !== undefined) {
				return (
					profile?.workExperiences[index]?.keyAchievements[fieldIndex] || ''
				);
			}
			return profile?.workExperiences[index]?.[field] || '';
		} else if (isAnonymousView) {
			if (field === 'keyAchievements' && fieldIndex !== undefined) {
				return (
					profile?.anonymizedFields.workExperiencesAnonymous[index]
						?.keyAchievements[fieldIndex] ||
					profile?.workExperiences[index]?.keyAchievements[fieldIndex] ||
					''
				);
			}
			return (
				profile.anonymizedFields.workExperiencesAnonymous[index]?.[field] ||
				profile.workExperiences[index]?.[field] ||
				''
			);
		} else {
			if (field === 'keyAchievements' && fieldIndex !== undefined) {
				return (
					profile?.workExperiences[index]?.keyAchievements[fieldIndex] || ''
				);
			}
			return profile?.workExperiences[index]?.[field] || '';
		}
	};

	const intro =
		profile.intro || profile?.anonymizedFields?.introAnonymous ? (
			<div>
				<h4>{ProfileFieldNamesEnum.INTRODUCTION}</h4>
				<p className="overflow-wrap-anywhere">
					<ShortenTextBox
						text={anonymousFieldContent(
							'intro',
							'introAnonymous',
							'introChecksum',
							'introAnonymousChecksum',
						)}
					/>
				</p>
			</div>
		) : null;

	const preferredWorkLocationsChips = profile.preferredWorkLocations.length ? (
		<div>
			<h4>{ProfileFieldNamesEnum.PREFERRED_WORK_LOCATIONS}</h4>
			<div className="sm-work-with-me-box-chip-container">
				{profile.preferredWorkLocations.map((loc: SmLocation, i) => (
					<Chip
						key={loc.label + i}
						label={loc.label}
						sx={{ margin: '5px 5px 5px 0' }}
						className="sm-multiline-chip"
						variant="outlined"
					/>
				))}
			</div>
		</div>
	) : null;

	const salaryDisplay = (
		value: number | null | undefined,
		currency: SalaryExpectationsCurrency = 'USD',
		period: 'year' | 'hour',
	) => (
		<>
			{value || value === 0 ? (
				<span>
					{currencyToSymbol[currency]}
					{value.toLocaleString(userLocale)}/{period}
				</span>
			) : null}
		</>
	);

	const contractTypeEntry = (key: string, salary: ReactNode) => (
		<div className="flex-space-between gap-10 flex-equal-size-children">
			<div>{key}</div>
			<div>{salary}</div>
		</div>
	);

	const contractTypeEl = (
		<div>
			<h4>{ProfileFieldNamesEnum.CONTRACT_TYPE}</h4>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: 10,
				}}>
				{contractType.employment ? (
					<>
						{contractTypeEntry(
							contractTypeLookup['employment'],
							salaryDisplay(
								salaryExpectations?.yearlyGross,
								salaryExpectations?.currency,
								'year',
							),
						)}
					</>
				) : null}
				{contractType.freelance ? (
					<>
						{contractTypeEntry(
							contractTypeLookup['freelance'],
							salaryDisplay(
								salaryExpectations?.hourlyGross,
								salaryExpectations?.currency,
								'hour',
							),
						)}
					</>
				) : null}
				{contractType.supplier ? (
					<>
						{contractTypeEntry(
							contractTypeLookup['supplier'],
							salaryDisplay(
								salaryExpectations?.hourlyGross,
								salaryExpectations?.currency,
								'hour',
							),
						)}
					</>
				) : null}
			</div>
		</div>
	);

	const workPermitP = (
		<p>
			{workPermit.wouldNeedIt
				? 'I would need a work permit.'
				: 'I have / do not need a work permit.'}
			<br />
		</p>
	);

	const startDateEl = profile.startDate ? (
		<div>
			<h4>{ProfileFieldNamesEnum.START_DATE}</h4>
			{startDateText(startDate)}
		</div>
	) : null;

	const workModelEl = (
		<div>
			<h4>{ProfileFieldNamesEnum.WORK_MODEL}</h4>
			{workModelList && workModelList.length > 1 ? (
				<ul>
					{workModelList.map((value) => (
						<li key={value}>{workModelLookup[value as WorkModelKeys]}</li>
					))}
				</ul>
			) : (
				<p>
					{workModelList && workModelList.length === 1
						? workModelLookup[workModelList[0] as WorkModelKeys]
						: ''}
				</p>
			)}
			{!workModelList || workModelList.length === 0 ? <p>&ndash;</p> : null}
		</div>
	);

	const workLanguagesChips = profile.preferredWorkLanguages.length ? (
		<div>
			<h4>{ProfileFieldNamesEnum.WORK_LANGUAGES}</h4>
			<div className="sm-work-with-me-box-chip-container">
				{profile.preferredWorkLanguages.map((lang: SmLanguage, i) => (
					<Chip
						key={lang + i}
						label={lang}
						sx={{ margin: '5px 5px 5px 0' }}
						className="sm-multiline-chip"
						variant="outlined"
					/>
				))}
			</div>
		</div>
	) : null;

	const scrumExperienceInYears =
		(profile.scrumExperienceInYears || profile.scrumExperienceInYears === 0) &&
		profile.scrumExperienceInYears >= 0 ? (
			<div>
				<h4>{ProfileFieldNamesEnum.SCRUM_EXPERIENCE}</h4>
				{profile.scrumExperienceInYears}
			</div>
		) : null;

	const certificationsChips = profile.certifications ? (
		<div>
			<h4>{ProfileFieldNamesEnum.CERTIFICATIONS}</h4>
			<div className="sm-work-with-me-box-chip-container">
				{profile.certifications.split(';').map((cert: string, i) => (
					<Chip
						key={cert + i}
						label={cert}
						sx={{ margin: '5px 5px 5px 0' }}
						className="sm-multiline-chip"
						variant="outlined"
					/>
				))}
			</div>
		</div>
	) : null;

	const educationChips = profile.education ? (
		<div>
			<h4>{ProfileFieldNamesEnum.EDUCATION}</h4>
			<div className="sm-work-with-me-box-chip-container">
				{profile.education.split(';').map((edu: string, i) => (
					<Chip
						key={edu + i}
						label={edu}
						sx={{ margin: '5px 5px 5px 0' }}
						className="sm-multiline-chip"
						variant="outlined"
					/>
				))}
			</div>
		</div>
	) : null;

	const myLocationP = profile.currentLocation.label ? (
		<div>
			<h4>{ProfileFieldNamesEnum.CURRENT_LOCATION}</h4>
			<p>{profile.currentLocation.label}</p>
		</div>
	) : null;

	const personalInterestsToShow = isAnonymousView
		? profile.anonymizedFields?.personalInterestsAnonymous ||
			profile.personalInterests ||
			''
		: profile.personalInterests ?? '';

	const personalInterestsP = personalInterestsToShow ? (
		<div>
			<h4>{ProfileFieldNamesEnum.PERSONAL_INTERESTS}</h4>
			<p className="overflow-wrap-anywhere">{personalInterestsToShow}</p>
		</div>
	) : null;

	const socialMediaLinks =
		!isAnonymousView && profile.socialMediaLink ? (
			<div>
				<h4>{ProfileFieldNamesEnum.SOCIAL_MEDIA_LINK}</h4>
				<a href={profile.socialMediaLink} className={'overflow-wrap-anywhere'}>
					{profile.socialMediaLink}
				</a>
			</div>
		) : null;

	const contactEmail =
		!isAnonymousView && email ? (
			<div>
				<h4>{ProfileFieldNamesEnum.CONTACT_EMAIL}</h4>
				<a href={`mailto:${email}`} target="_blank" rel="noreferrer">
					{email}
				</a>
			</div>
		) : null;

	const referenceContact =
		!isAnonymousView && profile.referenceContact ? (
			<div>
				<h4>{ProfileFieldNamesEnum.REFERENCE_CONTACT}</h4>
				<p>{profile.referenceContact}</p>
			</div>
		) : null;

	const isWorkExperienceComplete = (
		workExperience: WorkExperience,
	): boolean => {
		return (
			!!workExperience.role &&
			!!workExperience.industry &&
			!!workExperience.startDate &&
			!!workExperience.endDate &&
			workExperience.keyAchievements.length > 0 &&
			workExperience.keyAchievements.some((ka) => !!ka.trim())
		);
	};

	const keyAchievementChip = (achievement: string, j: number, i: number) => {
		const anonymousContent = anonymousWorkExperienceContent(
			i,
			'keyAchievements',
			j,
		);
		return !!achievement && !!anonymousContent ? (
			<Chip
				key={achievement + j}
				label={anonymousContent}
				sx={{ margin: '5px 5px 5px 0' }}
				className="sm-multiline-chip max-width-fit-content"
				variant="outlined"
			/>
		) : null;
	};

	const workExperiencesEl = (wxp: WorkExperience, i: number) => {
		return wxp.keyAchievements.length &&
			wxp.keyAchievements.some((ka) => !!ka.trim()) ? (
			<div key={`work-experience-${i}`}>
				{showAllWorkExperiences && i > 0 ? <>{dashedSeparator}</> : null}
				<div className="sm-form-inline-container">
					<p>
						<strong>Role:&nbsp;&nbsp;</strong>
						{anonymousWorkExperienceContent(i, 'role')}
					</p>
					<p>
						<strong>Industry:&nbsp;&nbsp;</strong>
						{anonymousWorkExperienceContent(i, 'industry')}
					</p>
				</div>
				<div className="sm-form-inline-container">
					{wxp.startDate && wxp.endDate ? (
						<>
							<p>
								<strong>From:&nbsp;&nbsp;</strong>
								{new Date(wxp.startDate).toLocaleDateString('en-US', {
									month: 'long',
									year: 'numeric',
								})}
							</p>
							<p>
								<strong>To:&nbsp;&nbsp;</strong>
								{wxp.endDate === 'present'
									? 'Present'
									: new Date(wxp.endDate).toLocaleDateString('en-US', {
											month: 'long',
											year: 'numeric',
										})}
							</p>
						</>
					) : null}
				</div>
				<p>
					<strong>Key Achievments:</strong>
				</p>
				{!showAllWorkExperiences && i === 0 ? (
					<p
						className="sm-clickable-text"
						onClick={() => setShowAllWorkExperiences(true)}>
						...more
					</p>
				) : (
					<div className="flex-column">
						{wxp.keyAchievements.map((achievement: string, j) =>
							!!achievement ? keyAchievementChip(achievement, j, i) : null,
						)}
					</div>
				)}
			</div>
		) : null;
	};

	const dashedSeparator = (
		<div
			style={{
				height: '1px',
				width: '100%',
				borderBottom: '1px dashed #bbb',
				margin: '15px 0',
			}}
		/>
	);

	const solidSeparator = (
		<div
			style={{
				height: '1px',
				width: '100%',
				borderBottom: '1px solid #bbb',
				margin: '15px 0',
			}}
		/>
	);

	return (
		<OutlinedBox
			title={ProfilePreviewSectionNamesEnum.SCRUM_MASTER_INFORMATION}
			classes="sm-profile-preview-teal-outline-box"
			slot={
				<div style={{ flexDirection: 'column' }}>
					{intro}
					{profile.workExperiences.length ? (
						<>
							{solidSeparator}
							<h4>{ProfileFieldNamesEnum.WORK_EXPERIENCE}</h4>
						</>
					) : null}
					{profile.workExperiences
						.filter((_wxp, i) => (showAllWorkExperiences ? true : i === 0))
						.filter((wxp) => isWorkExperienceComplete(wxp))
						.map((wxp, i) => workExperiencesEl(wxp, i))}
					{profile.workExperiences.length && showAllWorkExperiences ? (
						<p
							className="sm-clickable-text"
							onClick={() => setShowAllWorkExperiences(false)}>
							less
						</p>
					) : null}
					{solidSeparator}
					<div className="sm-form-inline-container">
						<div className="sm-half-screen flex-column gap-10">
							{preferredWorkLocationsChips}
							{workPermitP}
							{contractTypeEl}
							{workModelEl}
							{startDateEl}
						</div>
						<div className="sm-half-screen flex-column gap-10">
							{workLanguagesChips}
							{scrumExperienceInYears}
							{educationChips}
							{certificationsChips}
							{myLocationP}
							{personalInterestsP}
							{socialMediaLinks}
							{contactEmail}
							{referenceContact}
						</div>
					</div>
				</div>
			}
		/>
	);
}
