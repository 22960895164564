import { countryToAlpha2Code } from '../assets/utils';
import { SmLanguage } from '../types/languages';
import { SmLocation } from './smLocation';
import {
	salaryExpectationsCurrencies,
	SalaryExpectationsCurrency,
} from './profile';

/**
 * has limit of 200 chars
 */
export const extractString = (
	value: string | undefined,
	defaultValue = '',
	maxLength = 200,
): string => {
	if (typeof value === 'string') {
		return value.trim().substring(0, maxLength);
	} else {
		return defaultValue;
	}
};

export const extractNumber = <T extends number | null>(
	value: number | null | undefined,
	defaultValue: T,
): number | T => {
	if ((!!value || value === 0) && !isNaN(Number(value))) {
		return Number(value);
	} else {
		return defaultValue;
	}
};

export const extractBoolean = (
	value: boolean | undefined,
	defaultValue = false,
) => {
	if (value && (typeof value === 'boolean' || value === 'true')) {
		return value as boolean;
	} else {
		return defaultValue;
	}
};

export const extractArray = <T>(
	array: T[] | undefined,
	defaultValue: T[] = [],
	mappingCallback?: (data?: any) => T,
): T[] => {
	if (array && Array.isArray(array)) {
		if (mappingCallback) {
			return array.map((item) => mappingCallback(item));
		} else {
			return array;
		}
	} else {
		return defaultValue;
	}
};

export type Projection<T extends string> = Record<`${T}`, 1> | Record<'_id', 0>;

export enum ViewPermissionsEnum {
	ANONYMOUS = 'anonymous',
	REVIEWER = 'reviewer',
	MYSELF = 'myself',
	CONNECTED_RECRUITER = 'connected-recruiter',
}

export const extractDate = <T extends Date | null>(
	value: Date | undefined | null,
	defaultValue: T = null as T,
): Date | T => {
	if (value && new Date(value).toString() !== 'Invalid Date') {
		return new Date(value);
	} else {
		return defaultValue;
	}
};

export const isFilteredForLanguages = (
	filterLanguages: SmLanguage[],
	talentLanguages: SmLanguage[],
): boolean =>
	filterLanguages.length === 0 ||
	filterLanguages.every((lang) => talentLanguages.includes(lang));

export const isFilteredForPreferredWorkLocations = (
	filterWorkLocations: SmLocation[],
	talentWorkLocations: SmLocation[],
): boolean => {
	if (filterWorkLocations.length === 0) return true;
	const isWorkLocationMatching = () =>
		filterWorkLocations
			.map((location) => location.label)
			.some((label) =>
				talentWorkLocations.map((location) => location.label).includes(label),
			);
	const isFilterCountryMatchingTalentLocation = () =>
		filterWorkLocations
			.filter((location) => !location.label.includes(','))
			.map((country) => countryToAlpha2Code(country.label)) // saving locations in URL only saves the label => country.label not country.country
			.some((code) =>
				talentWorkLocations
					.map((location) => location.country)
					.includes(code ?? 'undefined'),
			);
	const isTalentCountryMatchingFilterLocation = () =>
		talentWorkLocations
			.filter((location) => !location.label.includes(','))
			.map((country) => country.country)
			.some((code) =>
				filterWorkLocations
					.map((location) => location.label)
					.some((label) => label.endsWith(`, ${code}`)),
			);
	return (
		isWorkLocationMatching() ||
		isFilterCountryMatchingTalentLocation() ||
		isTalentCountryMatchingFilterLocation()
	);
};

export const extractSalaryExpectationsCurrency = (
	value: SalaryExpectationsCurrency | undefined,
	defaultValue: SalaryExpectationsCurrency,
) => {
	const valueString = value?.toUpperCase() as SalaryExpectationsCurrency;
	if (value && salaryExpectationsCurrencies.includes(valueString)) {
		return value;
	} else {
		return defaultValue;
	}
};
