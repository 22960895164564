import { TableCell, TableRow } from '@mui/material';
import {
	Fragment,
	ReactElement,
	StrictMode,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { Link } from 'react-router-dom';
import { TableVirtuoso } from 'react-virtuoso';
import {
	fetchMyRegions,
	fetchTalentsForReviewer,
	isCurrentUserReviewer,
} from '../../api/reviewers';
import { PathsEnum } from '../../core/enums';
import { TalentColumn } from '../../core/interfaces';
import { ScrumMaturityDescription } from '../../core/lookups';
import { TalentData } from '../../core/types';
import { germanDateTimeString } from '../../core/utils';
import { countryCodeToRegionMap } from '../../shared/assets/utils';
import { ProfileStatusesEnum } from '../../shared/classes/profile';
import { SmLocation } from '../../shared/classes/smLocation';
import { ScrumMaturity, TalentForReviewers } from '../../shared/classes/talent';
import { SmRegionsEnum } from '../../shared/enums';
import { toUrlId } from '../../shared/types/urlId';
import useReviewerTalentFilterZustand from '../../zustand/ReviewerTalentFilterZustand';
import useScrumMaturityZustand from '../../zustand/ScrumMaturityZustand';
import ReviewerFilters from './ReviewerFilters';

const columns: TalentColumn[] = [
	{
		width: `${80}px`,
		label: 'Status',
		dataKey: 'status',
	},
	{
		width: `${120}px`,
		label: 'Review date',
		dataKey: 'scheduledEvalDate',
	},
	{
		width: `${120}px`,
		label: 'Last edit',
		dataKey: 'lastSavedAt',
	},
	{
		width: `${80}px`,
		label: 'Region',
		dataKey: 'region',
	},
	{
		width: `${225}px`,
		label: 'Name',
		dataKey: 'fullName',
	},
	{
		width: `${257}px`,
		label: 'Email',
		dataKey: 'email',
	},
	{
		width: `${80}px`,
		label: 'Notes',
		dataKey: 'successManagerNotes',
	},
	{
		width: `${140}px`,
		label: 'Maturity',
		dataKey: 'scrumMaturity',
	},
	{
		width: `${250}px`,
		label: 'Work locations',
		dataKey: 'preferredWorkLocations',
	},
];

const fixedHeaderContent = () => {
	return (
		<TableRow>
			{columns.map((column, index) => (
				<TableCell
					key={`${column.dataKey.toString()}${index}`}
					variant="head"
					style={{
						backgroundColor: '#f0f0f0',
					}}
					sx={{
						fontWeight: 'bold',
						minWidth: column.width,
						maxWidth: column.width,
						width: column.width,
					}}>
					{column.label}
				</TableCell>
			))}
		</TableRow>
	);
};

const toTableContent = (
	scrumMaturityLookup: Record<ScrumMaturity, ScrumMaturityDescription>,
	talent: TalentData,
	dataKey: keyof TalentData,
): string | ReactElement => {
	let value = talent[dataKey];
	if ((dataKey === 'scheduledEvalDate' || dataKey === 'lastSavedAt') && value) {
		value = germanDateTimeString(value as Date);
	}
	if (dataKey === 'scrumMaturity') {
		value = scrumMaturityLookup[value as ScrumMaturity].shortLabel;
	}
	if (dataKey === 'preferredWorkLocations' && value) {
		value = (value as SmLocation[])
			.map((location) => location.label)
			.join('; ');
		value = value.length > 100 ? value.substring(0, 100) + '...' : value;
	} else if (!value) {
		value = '-';
	}
	return value as string;
};

const rowContent = (
	_index: number,
	talent: TalentData,
	scrumMaturityLookup: Record<ScrumMaturity, ScrumMaturityDescription>,
) => {
	const talentUrlId = toUrlId(talent.userId);
	return (
		<StrictMode>
			<Fragment>
				{columns.map((column, index) => (
					<TableCell
						key={`${column.dataKey.toString()}${index}`}
						style={{
							width: column.width,
							minWidth: column.width,
							maxWidth: column.width,
							textOverflow: 'ellipsis',
							overflow: 'hidden',
						}}>
						<Link
							to={`${PathsEnum.REVIEWER_TALENTS}/${talentUrlId}`}
							data-testid={column.dataKey === 'fullName' ? talentUrlId : ''}>
							{toTableContent(scrumMaturityLookup, talent, column.dataKey)}
						</Link>
					</TableCell>
				))}
			</Fragment>
		</StrictMode>
	);
};

export default function TalentsTable() {
	const { scrumMaturityLookup } = useScrumMaturityZustand();
	const [talentsState, setTalentsState] = useState<TalentData[] | undefined>(
		[],
	);
	const [isUserReviewer, setIsUserReviewer] = useState(false);
	const [permittedRegions, setPermittedRegions] = useState<SmRegionsEnum[]>([]);
	const {
		filters,
		matchesFilters,
		setFilters,
		isEmpty: isFiltersEmpty,
	} = useReviewerTalentFilterZustand();

	useEffect(() => {
		isCurrentUserReviewer()
			.then(setIsUserReviewer)
			.catch((error) => console.log('is current user reviewer error: ', error));
	}, []);

	useEffect(() => {
		const sortTalents = (talents: TalentForReviewers[]) => {
			const compare = (a: Date | null, b: Date | null) =>
				new Date(a ?? 0) > new Date(b ?? 0) ? -1 : 1;
			return talents // sort first by createdAt, then by submittedAt
				?.sort((a, b) =>
					compare(a.activityLog.createdAt, b.activityLog.createdAt),
				)
				.sort((a, b) => {
					return compare(a.activityLog.lastSavedAt, b.activityLog.lastSavedAt);
				});
		};
		const flattenTalents = (talents: TalentForReviewers[]): TalentData[] => {
			return talents.map((talent: TalentForReviewers) => {
				const submittedAt: Date | null = talent.activityLog.submittedAt
					? new Date(talent.activityLog.submittedAt)
					: null;
				const lastSavedAt: Date | null = talent.activityLog.lastSavedAt
					? new Date(talent.activityLog.lastSavedAt)
					: null;
				return {
					userId: talent.userId,
					email: talent.email,
					fullName: `${talent.profile?.firstName ?? ''} ${
						talent.profile?.middleName ? talent.profile?.middleName + ' ' : ''
					}${talent.profile?.surnames ?? ''}`,
					...talent.profile,
					...talent.evalData,
					scrumMaturity: talent.evalData?.scrumMaturity ?? '-',
					evalNotes: talent.evalData?.evalNotes ? '✔︎' : '',
					successManagerNotes: talent.evalData?.successManagerNotes
						? '✔︎'
						: '-',
					region:
						countryCodeToRegionMap[talent.profile?.currentLocation.country] ??
						SmRegionsEnum['N/A'],
					submittedAt: submittedAt ? submittedAt.toISOString() : '',
					lastSavedAt: lastSavedAt ? lastSavedAt.toISOString() : '',
				};
			});
		};
		fetchTalentsForReviewer()
			.then((talents) => {
				if (talents) {
					const sortedTalents = sortTalents(talents);
					const flatTalents = flattenTalents(sortedTalents);
					setTalentsState(flatTalents);
				}
			})
			.catch((error) => console.log('get talents error 2: ', error));

		fetchMyRegions().then((myRegions) => {
			const regions = myRegions ?? [];
			setPermittedRegions(regions);
			if (isFiltersEmpty()) {
				setFilters({
					// default status: SUBMITTED, only my regions
					...filters,
					regions,
					statuses: [ProfileStatusesEnum.SUBMITTED],
				});
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const talentsToShow = useMemo(
		() => talentsState?.filter((talent) => matchesFilters(talent)),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[filters, talentsState, matchesFilters],
	);

	return (
		<div className="sm-talents-table-container">
			{isUserReviewer ? (
				<>
					<ReviewerFilters
						totalResults={talentsToShow ? talentsToShow.length : 0}
						permittedRegions={permittedRegions}
					/>
					<TableVirtuoso
						style={{ height: '70vh' }}
						data={talentsToShow ?? []}
						fixedHeaderContent={fixedHeaderContent}
						itemContent={(_index, talent) =>
							rowContent(_index, talent, scrumMaturityLookup)
						}
						className="sm-talents-table"
					/>
				</>
			) : (
				<p style={{ height: '50vh', padding: '50px' }}>Access Denied.</p>
			)}
		</div>
	);
}
