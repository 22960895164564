import { AxiosResponse } from 'axios';
import { Employer } from '../shared/classes/employer';
import { Recruiter } from '../shared/classes/recruiter';
import { EmailTemplate, Talent } from '../shared/classes/talent';
import {
	baseUrl,
	buildHeaders,
	jsonHeaders,
	safeDelete,
	safeGet,
	safePost,
} from './utils';

export const adminUrl = `${baseUrl}/admins`;

export const connectTalentToEmployer = async (
	talentUrlId: string,
	employerId: string,
): Promise<AxiosResponse | undefined> => {
	const result = await safePost(
		`${adminUrl}/talents/${talentUrlId}/connect-to-employer/${employerId}`,
		{},
		buildHeaders(jsonHeaders),
	);

	return result;
};

export const isAdmin = async (): Promise<boolean> => {
	const result = await safeGet(`${adminUrl}/me`, buildHeaders(jsonHeaders));

	return result ? result.data : false;
};

export const fetchEmployers = async (): Promise<Employer[] | undefined> => {
	const result = await safeGet(
		`${adminUrl}/employers`,
		buildHeaders(jsonHeaders),
	);

	return result ? result.data : undefined;
};

export const fetchRecruiters = async (): Promise<Recruiter[] | undefined> => {
	const result = await safeGet(
		`${adminUrl}/recruiters`,
		buildHeaders(jsonHeaders),
	);

	return result ? result.data : undefined;
};

export const fetchTalentByUrlIdForAdmin = async (
	urlId: string,
): Promise<Talent | undefined> => {
	const result = await safeGet(
		`${adminUrl}/talents/${urlId}`,
		buildHeaders(jsonHeaders),
	);

	return result ? result.data : undefined;
};

export const activateRecruiter = async (
	userId: string,
	employerId: string,
	headers?: { headers: any },
) => {
	const result = await safePost(
		`${adminUrl}/recruiters/${userId}/employers/${employerId}`,
		{},
		buildHeaders(headers ?? jsonHeaders),
	);

	return result;
};
export const createEmployer = async (
	employerId: string,
	name: string,
	isTestUser?: boolean,
	headers?: { headers: any },
): Promise<Employer | undefined> => {
	const result = await safePost(
		`${adminUrl}/newemployer`,
		{ employerId, name, isTestUser },
		buildHeaders(headers ?? jsonHeaders),
	);

	return result ? new Employer(result.data) : undefined;
};

export const deleteEmployer = async (
	employerId: string,
	headers?: { headers: any },
) => {
	const result = await safeDelete(
		`${adminUrl}/employers/${employerId}/delete`,
		buildHeaders(headers ?? jsonHeaders),
	);

	return result;
};

export const fetchConnectedTalents = async (
	employerId: string,
): Promise<Talent[] | undefined> => {
	const result = await safeGet(
		`${adminUrl}/employers/${employerId}/connected-talents`,
		buildHeaders(jsonHeaders),
	);

	if (result?.status === 200) {
		return result.data;
	} else {
		return undefined;
	}
};

export const fetchAdminEmailTemplatesForRecruiterAndTalent = async (
	recruiterUserId: string,
	talentUrlId: string,
): Promise<EmailTemplate[]> => {
	const result = await safeGet(
		`${adminUrl}/recruiters/${recruiterUserId}/talents/${talentUrlId}/email-templates`,
		buildHeaders(jsonHeaders),
	);

	return result?.data ?? [];
};

export const sendEmailAsAdmin = async (
	to: string[],
	filledTemplate: EmailTemplate,
	templateName = 'custom admin email',
): Promise<AxiosResponse | undefined> => {
	const result = await safePost(
		`${adminUrl}/send-email`,
		{
			to,
			filledTemplate,
			templateName,
		},
		buildHeaders(jsonHeaders),
	);

	return result;
};

export const debugSendRecruiterDailyTalentUpdateEmail = async () => {
	const result = await safeGet(
		`${adminUrl}/schedulers/recruiter-daily-talent-update/debug-send`,
		buildHeaders(jsonHeaders),
	);
	return result;
};
