export const smLanguages = [
	'Afar',
	'Abkhazian',
	'Avestan',
	'Afrikaans',
	'Akan',
	'Amharic',
	'Aragonese',
	'Arabic',
	'Assamese',
	'Avaric',
	'Aymara',
	'Azerbaijani',
	'Bashkir',
	'Belarusian',
	'Bulgarian',
	'Bihari languages',
	'Bislama',
	'Bambara',
	'Bengali',
	'Tibetan',
	'Breton',
	'Bosnian',
	'Catalan',
	'Chechen',
	'Chamorro',
	'Corsican',
	'Cree',
	'Czech',
	'Church Slavic',
	'Chuvash',
	'Welsh',
	'Danish',
	'German',
	'Divehi',
	'Dzongkha',
	'Ewe',
	'Greek',
	'English',
	'Esperanto',
	'Spanish',
	'Estonian',
	'Basque',
	'Persian',
	'Fulah',
	'Finnish',
	'Fijian',
	'Faroese',
	'French',
	'Western Frisian',
	'Irish',
	'Gaelic',
	'Galician',
	'Guarani',
	'Gujarati',
	'Manx',
	'Hausa',
	'Hebrew',
	'Hindi',
	'Hiri Motu',
	'Croatian',
	'Haitian',
	'Hungarian',
	'Armenian',
	'Herero',
	'Indonesian',
	'Interlingue',
	'Igbo',
	'Sichuan Yi',
	'Inupiaq',
	'Ido',
	'Icelandic',
	'Italian',
	'Inuktitut',
	'Japanese',
	'Javanese',
	'Georgian',
	'Kongo',
	'Kikuyu',
	'Kuanyama',
	'Kazakh',
	'Kalaallisut',
	'Central Khmer',
	'Kannada',
	'Korean',
	'Kanuri',
	'Kashmiri',
	'Kurdish',
	'Komi',
	'Cornish',
	'Kirghiz',
	'Latin',
	'Luxembourgish',
	'Ganda',
	'Limburgan',
	'Lingala',
	'Lao',
	'Lithuanian',
	'Luba-Katanga',
	'Latvian',
	'Malagasy',
	'Marshallese',
	'Maori',
	'Macedonian',
	'Malayalam',
	'Mongolian',
	'Marathi',
	'Malay',
	'Maltese',
	'Burmese',
	'Nauru',
	'Ndebele, North',
	'Nepali',
	'Ndonga',
	'Dutch',
	'Norwegian',
	'Ndebele, South',
	'Navajo',
	'Chichewa',
	'Occitan',
	'Ojibwa',
	'Oromo',
	'Oriya',
	'Ossetian',
	'Panjabi',
	'Pali',
	'Polish',
	'Pushto',
	'Portuguese',
	'Quechua',
	'Romansh',
	'Rundi',
	'Romanian',
	'Russian',
	'Kinyarwanda',
	'Sanskrit',
	'Sardinian',
	'Sindhi',
	'Northern Sami',
	'Sango',
	'Sinhala',
	'Slovak',
	'Slovenian',
	'Samoan',
	'Shona',
	'Somali',
	'Albanian',
	'Serbian',
	'Swati',
	'Sotho, Southern',
	'Sundanese',
	'Swedish',
	'Swahili',
	'Tamil',
	'Telugu',
	'Tajik',
	'Thai',
	'Tigrinya',
	'Turkmen',
	'Tagalog',
	'Tswana',
	'Tonga (Tonga Islands)',
	'Turkish',
	'Tsonga',
	'Tatar',
	'Twi',
	'Tahitian',
	'Uighur',
	'Ukrainian',
	'Urdu',
	'Uzbek',
	'Venda',
	'Vietnamese',
	'Volapük',
	'Walloon',
	'Wolof',
	'Xhosa',
	'Yiddish',
	'Yoruba',
	'Zhuang',
	'Chinese',
	'Zulu',
] as const;

export type SmLanguage = (typeof smLanguages)[number];

export const extractSmLanguages = (values?: any): SmLanguage[] => {
	if (!values || !Array.isArray(values)) {
		return [];
	}
	return Array.from(
		new Set(
			values.filter(
				(lang) =>
					lang &&
					typeof lang === 'string' &&
					smLanguages.includes(lang as SmLanguage),
			),
		),
	);
};
