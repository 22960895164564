import axios from 'axios';
import { toSha256 } from '../core/cryptoUtils';
import { baseUrl, buildHeaders, jsonHeaders, safePost } from './utils';

const authUrl = `${baseUrl}/auth`;

export const authenticateWithGoogle = async (code: string) => {
	return await axios.post(`${authUrl}/google`, { code });
};

export const authenticateWithLinkedIn = async (code: string) => {
	return await axios.post(`${authUrl}/linkedin`, { code });
};

export const refreshGoogleToken = async () => {
	return await safePost(
		`${authUrl}/google/refresh-token`,
		{},
		buildHeaders(jsonHeaders),
	);
};

export const authenticateWithScrumMatchForRecruiter = async (
	email: string,
	password: string,
	oneTimePassword: string,
	isTestUser?: boolean,
) => {
	return await safePost(
		`${authUrl}/scrummatch/recruiters`,
		{
			email,
			password: await toSha256(password),
			oneTimePassword,
			isTestUser,
		},
		buildHeaders(jsonHeaders),
	);
};
