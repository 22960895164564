import {
	InputLabel,
	TextareaAutosize,
	TextareaAutosizeProps,
} from '@mui/material';
import { ChangeEvent } from 'react';

type SmTextareaAutosizeProps = {
	placeholder: string;
	id: string;
	value?: string;
	containerClass?: string;
	handleChange?: (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => void;
	disabled?: boolean;
} & { textareaAutosizeProps?: TextareaAutosizeProps };

export default function SmTextareaAutosize({
	placeholder,
	id,
	value = '',
	containerClass = 'sm-full-screen',
	handleChange = undefined,
	textareaAutosizeProps,
	disabled = false,
}: SmTextareaAutosizeProps) {
	return (
		<div className={containerClass}>
			<InputLabel htmlFor={id} disabled={disabled}>{placeholder}</InputLabel>
			<TextareaAutosize
				id={id}
				onChange={handleChange}
				value={value}
				area-label={placeholder}
				readOnly={!handleChange}
				className="sm-textarea"
				style={{
					backgroundColor: handleChange ? '#fff' : '#f0f0f0',
				}}
				{...textareaAutosizeProps}
				disabled={disabled}
			/>
		</div>
	);
}
