import './ProfilePreview.css';

import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { hasAcceptedRecruiterTerms } from '../../api/recruiters';
import {
	getMyUrlId,
	getProfileResponseByUrlId,
	getReviewResultsByUrlId,
} from '../../api/talents';
import { PathsEnum } from '../../core/enums';
import { Profile } from '../../shared/classes/profile';
import {
	AnonymousReviewResults,
	ConnectedTalentReviewResults,
	ReviewResults,
} from '../../shared/classes/talent';
import { ViewPermissionsEnum } from '../../shared/classes/utils';
import useScrumMaturityZustand from '../../zustand/ScrumMaturityZustand';
import GetInTouchButton from '../SmButtons/GetInTouchButton';
import SmSpinner from '../SmSpinner';
import InsightsBox from './InsightsBox';
import ProfileHeader from './ProfileHeader';
import SmSwitch from './SmSwitch';
import UserInfoBox from './UserInfoBox';
import WorkWithMeBox from './WorkWithMeBox';

export interface ProfilePreviewProps {
	userId?: string;
	isEvalPage?: boolean;
}

export default function ProfilePreview({
	userId,
	isEvalPage = false,
}: ProfilePreviewProps) {
	const urlId = useParams<{
		urlId: string;
	}>().urlId;
	const navigate = useNavigate();
	const { scrumMaturityLookup } = useScrumMaturityZustand();
	const [profile, setProfile] = useState<Profile | undefined>();
	const [talentEmail, setTalentEmail] = useState<string | undefined>();
	const [reviewResults, setReviewResults] = useState<
		| ReviewResults
		| ConnectedTalentReviewResults
		| AnonymousReviewResults
		| undefined
	>();
	const [viewPermissions, setViewPermissions] = useState<
		ViewPermissionsEnum | undefined
	>();
	const [isAnonymousView, setIsAnonymousView] = useState(false);
	const [profileNotFound, setProfileNotFound] = useState(false);
	const [isRecruiter, setIsRecruiter] = useState(false);

	useEffect(() => {
		if (urlId) {
			getProfileResponseByUrlId(urlId)
				.then((profileResponse) => {
					setViewPermissions(profileResponse?.permissions);
					if (profileResponse?.profile) {
						setProfile(new Profile(profileResponse.profile));
					} else {
						setProfileNotFound(true);
					}
					// @ts-ignore
					setTalentEmail(profileResponse?.email);
				})
				.catch((error) => console.log('get profile by url error: ', error));
			getReviewResultsByUrlId(urlId)
				.then((reviewResultsResponse) => {
					if (reviewResultsResponse?.reviewResults) {
						setReviewResults(reviewResultsResponse.reviewResults);
					}
				})
				.catch((error) => console.log('review results error 2: ', error));
		} else if (!urlId) {
			getMyUrlId()
				.then((myUrlId) => {
					if (myUrlId) {
						navigate(`${PathsEnum.PROFILE}/${myUrlId}`, { replace: true });
					} else {
						navigate(PathsEnum.LOGIN);
					}
				})
				.catch(() => {
					navigate(PathsEnum.LOGIN);
				});
		}
	}, [userId, urlId, navigate]);

	useEffect(() => {
		setIsAnonymousView(viewPermissions === ViewPermissionsEnum.ANONYMOUS);
	}, [viewPermissions]);

	useEffect(() => {
		hasAcceptedRecruiterTerms().then((accepted) => {
			setIsRecruiter(accepted);
		});
	}, []);

	const employerSuccessEmailAddress = 'employer-success@scrummatch.com';
	const metaPrefix = navigator.language.startsWith('de')
		? 'Warum Sie diesen Scrum Master einstellen sollten: '
		: 'Why you should hire this Scrum Master: ';

	return (
		<div className="sm-profile-preview">
			<Helmet>
				<title>
					{profile && profile.preferredWorkLocations
						? `Scrum Master for ${profile.preferredWorkLocations
								.map((loc) => loc.label)
								.join()} | ScrumMatch`
						: 'ScrumMatch'}
				</title>
				<meta
					name="description"
					content={
						metaPrefix +
						scrumMaturityLookup[reviewResults?.scrumMaturity ?? ''].metaText
					}
				/>
			</Helmet>
			{!profile && !profileNotFound ? (
				<div style={{ margin: '50px' }}>
					<SmSpinner />
				</div>
			) : (
				<>
					<ProfileHeader
						isEvalPage={isEvalPage}
						showBackButton={
							isEvalPage || viewPermissions === ViewPermissionsEnum.MYSELF
						}
					/>

					{profileNotFound ? (
						<div style={{ padding: '20px' }}>
							<p className="sm-profile-preview-padding-x">Profile not found!</p>
							<p className="sm-profile-preview-padding-x">
								It seems this talent is no longer available. Don't worry, there
								are many more talents to be found on ScrumMatch. Login with your
								employer account or contact us at{' '}
								<a
									href={`mailto:${employerSuccessEmailAddress}`}
									target="_blank"
									rel="noreferrer">
									{employerSuccessEmailAddress}
								</a>{' '}
								to get access to more talents.
							</p>
						</div>
					) : (
						<div className="sm-profile-preview-grid-container margin-50">
							<div className="sm-profile-preview-talent-head">
								<div className="sm-profile-preview-padding-x">
									{viewPermissions === ViewPermissionsEnum.MYSELF ? (
										<SmSwitch
											value={isAnonymousView}
											handleChange={(_event, checked) =>
												setIsAnonymousView(checked)
											}
											offLabel="Full view"
											onLabel="Anonymous view"
										/>
									) : null}
									<div className="sm-profile-user-info-scrum-maturity">
										<UserInfoBox
											profile={profile!}
											isRecruiter={isRecruiter}
											isAnonymousView={isAnonymousView}
										/>
									</div>
								</div>
							</div>
							{!!reviewResults?.scrumMaturity ? (
								<div className="sm-profile-preview-employer">
									<InsightsBox
										isRecruiter={isRecruiter}
										scrumMaturity={reviewResults?.scrumMaturity ?? ''}
										successManagerNotes={
											(reviewResults as ConnectedTalentReviewResults)
												?.successManagerNotes
										}
									/>
								</div>
							) : null}

							<div className="sm-profile-preview-talent">
								<WorkWithMeBox
									profile={profile!}
									email={talentEmail}
									isAnonymousView={isAnonymousView}
								/>

								<div className="sm-button-container sm-profile-preview-padding-x flex-space-between max-width-1600 mobile-flex-column mobile-gap-50">
									{!isEvalPage &&
										viewPermissions === ViewPermissionsEnum.MYSELF && (
											<Button
												variant="gradient"
												color="primary"
												onClick={() => navigate(PathsEnum.PROFILE_FORM)}>
												Back
											</Button>
										)}
									{viewPermissions === ViewPermissionsEnum.ANONYMOUS ? (
										<GetInTouchButton isRecruiter={isRecruiter} />
									) : null}
									{viewPermissions !== ViewPermissionsEnum.MYSELF ? (
										<a
											href={`${PathsEnum.REPORT_CONTENT}${window.location.href}`}
											target="_blank"
											className="color-grey"
											style={{ justifySelf: 'end' }}
											rel="noreferrer">
											Report Content
										</a>
									) : null}
								</div>
							</div>
						</div>
					)}
				</>
			)}
		</div>
	);
}
