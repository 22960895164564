import { useEffect } from 'react';
import { create } from 'zustand';
import { getScrumMaturity } from '../api/config';
import {
	ScrumMaturityDescription,
	generateScrumMaturityLookup,
} from '../core/lookups';
import { ScrumMaturity } from '../shared/classes/talent';
import { ScrumMaturityConfig } from '../shared/types/config';

interface ScrumMaturityZustandType {
	scrumMaturity: ScrumMaturityConfig | undefined;
	loadScrumMaturity: () => Promise<void>;
	scrumMaturityLookup: Record<ScrumMaturity, ScrumMaturityDescription>;
	setScrumMaturityLookup: (scrumMaturity: ScrumMaturityConfig) => void;
}

const blankScrumMaturity = {
	metaText: '',
	shouldHireNode: <></>,
	dropdownLabel: 'n/a',
	graphText: '',
	shortLabel: 'n/a',
	percentageNumeric: 0,
	percentageExplanation: <></>,
};

const useScrumMaturityZustand = create<ScrumMaturityZustandType>(
	(set, get) => ({
		scrumMaturity: undefined,
		loadScrumMaturity: async () => {
			const scrumMaturityConfig = await getScrumMaturity();
			set({ scrumMaturity: scrumMaturityConfig });
		},
		scrumMaturityLookup: {
			'': blankScrumMaturity,
			'-1': blankScrumMaturity,
			0: blankScrumMaturity,
			1: blankScrumMaturity,
			2: blankScrumMaturity,
			3: blankScrumMaturity,
			4: blankScrumMaturity,
			5: blankScrumMaturity,
		},
		setScrumMaturityLookup: () => {
			const smConfig = get().scrumMaturity;
			if (smConfig) {
				const smLookup = generateScrumMaturityLookup(smConfig);
				set({ scrumMaturityLookup: smLookup });
			}
		},
	}),
);

export default useScrumMaturityZustand;

export function ScrumMaturityProvider() {
	const {
		scrumMaturity,
		loadScrumMaturity,
		scrumMaturityLookup,
		setScrumMaturityLookup,
	} = useScrumMaturityZustand();

	useEffect(() => {
		if (!scrumMaturity) loadScrumMaturity();
	}, [scrumMaturity, loadScrumMaturity]);

	useEffect(() => {
		if (!!scrumMaturity && scrumMaturityLookup[5].metaText === '')
			setScrumMaturityLookup(scrumMaturity);
	}, [scrumMaturity, scrumMaturityLookup, setScrumMaturityLookup]);

	return <></>;
}
