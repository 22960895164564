import CloseIcon from '@mui/icons-material/Close';
import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
} from '@mui/material';
import { ReactNode, useState } from 'react';
import { SmBootstrapDialog } from '../../../core/consts';
import SmTextField from './SmTextField';

interface SmInputDialogProps {
	open: boolean;
	onClose: () => void;
	title: ReactNode;
	inputLabel: string;
	inputPlaceholder: string;
	submitButtonText: string;
	onSubmit: (inputValue: string) => Promise<void>;
	cancelButtonText?: string;
	isErrorWhenEmpty?: boolean;
	errorHelperText?: string;
}

export default function SmInputDialog({
	open,
	onClose,
	title,
	inputLabel,
	inputPlaceholder,
	submitButtonText,
	onSubmit,
	cancelButtonText,
	isErrorWhenEmpty,
	errorHelperText,
}: SmInputDialogProps) {
	const [inputValue, setInputValue] = useState('');
	const [attemptedToSubmitWhenEmpty, setAttemptedToSubmitWhenEmpty] =
		useState(false);
	const isError =
		isErrorWhenEmpty === true && attemptedToSubmitWhenEmpty && !inputValue;

	const resetStates = () => {
		setInputValue('');
		setAttemptedToSubmitWhenEmpty(false);
	};

	const handleSubmit = async (value: string) => {
		if (!value) {
			setAttemptedToSubmitWhenEmpty(true);
		} else {
			await onSubmit(value);
			resetStates();
		}
	};

	const handleClose = () => {
		resetStates();
		onClose();
	};

	return (
		<SmBootstrapDialog
			disableRestoreFocus
			open={open}
			onClose={handleClose}
			maxWidth="xs"
			fullWidth
			onKeyDown={async (event) => {
				if (event.key === 'Escape') {
					event.preventDefault();
					handleClose();
				}
				if (event.key === 'Enter') {
					event.preventDefault();
					handleSubmit(inputValue);
				}
			}}>
			<DialogTitle className="letter-spacing-wide uppercase">
				{title}{' '}
				<IconButton
					aria-label="close"
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<SmTextField
					error={isError}
					helperText={isError ? errorHelperText : ''}
					autoFocus
					label={inputLabel}
					variant="outlined"
					fullWidth
					placeholder={inputPlaceholder}
					value={inputValue}
					onChange={(event) => setInputValue(event.target.value)}
				/>
			</DialogContent>
			<DialogActions sx={{ margin: '0 auto 10px' }}>
				<Button
					onClick={() => handleSubmit(inputValue)}
					variant="dialog1"
					disableElevation>
					{submitButtonText}
				</Button>
				<Button onClick={handleClose} variant="dialog2" disableElevation>
					{cancelButtonText}
				</Button>
			</DialogActions>
		</SmBootstrapDialog>
	);
}
