import { StrictMode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchTalentsForRecruiters } from '../../api/recruiters';
import RecruiterMenu from '../../components/LayoutComponents/SidebarComponents/RecruiterSidebarMenu';
import SmInterceptor from '../../components/SmInterceptor';
import { PathsEnum } from '../../core/enums';
import SidebarLayout from '../../layouts/SidebarLayout';

export default function RecruiterWaitingPage() {
	const navigate = useNavigate();

	useEffect(() => {
		if (navigate) {
			const interval = setInterval(() => {
				console.log('retrying...');
				fetchTalentsForRecruiters()
					.then((res) => {
						if (res && res.length) {
							navigate(PathsEnum.RECRUITER_HOME);
						}
					})
					.catch((_err) => {
						console.log('still not active.');
					});
			}, 1000 * 10);
			return () => {
				clearInterval(interval);
			};
		}
	}, [navigate]);

	return (
		<StrictMode>
			<SmInterceptor
				redirectTo={PathsEnum.RECRUITER_LOGIN}
				redirectToIfUserNotActive={PathsEnum.RECRUITER_WAITING_PAGE}
			/>
			<SidebarLayout
				menu={<RecruiterMenu />}
				content={
					<>
						<h2>Thank you for signing up as a recruiter!</h2>
						<p>
							Your customer success manager will get in touch with you shortly
							to activate your account.
						</p>
					</>
				}
			/>
		</StrictMode>
	);
}
