import { useEffect, useState } from 'react';
import { PathsEnum } from '../../core/enums';
// import { scrumMaturityLookup } from '../../core/lookups';
import { ScrumMaturity } from '../../shared/classes/talent';
import useScrumMaturityZustand from '../../zustand/ScrumMaturityZustand';
import './ScrumMaturityGraph.css';

interface MaturityBox {
	maturity: ScrumMaturity;
	percentage: number;
}

interface ScrumMaturityGraphProps {
	maturity: ScrumMaturity;
	isInteractive?: boolean;
}

export default function ScrumMaturityGraph({
	maturity,
	isInteractive = false,
}: ScrumMaturityGraphProps) {
	const { scrumMaturityLookup } = useScrumMaturityZustand();
	const [focusedMaturityBox, setFocusedMaturityBox] = useState<
		MaturityBox | undefined
	>();
	const [isHovered, setIsHovered] = useState(false);
	const maturityBoxesContent: MaturityBox[] = [
		{
			maturity: '-1',
			percentage: scrumMaturityLookup['-1'].percentageNumeric,
		},
		{
			maturity: '0',
			percentage: scrumMaturityLookup['0'].percentageNumeric,
		},
		{
			maturity: '1',
			percentage: scrumMaturityLookup['1'].percentageNumeric,
		},
		{
			maturity: '2',
			percentage: scrumMaturityLookup['2'].percentageNumeric,
		},
		{
			maturity: '3',
			percentage: scrumMaturityLookup['3'].percentageNumeric,
		},
		{
			maturity: '4',
			percentage: scrumMaturityLookup['4'].percentageNumeric,
		},
		{
			maturity: '5',
			percentage: scrumMaturityLookup['5'].percentageNumeric,
		},
	];

	const resetClickedMaturityBox = () => {
		setFocusedMaturityBox(
			maturityBoxesContent.find(
				(box: MaturityBox) => box.maturity === maturity,
			),
		);
	};

	useEffect(() => {
		resetClickedMaturityBox();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [maturity]);

	const handleHoverStart = (box: MaturityBox) => {
		setIsHovered(true);
		setFocusedMaturityBox(box);
	};

	const handlePointerUp = () => {
		if (!isHovered) {
			resetClickedMaturityBox();
		}
	};

	const generateMaturityBox = (box: MaturityBox) => {
		let selectedMaturity =
			maturity === box.maturity ? 'sm-scrum-maturity-graph-box-selected' : '';
		return (
			<div
				key={`sm-scrum-maturity-graph-box${box.maturity}`}
				onPointerEnter={() => handleHoverStart(box)}
				onPointerLeave={resetClickedMaturityBox}
				onPointerDown={() => setFocusedMaturityBox(box)}
				onPointerUp={handlePointerUp}
				className={`sm-scrum-maturity-graph-box ${selectedMaturity}`}
				style={{
					flexGrow: box.percentage,
					cursor: isInteractive ? 'pointer' : 'unset',
				}}>
				<div
					className={`sm-scrum-maturity-graph-box-percentage${
						focusedMaturityBox?.maturity === box.maturity && isInteractive
							? ' text-decoration-underline'
							: ''
					}`}>
					{box.percentage}
					<span>%</span>
				</div>
				<div className="sm-scrum-maturity-graph-box-marker">▲</div>
			</div>
		);
	};

	const percentageExplanation = isInteractive
		? scrumMaturityLookup[focusedMaturityBox?.maturity ?? '']
				.percentageExplanation
		: scrumMaturityLookup[maturity].percentageExplanation;

	return (
		<>
			<p style={{ marginBottom: 15 }}>{percentageExplanation}</p>
			<div className="flex-space-between">
				<p className="font-size-12 letter-spacing-1 lowercase no-margin">Low</p>
				<p className="font-size-12 letter-spacing-1 lowercase no-margin">
					High
				</p>
			</div>
			<div className="sm-scrum-maturity-graph">
				{maturityBoxesContent.map((box: MaturityBox) =>
					generateMaturityBox(box),
				)}
			</div>
			<div style={{ height: 15 }} />
			<a
				target="_blank"
				href={PathsEnum.LANDING_PAGE_FAQ_SCRUM_MATURITY}
				rel="noreferrer">
				What is Scrum maturity?
			</a>
		</>
	);
}
