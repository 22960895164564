import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

import { createTheme, ThemeProvider } from '@mui/material';
import { CookiesProvider } from 'react-cookie';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import {
	createBrowserRouter,
	Navigate,
	RouterProvider,
} from 'react-router-dom';
import { PathsEnum } from './core/enums';
import { smTheme } from './core/theme';
import './styles.css';
import { UserInfoProvider } from './zustand/UserInfoZustand';

import HomePage from './pages/talent/HomePage';
import ManageAccountPage from './pages/talent/ManageAccountPage';
import ProfileFormPage from './pages/talent/ProfileFormPage';
import ProfilePage from './pages/talent/ProfilePage';
import ReviewPage from './pages/talent/ReviewPage';
import TalentLoginPage from './pages/talent/TalentLoginPage';
import TalentProfileActivatePage from './pages/talent/TalentProfileActivatePage';
import TalentSignupPage from './pages/talent/TalentSignupPage';

import ReviewerLoginPage from './pages/reviewer/ReviewerLoginPage';
import ReviewerTalentsPage from './pages/reviewer/ReviewerTalentsPage';
import ReviewerTalentsTablePage from './pages/reviewer/ReviewerTalentsTablePage';

import RecruiterHomePage from './pages/recruiter/RecruiterHomePage';
import RecruiterLoginPage from './pages/recruiter/RecruiterLoginPage';
import RecruiterWaitingPage from './pages/recruiter/RecruiterWaitingPage';

import DashboardPage from './pages/admin/DashboardPage';
import { ScrumMaturityProvider } from './zustand/ScrumMaturityZustand';

const router = createBrowserRouter([
	{
		path: '/',
		element: <Navigate to={PathsEnum.LOGIN} replace />,
		errorElement: <Navigate to={PathsEnum.LOGIN} replace />,
	},
	{
		path: PathsEnum.LOGIN,
		element: <TalentLoginPage />,
	},
	{
		path: PathsEnum.SIGN_UP,
		element: <TalentSignupPage />,
	},
	{
		path: PathsEnum.ACTIVATE,
		element: <TalentProfileActivatePage />,
	},
	{
		path: PathsEnum.LINKEDIN,
		element: <LinkedInCallback />,
	},
	{
		path: PathsEnum.PROFILE_FORM,
		element: <ProfileFormPage />,
	},
	{
		path: PathsEnum.PROFILE,
		element: <ProfilePage />,
	},
	{
		path: `${PathsEnum.PROFILE}/:urlId`,
		element: <ProfilePage />,
	},
	{
		path: PathsEnum.REVIEW,
		element: <ReviewPage />,
	},
	{
		path: PathsEnum.ACCOUNT,
		element: <ManageAccountPage />,
	},
	{
		path: PathsEnum.HOME,
		element: <HomePage />,
	},
	{
		path: PathsEnum.ADMIN,
		element: <Navigate to={PathsEnum.ADMIN_DASHBOARD} replace />,
	},
	{
		path: PathsEnum.ADMIN_NEW_EMPLOYER,
		element: <DashboardPage />,
	},
	{
		path: PathsEnum.ADMIN_DASHBOARD,
		element: <DashboardPage />,
	},
	{
		path: PathsEnum.ADMIN_ACTIVATE_RECRUITER,
		element: <DashboardPage />,
	},
	{
		path: PathsEnum.ADMIN_CONNECT_TALENT,
		element: <DashboardPage />,
	},
	{
		path: PathsEnum.ADMIN_DEBUG,
		element: <DashboardPage />,
	},
	{
		path: PathsEnum.RECRUITER,
		element: <Navigate to={PathsEnum.RECRUITER_HOME} replace />,
	},
	{
		path: PathsEnum.RECRUITER_SIGN_UP,
		element: <RecruiterLoginPage isSignup={true} />,
	},
	{
		path: PathsEnum.RECRUITER_LOGIN,
		element: <RecruiterLoginPage isSignup={false} />,
	},
	{
		path: PathsEnum.RECRUITER_HOME,
		element: <RecruiterHomePage />,
	},
	{
		path: PathsEnum.RECRUITER_WAITING_PAGE,
		element: <RecruiterWaitingPage />,
	},
	{
		path: PathsEnum.REVIEWER,
		element: <Navigate to={PathsEnum.REVIEWER_TALENTS} replace />,
	},
	{
		path: PathsEnum.REVIEWER_TALENTS,
		element: <Navigate to={PathsEnum.REVIEWER_HOME} replace />,
	},
	{
		path: PathsEnum.REVIEWER_SIGN_UP,
		element: <ReviewerLoginPage isSignup={true} />,
	},
	{
		path: PathsEnum.REVIEWER_LOGIN,
		element: <ReviewerLoginPage isSignup={false} />,
	},
	{
		path: PathsEnum.REVIEWER_HOME,
		element: <ReviewerTalentsTablePage />,
	},
	{
		path: `${PathsEnum.REVIEWER_TALENTS}/:urlId`,
		element: <ReviewerTalentsPage />,
	},
]);

const theme = createTheme(smTheme);

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement,
);

const isDev = process.env.NODE_ENV === 'development';
if (isDev) {
	console.log('⭐️⭐️⭐️Development mode⭐️⭐️⭐️');
}

root.render(
	<CookiesProvider>
		<ThemeProvider theme={theme}>
			<ScrumMaturityProvider />
			<UserInfoProvider />
			<RouterProvider router={router} />
		</ThemeProvider>
	</CookiesProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
