import InfoIcon from '@mui/icons-material/Info';
import {
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	FormLabel,
	Radio,
	Tooltip,
} from '@mui/material';
import { WorkPermit } from '../../../shared/classes/profile';
import { ProfileFieldNamesEnum } from '../../../core/enums';

interface WorkPermitInputProps {
	handleChange: (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		checked: boolean,
	) => void;
	workPermit?: WorkPermit;
}

export default function WorkPermitInput({
	handleChange,
	workPermit,
}: WorkPermitInputProps) {
	const isNoneSelected =
		!workPermit ||
		!(workPermit?.haveIt || workPermit?.wouldNeedIt || workPermit?.dontNeedIt);

	return (
		<FormControl
			className="sm-custom-input"
			required
			component="fieldset"
			variant="standard">
			<FormLabel component="legend">
				{ProfileFieldNamesEnum.WORK_PERMIT}
				<Tooltip
					title={
						'Please let employers know whether you are legally allowed to work in your desired work locations. If you are a citizen of the country or do not otherwise require any special permit, select "I do not need a work permit for these locations".'
					}
					arrow>
					<InfoIcon className="sm-checkbox-icon" />
				</Tooltip>
			</FormLabel>
			<FormGroup>
				<FormControlLabel
					control={
						<Radio
							required={isNoneSelected}
							checked={workPermit?.haveIt ?? false}
							onChange={handleChange}
							name={'haveIt'}
						/>
					}
					label="I have a work permit for these locations."
				/>
				<FormControlLabel
					control={
						<Radio
							required={isNoneSelected}
							checked={workPermit?.wouldNeedIt ?? false}
							onChange={handleChange}
							name={'wouldNeedIt'}
						/>
					}
					label="I would need a work permit for these locations."
				/>
				<FormControlLabel
					control={
						<Radio
							required={isNoneSelected}
							checked={workPermit?.dontNeedIt ?? false}
							onChange={handleChange}
							name={'dontNeedIt'}
						/>
					}
					label="I do not need a work permit for these locations."
				/>
			</FormGroup>
			<FormHelperText>Please select at least one</FormHelperText>
		</FormControl>
	);
}
