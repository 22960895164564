import { StrictMode, useEffect } from 'react';
import SmInterceptor from '../../components/SmInterceptor';
import ProfilePreview from '../../components/TalentComponents/ProfilePreview';
import ProfileLayout from '../../layouts/ProfileLayout';

export default function ProfilePage() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<StrictMode>
			<SmInterceptor onlyRefreshToken={true} />
			<ProfileLayout content={<ProfilePreview />} />
		</StrictMode>
	);
}
