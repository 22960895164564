import { Box, SxProps, Theme } from '@mui/material';
import { DateView } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { ProfileFieldNamesEnum } from '../../../core/enums';

export interface StartDateInputProps {
	startDate: string | null;
	handleChange: (value: Dayjs | null) => void;
	label?: string;
	containerClass?: string;
	required: boolean;
	notched?: boolean;
	sx?: SxProps<Theme> | undefined;
	disabled?: boolean;
	format?: string;
	views?: DateView[];
	disableFuture?: boolean;
	isPresentAllowed?: boolean;
}

export function StartDateInput({
	startDate,
	handleChange,
	label = ProfileFieldNamesEnum.START_DATE,
	containerClass = '',
	required,
	notched = false,
	sx,
	disabled,
	format = 'DD-MMMM-YYYY',
	views = ['year', 'month', 'day'],
	disableFuture = false,
	isPresentAllowed,
}: StartDateInputProps) {
	const allowPresent =
		startDate === 'present' && isPresentAllowed ? { error: false } : {};

	return (
		<Box
			className={`sm-start-date-input sm-custom-input ${containerClass}`}
			sx={{
				marginTop: notched ? '10px !important' : 0,
				marginBottom: notched ? '8px !important' : '',
			}}>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DatePicker
					views={views}
					disabled={disabled}
					disableFuture={disableFuture}
					label={label}
					format={format}
					value={startDate ? dayjs(startDate) : null}
					onChange={handleChange}
					slotProps={{
						textField: {
							required: required,
							SelectProps: { notched },
							InputLabelProps: { shrink: notched, className: 'sm-label-bold' },
							...allowPresent,
						},
					}}
					sx={{ ...sx }}
				/>
			</LocalizationProvider>
		</Box>
	);
}
