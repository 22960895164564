import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ReportIcon from '@mui/icons-material/Report';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Autocomplete,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
	fetchConnectedTalents,
	fetchEmployers,
	fetchRecruiters,
} from '../../api/admin';
import { PathsEnum } from '../../core/enums';
import { Employer } from '../../shared/classes/employer';
import { Recruiter } from '../../shared/classes/recruiter';
import { Talent } from '../../shared/classes/talent';
import { toUrlId } from '../../shared/types/urlId';
import SmTextField from '../SmForms/SmFormComponents/SmTextField';
import SmSpinner from '../SmSpinner';

export default function EmployerList() {
	const [employers, setEmployers] = useState<Employer[]>([]);
	const [recruiters, setRecruiters] = useState<Recruiter[]>([]);
	const [showEmployers, setShowEmployers] = useState<Employer[]>([]);
	const [employerFilter, setEmployerFilter] = useState<Employer | null>(null);
	const [employerIdToConnectedTalents, setEmployerIdToConnectedTalents] =
		useState<Record<string, Talent[]>>({});

	useEffect(() => {
		fetchEmployers().then((emps) => {
			setEmployers(
				emps?.sort((a, b) =>
					a.name.trim().toLowerCase() < b.name.trim().toLowerCase() ? -1 : 1,
				) ?? [],
			);
		});
		fetchRecruiters().then((recs) => {
			setRecruiters(
				recs?.sort((a, b) =>
					a.recruiterProfile.firstName < b.recruiterProfile.firstName ? -1 : 1,
				) ?? [],
			);
		});
	}, []);

	useEffect(() => {
		if (employerFilter) setShowEmployers([employerFilter]);
		else setShowEmployers(employers);
	}, [employerFilter, employers]);

	useEffect(() => {}, [employers]);

	const handleLoadConnectedTalentsForEmployer = async (employerId: string) => {
		if (!employerIdToConnectedTalents[employerId]) {
			const connectedTalents = await fetchConnectedTalents(employerId);
			console.log(connectedTalents);
			setEmployerIdToConnectedTalents((prev) => ({
				...prev,
				[employerId]: connectedTalents ?? [],
			}));
		}
	};

	return (
		<div style={{ minHeight: '700px', padding: '20px' }}>
			<h2>Employer List</h2>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					maxWidth: 800,
					gap: '10px',
				}}>
				<Autocomplete
					style={{ maxWidth: 800, minWidth: 300 }}
					options={employers}
					getOptionLabel={(option) => `${option.name} (${option.employerId})`}
					value={employerFilter}
					onChange={(_, value) => setEmployerFilter(value)}
					renderInput={(params) => (
						<SmTextField
							className={
								'sm-profile-form-single-line-input-field sm-input-label'
							}
							required
							{...params}
							label={'Employer'}
							type="text"
							SelectProps={{ notched: true }}
							InputLabelProps={{ shrink: true }}
						/>
					)}
				/>
				{showEmployers.map((emp) => {
					return (
						<Accordion
							key={emp.employerId}
							sx={{ border: '1px solid #e7e7e7' }}>
							<AccordionSummary
								onClick={() =>
									handleLoadConnectedTalentsForEmployer(emp.employerId)
								}
								expandIcon={<ExpandMoreIcon />}
								id={emp.employerId}>
								{emp.name}
							</AccordionSummary>
							<AccordionDetails sx={{ backgroundColor: '#eee' }}>
								<p>
									<strong>Activated recruiters</strong>
								</p>
								<ul>
									{recruiters
										.filter((rec) => rec.employerId === emp.employerId)
										.map((rec) => (
											<li
												style={{
													display: 'flex',
													alignItems: 'center',
												}}
												key={rec.email}>
												{rec.isActive ? <HowToRegIcon /> : <ReportIcon />}
												&nbsp;&nbsp;
												{rec.email}
											</li>
										))}
								</ul>
								<p>
									<strong>Connected talents</strong>
								</p>
								{!!employerIdToConnectedTalents[emp.employerId] ? (
									<>
										{employerIdToConnectedTalents[emp.employerId].length ===
										0 ? (
											<ul>
												<li>No connected talents</li>
											</ul>
										) : (
											<ul>
												{employerIdToConnectedTalents[emp.employerId].map(
													(talent) => (
														<li
															style={{
																display: 'flex',
																alignItems: 'center',
															}}
															key={talent.email}>
															<PersonAddIcon />
															&nbsp;&nbsp;
															{talent.email}&nbsp;&nbsp;
															<Link
																target="_blank"
																to={`${PathsEnum.PROFILE}/${toUrlId(talent.userId)}`}>
																Profile
															</Link>
														</li>
													),
												)}
											</ul>
										)}
									</>
								) : (
									<SmSpinner />
								)}
							</AccordionDetails>
						</Accordion>
					);
				})}
			</div>
		</div>
	);
}
