import { Autocomplete, Button } from '@mui/material';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import {
	connectTalentToEmployer,
	fetchAdminEmailTemplatesForRecruiterAndTalent,
	fetchEmployers,
	fetchRecruiters,
	fetchTalentByUrlIdForAdmin,
	sendEmailAsAdmin,
} from '../../api/admin';
import { Employer } from '../../shared/classes/employer';
import { Recruiter } from '../../shared/classes/recruiter';
import { EmailTemplate, Talent } from '../../shared/classes/talent';
import { toUrlId } from '../../shared/types/urlId';
import EmailTemplateForm from '../SmForms/EmailTemplateForm';
import SmInput from '../SmForms/SmFormComponents/SmInput';
import SmTextField from '../SmForms/SmFormComponents/SmTextField';
import SmSpinner from '../SmSpinner';

export default function ConnectTalentForm() {
	const [employers, setEmployers] = useState<Employer[]>([]);
	const [selectedEmployer, setSelectedEmployer] = useState<Employer | null>(
		null,
	);
	const [recruiters, setRecruiters] = useState<Recruiter[]>([]);
	const [selectedRecruiter, setSelectedRecruiter] =
		useState<Recruiter | null>();
	const [talentUrlId, setTalentUrlId] = useState<string>('');
	const [selectedTalent, setSelectedTalent] = useState<Talent | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [emailDraft, setEmailDraft] = useState<EmailTemplate>(
		new EmailTemplate(),
	);
	const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>([]);
	const [isLoadingEmailTemplates, setIsLoadingEmailTemplates] =
		useState<boolean>(false);

	const recruitersForEmployer = useMemo(() => {
		setSelectedRecruiter(null);
		setEmailDraft(new EmailTemplate());
		return recruiters.filter(
			(rec) => rec.employerId === selectedEmployer?.employerId,
		);
	}, [selectedEmployer, recruiters]);

	useEffect(() => {
		fetchEmployers().then((emps) => {
			setEmployers(emps?.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)) ?? []);
		});
		fetchRecruiters().then((recs) => {
			setRecruiters(recs ?? []);
		});
	}, []);

	useEffect(() => {
		if (selectedRecruiter && talentUrlId) {
			setIsLoadingEmailTemplates(true);
			fetchAdminEmailTemplatesForRecruiterAndTalent(
				selectedRecruiter.userId,
				talentUrlId,
			)
				.then((templates) => {
					setEmailTemplates(templates);
					setEmailDraft(templates?.[0] ?? new EmailTemplate());
				})
				.finally(() => setIsLoadingEmailTemplates(false));
		}
	}, [selectedRecruiter, talentUrlId]);

	const fetchTalentByUrlId = async (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => {
		try {
			setIsLoading(true);
			setSelectedTalent(null);
			const urlId = event.target.value;
			setTalentUrlId(urlId);
			const talent = await fetchTalentByUrlIdForAdmin(urlId);

			if (talent) {
				setSelectedTalent(talent);
			}
		} catch (err) {
			console.log('error loading talent');
		} finally {
			setIsLoading(false);
		}
	};

	const handleSendEmail =
		emailDraft && selectedRecruiter
			? async () => {
					const response = await sendEmailAsAdmin(
						[selectedRecruiter.email],
						emailDraft,
						emailDraft.name,
					);
					if (response?.status === 200) {
						setSelectedEmployer(null);
						setTalentUrlId('');
						setSelectedTalent(null);
						setSelectedRecruiter(null);
						setEmailDraft(new EmailTemplate());
						return response;
					}
				}
			: async () => undefined;

	return (
		<div style={{ minHeight: '700px', padding: '20px' }}>
			<h2>Connect Employer and Talent</h2>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					maxWidth: 800,
					gap: '10px',
				}}>
				<Autocomplete
					style={{ maxWidth: 800, minWidth: 300 }}
					options={employers}
					getOptionLabel={(option) => `${option.name} (${option.employerId})`}
					value={selectedEmployer}
					onChange={(_, value) => setSelectedEmployer(value)}
					renderInput={(params) => (
						<SmTextField
							className={
								'sm-profile-form-single-line-input-field sm-input-label'
							}
							required
							{...params}
							label={'Employer'}
							type="text"
							SelectProps={{ notched: true }}
							InputLabelProps={{ shrink: true }}
						/>
					)}
				/>
				<div className="flex-space-between gap-10">
					<div
						className="flex-column gap-10 align-self-flex-start"
						style={{ minWidth: '450px' }}>
						<SmInput
							id={'talentUrlIdInput'}
							placeholder={'Talent URL ID'}
							value={talentUrlId}
							containerClass="sm-filter-input"
							handleChange={fetchTalentByUrlId}
						/>
						<div className="flex-row-gap-10">
							<Button
								disabled={!selectedEmployer || !talentUrlId}
								variant={'contained'}
								onClick={async () => {
									const result = await connectTalentToEmployer(
										talentUrlId,
										selectedEmployer?.employerId ?? 'invalid-employer-id####',
									);
									if (result?.status === 200) {
										alert('Success!');
									} else {
										alert('Error!');
									}
								}}>
								Connect
							</Button>
						</div>
					</div>

					<div
						style={{ height: '119px' }}
						className={`background-teal border-radius-6 border-light-grey full-width${!!selectedTalent ? ' cursor-pointer' : ''}`}
						onClick={() => {
							if (!!selectedTalent) {
								window.open(
									`${window.location.origin}/profile/${toUrlId(talentUrlId)}`,
								);
							}
						}}>
						{!selectedTalent ? (
							<>
								{isLoading ? (
									<div style={{ padding: '10px' }}>
										<SmSpinner />
									</div>
								) : (
									<p style={{ padding: '0px 10px' }}>
										{!talentUrlId
											? 'Enter Talent URL ID for preview'
											: 'Talent not found'}
									</p>
								)}
							</>
						) : (
							<div>
								<Button
									disabled={!talentUrlId}
									variant={'text'}
									sx={{ paddingLeft: '20px', paddingTop: '10px' }}>
									Open Talent Profile
								</Button>
								<div>
									<ul className="no-margin-top">
										<li>
											{selectedTalent?.profile.firstName}{' '}
											{selectedTalent?.profile.surnames}
										</li>
										<li>{selectedTalent?.email}</li>
									</ul>
								</div>
							</div>
						)}
					</div>
				</div>
				<hr style={{ width: '100%' }} />
				<p>
					<strong>Send confirmation email to Recruiter</strong>
				</p>
				<Autocomplete
					style={{ maxWidth: 800, minWidth: 300 }}
					options={recruitersForEmployer}
					getOptionLabel={(option) =>
						option
							? `${option.recruiterProfile.firstName} (${option.email})`
							: ''
					}
					value={selectedRecruiter}
					disabled={!selectedEmployer}
					onChange={(_, value) => setSelectedRecruiter(value ?? null)}
					renderInput={(params) => (
						<SmTextField
							className={
								'sm-profile-form-single-line-input-field sm-input-label'
							}
							required
							{...params}
							label={'Recruiter'}
							type="text"
							SelectProps={{ notched: true }}
							InputLabelProps={{ shrink: true }}
						/>
					)}
				/>
				<div>
					{isLoadingEmailTemplates ? (
						<div
							style={{
								position: 'absolute',
								height: '100%',
								width: '800px',
								display: 'flex',
								placeContent: 'center',
								zIndex: 10,
							}}>
							<div
								style={{
									backgroundColor: '#eee',
									borderRadius: '10%',
									width: '75px',
									height: '75px',
									display: 'flex',
									placeContent: 'center',
								}}>
								<SmSpinner />
							</div>
						</div>
					) : null}
					<EmailTemplateForm
						emailTemplates={emailTemplates}
						currentDraft={emailDraft}
						onChange={setEmailDraft}
						sendEmail={handleSendEmail}
						disabled={!selectedRecruiter || isLoadingEmailTemplates}
					/>
				</div>
			</div>
		</div>
	);
}
