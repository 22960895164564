import { create } from 'zustand';
import { MiscTalentFiltersEnum } from '../core/enums';
import { TalentFilters } from '../core/interfaces';
import { TalentData } from '../core/types';
import { isFilteredForPreferredWorkLocations } from '../core/utils';
import { SmRegionsEnum } from '../shared/enums';
import useScrumMaturityZustand from './ScrumMaturityZustand';

const initialFilter: TalentFilters = {
	statuses: [],
	nameAndEmail: '',
	successManagerNotes: false,
	regions: [],
	scrumMaturities: [],
	preferredWorkLocations: [],
	misc: [],
};

interface ReviewerTalentFilterZustandType {
	filters: TalentFilters;
	setFilters: (filter: TalentFilters) => void;
	matchesFilters: (talent: TalentData) => boolean;
	isEmpty: () => boolean;
}

const isFilteredForProfileStatus = (
	filters: TalentFilters,
	talent: TalentData,
) => filters.statuses.length === 0 || filters.statuses.includes(talent.status);
const isFilteredForRegion = (filters: TalentFilters, talent: TalentData) =>
	filters.regions.length === 0 ||
	filters.regions.includes(talent.region as SmRegionsEnum);
const isFilteredForMaturity = (filters: TalentFilters, talent: TalentData) =>
	filters.scrumMaturities.length === 0 ||
	filters.scrumMaturities.includes(
		useScrumMaturityZustand.getState().scrumMaturityLookup[talent.scrumMaturity]
			.dropdownLabel,
	);
const isFilteredForMisc = (filters: TalentFilters, talent: TalentData) =>
	filters.misc.length === 0 ||
	(filters.misc.includes(MiscTalentFiltersEnum.MISSING_SM_NOTES) &&
		talent.successManagerNotes === '-');
const isFilteredForNameAndEmail = (
	filters: TalentFilters,
	talent: TalentData,
) => {
	const stringMatches = (identifier: string) =>
		identifier?.toLowerCase().includes(filters.nameAndEmail.toLowerCase());
	return (
		filters.nameAndEmail === '' ||
		[talent.fullName, talent.email].some(stringMatches)
	);
};

const useReviewerTalentFilterZustand = create<ReviewerTalentFilterZustandType>(
	(set, get) => ({
		filters: initialFilter,
		setFilters: (filter: TalentFilters) => {
			set({ filters: filter });
		},
		matchesFilters: (talent: TalentData) =>
			isFilteredForProfileStatus(get().filters, talent) &&
			isFilteredForRegion(get().filters, talent) &&
			isFilteredForMaturity(get().filters, talent) &&
			isFilteredForMisc(get().filters, talent) &&
			isFilteredForNameAndEmail(get().filters, talent) &&
			isFilteredForPreferredWorkLocations(
				get().filters.preferredWorkLocations,
				talent.preferredWorkLocations,
			),
		isEmpty: () => get().filters === initialFilter,
	}),
);

export default useReviewerTalentFilterZustand;
