import VerifiedIcon from '@mui/icons-material/Verified';
import { Profile } from '../../shared/classes/profile';
import GetInTouchButton from '../SmButtons/GetInTouchButton';

interface UserInfoBoxProps {
	profile?: Profile;
	isRecruiter?: boolean;
	isAnonymousView?: boolean;
}
export default function UserInfoBox({
	profile,
	isRecruiter = false,
	isAnonymousView = false,
}: UserInfoBoxProps) {
	const firstName = isAnonymousView ? 'Scrum' : profile?.firstName;
	const middleName = isAnonymousView ? '' : profile?.middleName;
	const surnames = isAnonymousView ? 'Master' : profile?.surnames;

	return (
		<div className="sm-profile-preview-user-info">
			<h1 className="sm-profile-preview-user-name">
				{firstName} {middleName ? middleName + ' ' : ''}
				{surnames}
			</h1>
			<div className="sm-profile-preview-user-info-icon-text sm-profile-preview-tagline">
				<img
					src={require('../../assets/blue-arrow-right.png')}
					alt="arrow pointing right"
				/>
				<p className="sm-profile-preview-user-tagline">{profile?.tagLine}</p>
			</div>
			<div style={{ minHeight: 54.4 }}>
				{isAnonymousView ? (
					<GetInTouchButton isRecruiter={isRecruiter} />
				) : (
					<div className="sm-profile-preview-user-info-icon-text">
						<VerifiedIcon />
						<p className="sm-profile-preview-user-profession">Scrum Master</p>
					</div>
				)}
			</div>
		</div>
	);
}
