import { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isAdmin } from '../../api/admin';
import { PathsEnum } from '../../core/enums';
import { AdminMenuItem } from '../../pages/admin/DashboardPage';
import ActivateRecruiterForm from './ActivateRecruiterForm';
import ConnectTalentForm from './ConnectTalentForm';
import DebugForm from './DebugForm';
import NewEmployerForm from './NewEmployerForm';
import EmployerList from './EmployerList';

interface DashboardProps {
	selectedMenuItem: AdminMenuItem;
}

export default function AdminDashboard({ selectedMenuItem }: DashboardProps) {
	const navigate = useNavigate();

	useEffect(() => {
		isAdmin().then((result) => {
			if (!result) {
				navigate(PathsEnum.LOGIN);
			}
		});
	}, [navigate]);

	const displayNode = (): ReactNode => {
		switch (selectedMenuItem) {
			case 'Home':
				return (
					<div>
						<h1>Home</h1>
						<p>
							Welcome to the admin dashboard. Use the menu on the left to find
							what you want to do. More will be displayed here soon 🦄
						</p>
					</div>
				);
			case 'Create New Employer':
				return <NewEmployerForm />;
			case 'Employers List':
				return <EmployerList />;
			case 'Activate Recruiter':
				return <ActivateRecruiterForm />;
			case 'Connect Talent':
				return <ConnectTalentForm />;
			case 'Debug':
				return <DebugForm />;
			default:
				return (
					<div>
						<h1>Home</h1>
						<p>Welcome to the admin dashboard.</p>
					</div>
				);
		}
	};

	return <>{displayNode()}</>;
}
