import { extractSmLanguages, SmLanguage } from '../types/languages';
import { SmLocation } from './smLocation';
import {
	extractArray,
	extractBoolean,
	extractNumber,
	extractSalaryExpectationsCurrency,
	extractString,
	ViewPermissionsEnum,
} from './utils';

const requiredFields: (keyof Omit<
	Profile,
	| 'middleName'
	| 'education'
	| 'certifications'
	| 'personalInterests'
	| 'checksums'
	| 'status'
>)[] = [
	'firstName',
	'surnames',
	'tagLine',
	'intro',
	'evalAnswer',
	'preferredWorkLocations',
	'workPermit',
	'contractType',
	'salaryExpectations',
	'workModel',
	'startDate',
	'preferredWorkLanguages',
	'scrumExperienceInYears',
	'currentLocation',
];

export const defaultProseMaxLength = 2500;

const isValidSalaryExpectationsForEmployment = (profile: Profile): boolean => {
	if (!profile.contractType || !profile.salaryExpectations) return false;

	const { employment, freelance, supplier } = profile.contractType;
	const { hourlyGross, yearlyGross } = profile.salaryExpectations;

	const hasAtLeastOneContractType = employment || freelance || supplier;
	const hasValidEmploymentData = !employment || !!yearlyGross;
	const hasValidFreelanceOrSupplierData =
		!(freelance || supplier) || !!hourlyGross;

	const isValid =
		hasAtLeastOneContractType &&
		hasValidEmploymentData &&
		hasValidFreelanceOrSupplierData;

	return isValid;
};

export class Profile {
	firstName: string;
	middleName: string;
	surnames: string;
	tagLine: string;
	contractType: ContractType;
	salaryExpectations?: SalaryExpectations;
	currentLocation: SmLocation;
	preferredWorkLocations: SmLocation[];
	workModel: WorkModel;
	startDate: string;
	preferredWorkLanguages: SmLanguage[];
	scrumExperienceInYears: number | null;
	education: string;
	certifications: string;
	socialMediaLink?: string;
	referenceContact?: string;
	workPermit: WorkPermit;
	intro: string;
	evalAnswer: string;
	personalInterests: string;
	anonymizedFields: AnonymizedFields;
	status: ProfileStatusesEnum;
	checksums: Checksums;
	workExperiences: WorkExperience[];

	constructor(data?: Profile) {
		this.firstName = extractString(data?.firstName);
		this.middleName = extractString(data?.middleName);
		this.surnames = extractString(data?.surnames);
		this.tagLine = extractString(data?.tagLine);
		this.contractType = new ContractType(data?.contractType);
		this.salaryExpectations = new SalaryExpectations(data?.salaryExpectations);
		this.currentLocation = new SmLocation(data?.currentLocation);
		this.preferredWorkLocations = extractArray<SmLocation>(
			data?.preferredWorkLocations,
			[],
			(loc: any) => new SmLocation(loc),
		);
		this.workModel = new WorkModel(data?.workModel);
		this.startDate = extractString(data?.startDate);
		this.preferredWorkLanguages = extractSmLanguages(
			extractArray<SmLanguage>(data?.preferredWorkLanguages, []),
		);
		this.scrumExperienceInYears = extractNumber(
			data?.scrumExperienceInYears,
			null,
		);
		this.education = extractString(data?.education, '', defaultProseMaxLength);
		this.certifications = extractString(
			data?.certifications,
			'',
			defaultProseMaxLength,
		);
		this.education = extractString(data?.education);
		this.certifications = extractString(data?.certifications);
		this.socialMediaLink = extractString(data?.socialMediaLink, undefined);
		this.referenceContact = extractString(data?.referenceContact, undefined);
		this.workPermit = new WorkPermit(data?.workPermit);
		this.intro = extractString(data?.intro, '', defaultProseMaxLength);
		this.evalAnswer = extractString(
			data?.evalAnswer,
			'',
			defaultProseMaxLength,
		);
		this.personalInterests = extractString(
			data?.personalInterests,
			'',
			defaultProseMaxLength,
		);
		this.anonymizedFields = new AnonymizedFields(data?.anonymizedFields);
		this.status = extractProfileStatus(data, 'status');
		this.checksums = new Checksums(data?.checksums);
		this.workExperiences = extractArray<WorkExperience>(
			data?.workExperiences,
			[],
			(workExperience) => new WorkExperience(workExperience),
		);
	}

	isReadyToSubmit?(): boolean {
		const isValidSalaryExpectationsForContractType =
			isValidSalaryExpectationsForEmployment(this);
		return (
			requiredFields?.every((field) => {
				const isEmptyArray =
					Array.isArray(this[field]) && (this[field] as any[]).length === 0;
				if (
					this[field] === undefined ||
					this[field] === null ||
					this[field] === '' ||
					isEmptyArray
				) {
					return false;
				} else if (
					this[field] !== undefined &&
					typeof this[field] === 'object' &&
					!Array.isArray(this[field])
				) {
					const obj = this[field] as { isReadyToSubmit?: () => boolean };
					return obj.isReadyToSubmit && obj.isReadyToSubmit();
				} else if (
					typeof this[field] === 'number' &&
					this[field] !== undefined &&
					(Number.isNaN(this[field]) || (this[field] as number) < 0)
				) {
					return false;
				} else if (
					!isEmptyArray &&
					Array.isArray(this[field]) &&
					field === 'preferredWorkLocations'
				) {
					const isAllSmLocations = this[field].every((location) => {
						const loc = new SmLocation(location);
						return loc.isReadyToSubmit && loc.isReadyToSubmit();
					});
					return isAllSmLocations;
				} else {
					return true;
				}
			})! && isValidSalaryExpectationsForContractType
		);
	}
}

export class ContractType {
	employment: boolean;
	freelance: boolean;
	supplier: boolean;

	constructor(data?: Record<string, any> | undefined) {
		this.employment = extractBoolean(data?.employment);
		this.freelance = extractBoolean(data?.freelance);
		this.supplier = extractBoolean(data?.supplier);
	}

	isReadyToSubmit?(): boolean {
		return this.employment || this.freelance || this.supplier;
	}
}

export type ContractTypeKeys = keyof Omit<ContractType, 'isReadyToSubmit'>;

export const salaryExpectationsCurrencies = ['EUR', 'USD'] as const;
export type SalaryExpectationsCurrency =
	(typeof salaryExpectationsCurrencies)[number];
export type SalaryExpecationsCurrencyWithAny =
	| 'any'
	| SalaryExpectationsCurrency;
export const currencyToSymbol: Record<SalaryExpectationsCurrency, string> = {
	EUR: '€',
	USD: '$',
};

export class SalaryExpectations {
	currency: SalaryExpectationsCurrency;
	yearlyGross: number | null;
	hourlyGross: number | null;

	constructor(data?: SalaryExpectations) {
		this.currency = extractSalaryExpectationsCurrency(data?.currency, 'USD');
		this.yearlyGross = extractNumber(data?.yearlyGross, null);
		this.hourlyGross = extractNumber(data?.hourlyGross, null);
	}

	isReadyToSubmit?(): boolean {
		return !!this.currency && (!!this.yearlyGross || !!this.hourlyGross);
	}
}

export class WorkModel {
	remote: boolean;
	onSite: boolean;
	hybrid: boolean;

	constructor(data?: WorkModel) {
		this.remote = extractBoolean(data?.remote);
		this.onSite = extractBoolean(data?.onSite);
		this.hybrid = extractBoolean(data?.hybrid);
	}

	isReadyToSubmit?(): boolean {
		return this.remote || this.onSite || this.hybrid;
	}
}

export type WorkModelKeys = keyof Omit<WorkModel, 'isReadyToSubmit'>;

export class WorkPermit {
	haveIt: boolean;
	wouldNeedIt: boolean;
	dontNeedIt: boolean;

	constructor(data?: WorkPermit) {
		this.haveIt = extractBoolean(data?.haveIt);
		this.wouldNeedIt = extractBoolean(data?.wouldNeedIt);
		this.dontNeedIt = extractBoolean(data?.dontNeedIt);
	}

	isReadyToSubmit?(): boolean {
		return this.haveIt || this.wouldNeedIt || this.dontNeedIt;
	}
}

export enum ProfileStatusesEnum {
	DRAFT = 'draft',
	SUBMITTED = 'submitted',
	FEEDBACK_GIVEN = 'feedback-given',
	SCHEDULING = 'scheduling',
	SCHEDULED = 'scheduled',
	PASSED = 'passed',
	FAILED = 'failed',
	ACTIVE = 'active',
	INACTIVE = 'inactive',
	MATCHED = 'matched',
	HIRED_ELSEWHERE = 'hired-elsewhere',
}

export const extractProfileStatus = (
	object: Record<string, any> | undefined,
	key: string,
) => {
	if (
		object &&
		object[key] &&
		typeof object[key] === 'string' &&
		//@ts-ignore
		Object.values(ProfileStatusesEnum).includes(object[key] as string)
	) {
		return object[key];
	} else {
		return ProfileStatusesEnum.DRAFT;
	}
};

export class AnonymizedFields {
	evalAnswerAnonymous: string;
	introAnonymous: string;
	personalInterestsAnonymous: string;
	workExperiencesAnonymous: WorkExperience[];

	constructor(data?: AnonymizedFields) {
		this.evalAnswerAnonymous = extractString(
			data?.evalAnswerAnonymous,
			'',
			defaultProseMaxLength,
		);
		this.introAnonymous = extractString(
			data?.introAnonymous,
			'',
			defaultProseMaxLength,
		);
		this.personalInterestsAnonymous = extractString(
			data?.personalInterestsAnonymous,
			'',
			defaultProseMaxLength,
		);
		this.workExperiencesAnonymous = extractArray<WorkExperience>(
			data?.workExperiencesAnonymous,
			[],
			(workExperience) => new WorkExperience(workExperience),
		);
	}
}

export class AnonymousProfile extends Profile {
	isAnonymous = true;

	constructor(data?: AnonymousProfile) {
		super(data);
		this.intro = '';
		this.evalAnswer = '';
		this.personalInterests = '';
		this.firstName = 'Scrum';
		this.middleName = '';
		this.surnames = 'Master';
		this.socialMediaLink = '';
	}
}

export type ProfileResponse =
	| {
			permissions: ViewPermissionsEnum.ANONYMOUS;
			profile: AnonymousProfile;
	  }
	| {
			permissions:
				| ViewPermissionsEnum.REVIEWER
				| ViewPermissionsEnum.MYSELF
				| ViewPermissionsEnum.CONNECTED_RECRUITER;
			profile: Profile;
			email: string;
	  };

export class Checksums {
	introChecksum?: string;
	introAnonymousChecksum?: string;
	evalAnswerChecksum?: string;
	evalAnswerAnonymousChecksum?: string;
	personalInterestsChecksum?: string;
	personalInterestsAnonymousChecksum?: string;
	workExperiencesChecksum?: string;
	workExperiencesAnonymousChecksum?: string;

	constructor(data?: Checksums) {
		this.introChecksum = extractString(data?.introChecksum, undefined);
		this.introAnonymousChecksum = extractString(
			data?.introAnonymousChecksum,
			undefined,
		);
		this.evalAnswerChecksum = extractString(
			data?.evalAnswerChecksum,
			undefined,
		);
		this.evalAnswerAnonymousChecksum = extractString(
			data?.evalAnswerAnonymousChecksum,
			undefined,
		);
		this.personalInterestsChecksum = extractString(
			data?.personalInterestsChecksum,
			undefined,
		);
		this.personalInterestsAnonymousChecksum = extractString(
			data?.personalInterestsAnonymousChecksum,
			undefined,
		);
		this.workExperiencesChecksum = extractString(
			data?.workExperiencesChecksum,
			undefined,
		);
		this.workExperiencesAnonymousChecksum = extractString(
			data?.workExperiencesAnonymousChecksum,
			undefined,
		);
	}
}

interface ProfileStatusPermissions {
	canSave?: boolean;
	canSubmit?: boolean;
	canUpdate?: boolean;
	canPause?: boolean;
	canUnpause?: boolean;
	canActivate?: boolean;
	canSeePaymentLink?: boolean;
}

const profileStatusToPermissions: Record<
	ProfileStatusesEnum,
	ProfileStatusPermissions
> = {
	[ProfileStatusesEnum.DRAFT]: { canSave: true, canSubmit: true },
	[ProfileStatusesEnum.SUBMITTED]: {},
	[ProfileStatusesEnum.FEEDBACK_GIVEN]: { canSave: true, canSubmit: true },
	[ProfileStatusesEnum.SCHEDULING]: {},
	[ProfileStatusesEnum.SCHEDULED]: {},
	[ProfileStatusesEnum.PASSED]: {
		canUpdate: true,
		canActivate: true,
		canSeePaymentLink: true,
	},
	[ProfileStatusesEnum.FAILED]: { canSave: true, canSubmit: true },
	[ProfileStatusesEnum.ACTIVE]: {
		canUpdate: true,
		canPause: true,
		canActivate: true,
		canSeePaymentLink: true,
	},
	[ProfileStatusesEnum.INACTIVE]: {
		canUpdate: true,
		canUnpause: true,
		canSeePaymentLink: true,
	},
	[ProfileStatusesEnum.MATCHED]: { canUpdate: true, canSeePaymentLink: true },
	[ProfileStatusesEnum.HIRED_ELSEWHERE]: {
		canUpdate: true,
		canSeePaymentLink: true,
	},
};
export const canSaveProfile = (status?: ProfileStatusesEnum): boolean => {
	return (
		profileStatusToPermissions[status ?? ProfileStatusesEnum.DRAFT].canSave ??
		false
	);
};
export const canSubmitProfile = (status?: ProfileStatusesEnum): boolean => {
	return (
		profileStatusToPermissions[status ?? ProfileStatusesEnum.DRAFT].canSubmit ??
		false
	);
};
export const canUpdateProfile = (status?: ProfileStatusesEnum): boolean => {
	return (
		profileStatusToPermissions[status ?? ProfileStatusesEnum.DRAFT].canUpdate ??
		false
	);
};
export const canPauseProfile = (status?: ProfileStatusesEnum): boolean => {
	return (
		profileStatusToPermissions[status ?? ProfileStatusesEnum.DRAFT].canPause ??
		false
	);
};
export const canUnpauseProfile = (status?: ProfileStatusesEnum): boolean => {
	return (
		profileStatusToPermissions[status ?? ProfileStatusesEnum.DRAFT]
			.canUnpause ?? false
	);
};
export const canActivateProfile = (status?: ProfileStatusesEnum): boolean => {
	return (
		profileStatusToPermissions[status ?? ProfileStatusesEnum.DRAFT]
			.canActivate ?? false
	);
};
export const canSeePaymentLink = (status?: ProfileStatusesEnum): boolean => {
	return (
		profileStatusToPermissions[status ?? ProfileStatusesEnum.DRAFT]
			.canSeePaymentLink ?? false
	);
};

export class WorkExperience {
	role: string;
	industry: string;
	startDate: string | null;
	endDate: string | null;
	keyAchievements: string[];

	constructor(data?: WorkExperience) {
		this.role = extractString(data?.role);
		this.industry = extractString(data?.industry);
		this.startDate = data?.startDate ? extractString(data?.startDate) : null;
		this.endDate = data?.endDate ? extractString(data?.endDate) : null;
		this.keyAchievements = extractArray<string>(
			data?.keyAchievements,
			['', '', ''],
			(achievement) => extractString(achievement, '', 100),
		).slice(0, 3);
	}
}
