import { StrictMode, useEffect, useState } from 'react';
import AdminDashboard from '../../components/AdminComponents/AdminDashboard';
import AdminSidebarMenu from '../../components/LayoutComponents/SidebarComponents/AdminSidebarMenu';
import SmInterceptor from '../../components/SmInterceptor';
import { PathsEnum } from '../../core/enums';
import SidebarLayout from '../../layouts/SidebarLayout';

const menuItems = [
	'Home',
	'Create New Employer',
	'Employers List',
	'Activate Recruiter',
	'Connect Talent',
	'Debug',
] as const;
export type AdminMenuItem = (typeof menuItems)[number];
const extractAdminSubPath = (path: PathsEnum): string => {
	return path.split('/').pop() ?? 'invalid';
};
const adminSubPathToMenuItem = {
	[extractAdminSubPath(PathsEnum.ADMIN_DASHBOARD)]: 'Home',
	[extractAdminSubPath(PathsEnum.ADMIN_NEW_EMPLOYER)]: 'Create New Employer',
	[extractAdminSubPath(PathsEnum.ADMIN_EMPLOYER_LIST)]: 'Employers List',
	[extractAdminSubPath(PathsEnum.ADMIN_ACTIVATE_RECRUITER)]:
		'Activate Recruiter',
	[extractAdminSubPath(PathsEnum.ADMIN_CONNECT_TALENT)]: 'Connect Talent',
	[extractAdminSubPath(PathsEnum.ADMIN_DEBUG)]: 'Debug',
};

export default function DashboardPage() {
	const [selectedMenuItem, setSelectedMenuItem] = useState<AdminMenuItem>(
		menuItems[0],
	);

	useEffect(() => {
		const subPath = window.location.pathname.split('/').pop();
		const menuItem = adminSubPathToMenuItem[subPath ?? ''] as AdminMenuItem;
		setSelectedMenuItem(
			menuItems.find((item) => item === menuItem) ?? menuItems[0],
		);
	}, []);

	const handleSetSelectedMenuItem = (menuItem: AdminMenuItem) => {
		setSelectedMenuItem(menuItem);
		const subPath = Object.entries(adminSubPathToMenuItem).find(
			([, value]) => value === menuItem,
		)?.[0];
		if (subPath) {
			window.history.pushState({}, '', subPath);
		}
	};

	return (
		<StrictMode>
			<SmInterceptor />
			<SidebarLayout
				content={<AdminDashboard selectedMenuItem={selectedMenuItem} />}
				menu={
					<AdminSidebarMenu
						menuItems={menuItems}
						selectedMenuItem={selectedMenuItem}
						setSelectedMenuItem={handleSetSelectedMenuItem}
					/>
				}
			/>
		</StrictMode>
	);
}
