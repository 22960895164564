import { Checkbox, FormControlLabel } from '@mui/material';
import { CSSProperties } from 'react';
import './SmFormComponents.css';
import { PathsEnum } from '../../../core/enums';

interface SmTalentTermsCheckboxProps {
	setAgreed: (value: React.SetStateAction<boolean>) => void;
	inDialogBox?: boolean;
}

export default function SmTalentTermsCheckbox({
	setAgreed,
	inDialogBox,
}: SmTalentTermsCheckboxProps) {
	const dialogBoxStyles: CSSProperties = inDialogBox
		? {
				position: 'relative',
				top: 10,
		  }
		: {};

	return (
		<div className="terms-of-service-checkbox-label">
			<FormControlLabel
				control={
					<Checkbox onChange={(event) => setAgreed(event.target.checked)} />
				}
				label={
					<p className="sm-checkbox-label" style={{ ...dialogBoxStyles }}>
						<span>I accept the </span>
						<a
							href={PathsEnum.TALENT_TERMS_OF_USE}
							target="_blank"
							rel="noreferrer">
							terms of use
						</a>
						<span>, </span>
						<a
							href={PathsEnum.PRIVACY_POLICY}
							target="_blank"
							rel="noreferrer">
							privacy policy
						</a>
						<span> and </span>
						<a
							href={PathsEnum.COOKIE_POLICY}
							target="_blank"
							rel="noreferrer">
							cookie policy
						</a>
						<span>.</span>
					</p>
				}
			/>
		</div>
	);
}
