export enum SmRegionsEnum {
	'N/A' = 'n/a',
	AMERICAS = 'Americas',
	EMEA = 'EMEA',
	APAC = 'APAC',
}

export const extractSmRegion = (data?: any): SmRegionsEnum => {
	const SmRegions = Object.values(SmRegionsEnum);
	if (SmRegions.includes(data)) {
		return data as SmRegionsEnum;
	} else {
		return SmRegionsEnum['N/A'];
	}
};

export enum TestEnum {
	INVALID_INPUT = 'wrong',
}
