import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { create } from 'zustand';
import { getMyUserInfo, isTalentLoggedIn } from '../api/talents';
import { COOKIE_CONFIG, SM_AUTH_COOKIE } from '../core/consts';
import { UserInfo } from '../shared/types/UserInfoTypes';
import useProfileZustand from './ProfileZustand';

interface UserInfoZustandType {
	userInfo: UserInfo | undefined;
	loadUserInfo: () => Promise<void>;
	logout: () => Promise<void>;
	setLogout: (logout: () => Promise<void>) => void;
}

const useUserInfoZustand = create<UserInfoZustandType>((set, get) => ({
	userInfo: undefined,
	loadUserInfo: async () => {
		if (!get().userInfo?.email || !get().userInfo?.loginProvider) {
			if (!(await isTalentLoggedIn())) {
				return;
			}
			try {
				const userInfoFromBackend = await getMyUserInfo();
				set({ userInfo: userInfoFromBackend });
			} catch (error) {
				console.error('User not logged in. Cannot load user info.', error);
			}
		}
	},
	logout: async () => {},
	setLogout: (logout) => set({ logout }),
}));

export default useUserInfoZustand;

export function UserInfoProvider() {
	const [cookies, _setCookies, removeCookies] = useCookies([SM_AUTH_COOKIE]);
	const { setProfile } = useProfileZustand();
	const { loadUserInfo, setLogout } = useUserInfoZustand();

	useEffect(() => {
		loadUserInfo();
	}, [cookies, loadUserInfo]);

	useEffect(() => {
		setLogout(async () => {
			removeCookies(SM_AUTH_COOKIE, COOKIE_CONFIG);
			setProfile(null);
			window.location.reload();
		});
	}, [removeCookies, setProfile, setLogout]);

	return <></>;
}
