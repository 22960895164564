import { ReactNode } from 'react';
import { ContractTypeKeys, WorkModelKeys } from '../shared/classes/profile';
import { ScrumMaturity } from '../shared/classes/talent';
import { ScrumMaturityConfig } from '../shared/types/config';

export interface ScrumMaturityDescription {
	metaText: string;
	shouldHireNode: ReactNode;
	dropdownLabel: string;
	graphText: string;
	shortLabel: string;
	percentageNumeric: number;
	percentageExplanation: ReactNode;
}

const toPercentageExplanation = (percentage: number, variableText: string) => (
	<>
		This Scrum Master is in the <mark>{percentage}% area</mark> of true Scrum
		Masters who {variableText}.
	</>
);

const toShouldHireNode = (variableText: string) => (
	<>
		Yes, you should hire them if you are looking for a true Scrum Master who{' '}
		{variableText}. Here are our review results:
	</>
);

export const generateScrumMaturityLookup = (
	config: ScrumMaturityConfig,
): Record<ScrumMaturity, ScrumMaturityDescription> => {
	return {
		'': {
			metaText: '',
			shouldHireNode: <></>,
			dropdownLabel: 'n/a',
			graphText: '',
			shortLabel: 'n/a',
			percentageNumeric: 0,
			percentageExplanation: <></>,
		},
		'-1': {
			metaText: `ScrumMatch rejects ${config['-1']}% of the submitted profiles during the review process because the candidates do not demonstrate a sufficient understanding of Scrum.`,
			shouldHireNode: (
				<p>
					ScrumMatch has reviewed this Scrum Master and gathered insights, so
					that you can hire the Scrum Master that matches you best:
				</p>
			),
			graphText: `${config['-1']}%`,
			shortLabel: 'Rejected',
			dropdownLabel: `Rejected (${config['-1']}%)`,
			percentageNumeric: config['-1'],
			percentageExplanation: <></>,
		},
		'0': {
			metaText: `This Scrum Master has Scrum knowledge. They have foundational understanding with little to no experience. They know Scrum is different but struggle to explain why, how and the benefits. They struggle to explain what an Increment is and why it is essential.`,
			shouldHireNode: toShouldHireNode('has Scrum knowledge'),
			graphText: `${config[0]}%`,
			shortLabel: 'Having Scrum knowledge',
			dropdownLabel: `Having Scrum knowledge (${config[0]}%)`,
			percentageNumeric: config[0],
			percentageExplanation: toPercentageExplanation(
				config[0],
				'have Scrum knowledge',
			),
		},
		'1': {
			metaText: `This Scrum Master knows how to apply Scrum foundations. They have some Scrum experience as a Scrum Master (according to Scrum Guide). They understand the what and the how, and follow the process (Scrum might not be established completely). They focus on facilitating Scrum events and team efficiency. They help Developers track their work and progress; help the Product Owner create Product Backlog Items. Their team(s) do(es) not build and deliver Increments regularly.`,
			shouldHireNode: toShouldHireNode('knows how to apply Scrum foundations'),
			graphText: `${config[1]}%`,
			shortLabel: 'Applying Scrum foundations',
			dropdownLabel: `Applying Scrum foundations (${config[1]}%)`,
			percentageNumeric: config[1],
			percentageExplanation: toPercentageExplanation(
				config[1],
				'apply Scrum foundations',
			),
		},
		'2': {
			metaText: `This Scrum Master knows how to improve the use of Scrum. They have situational Scrum experience. They understand the why and the principles. They facilitate the use of Scrum to focus on goals and outcomes. They enable Developers to self-manage; enable the Product Owner to focus the team on the Product Goal. Their team(s) build(s) Increments regularly and deliver(s) frequently.`,
			shouldHireNode: toShouldHireNode('knows how to improve the use of Scrum'),
			graphText: `${config[2]}%`,
			shortLabel: 'Improving Scrum',
			dropdownLabel: `Improving Scrum (${config[2]}%)`,
			percentageNumeric: config[2],
			percentageExplanation: toPercentageExplanation(
				config[2],
				'improve the use of Scrum',
			),
		},
		'3': {
			metaText: `This Scrum Master uses Scrum to improve the product. They have extended Scrum experience. They grow and expand upon their experience with complementary practices to enhance business value. They optimize empirical feedback loops to optimize product outcomes and business results. They grow the Product Owner and Developers to use Scrum for discovery and delivery of value. Their team(s) deliver(s) and validate(s) Increments regularly.`,
			shouldHireNode: toShouldHireNode('uses Scrum to improve the product'),
			graphText: `${config[3]}%`,
			shortLabel: 'Improving the product',
			dropdownLabel: `Improving the product (${config[3]}%)`,
			percentageNumeric: config[3],
			percentageExplanation: toPercentageExplanation(
				config[3],
				'use Scrum to improve the product',
			),
		},
		'4': {
			metaText: `This Scrum Master uses Scrum to optimize the product value stream. They use their experience of Scrum and complementary practices to optimize a product value stream. They use a lean approach to scaling (horizontally/along a value stream; vertically/number of teams). They optimize the delivery and discovery of value by multiple teams developing one product. They grow the Product Owner to own adjacent areas of the product value stream. The value stream delivers and validates Increments regularly and frequently.`,
			shouldHireNode: toShouldHireNode(
				'uses Scrum to optimize the product value stream',
			),
			graphText: `${config[4]}%`,
			shortLabel: 'Optimizing the product value stream',
			dropdownLabel: `Optimizing the product value stream (${config[4]}%)`,
			percentageNumeric: config[4],
			percentageExplanation: toPercentageExplanation(
				config[4],
				'use Scrum to optimize the product value stream',
			),
		},
		'5': {
			metaText: `This Scrum Master uses Scrum to optimize the whole organization. They can define products, set up an organizational system that optimizes for agility and guide an organization through trade-off decisions. They are optimizing the organization for agility and expanding the adoption of Scrum. They demonstrate an expert level of being a change agent. They create an environment for organizational agility to thrive. They have the ability to pivot at the organizational level, not at the team level or product level.`,
			shouldHireNode: toShouldHireNode(
				'uses Scrum to optimize the whole organization',
			),
			graphText: `${config[5]}%`,
			shortLabel: 'Optimizing the whole organization',
			dropdownLabel: `Optimizing the whole organization (${config[5]}%)`,
			percentageNumeric: config[5],
			percentageExplanation: toPercentageExplanation(
				config[5],
				'use Scrum to optimize the whole organization',
			),
		},
	};
};

export const contractTypeLookup: Record<ContractTypeKeys, string> = {
	employment: 'Employment',
	freelance: 'Independent consultant',
	supplier: 'Consulting firm',
};

export const workModelLookup: Record<WorkModelKeys, string> = {
	onSite: 'On-Site',
	remote: 'Remote',
	hybrid: 'Hybrid',
};
