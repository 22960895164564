import { Button, ButtonProps } from '@mui/material';

export default function ArrowButton(props: ButtonProps) {
	return (
		<Button
			endIcon={
				<img
					src={require('../../assets/sm-arrow.png')}
					alt={'arrow pointing right'}
				/>
			}>
			{props.children}
		</Button>
	);
}
