import SmLogoutButton from '../../SmButtons/SmLogoutButton';

export default function RecruiterMenu() {
	const supportEmail = 'employer-success@scrummatch.com';

	return (
		<div className="sm-sidebar-menu">
			<div className="sm-sidebar-title">
				<h1>ScrumMatch for Recruiters</h1>
			</div>
			<SmLogoutButton />
			<div className="sm-info-text sm-sidebar-text margin-bottom-50">
				<p>
					NEED HELP?{' '}
					<a
						href={`mailto:${supportEmail}`}
						style={{ color: '#fff' }}
						target="_blank"
						rel="noreferrer">
						CONTACT&nbsp;US
					</a>
				</p>
			</div>
		</div>
	);
}
