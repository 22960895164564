import { Button, ButtonProps } from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';

type GoogleLoginButtonProps = ButtonProps & {
	handleSuccess: ({ code }: { code: string }) => void;
	label?: string;
};

export default function GoogleLoginButton({
	handleSuccess,
	label = 'SIGN UP WITH GOOGLE',
	disabled = false,
}: GoogleLoginButtonProps) {
	const googleLogin = useGoogleLogin({
		onSuccess: handleSuccess,
		flow: 'auth-code',
	});

	return (
		<Button
			onClick={googleLogin}
			variant="login"
			disableElevation
			type="submit"
			disabled={disabled}
			startIcon={
				<img src={require('../../assets/google-logo.png')} alt={'Google logo'} />
			}>
			{label}
		</Button>
	);
}
