import { TextField, TextFieldProps } from '@mui/material';

type SmTextFieldProps = TextFieldProps & {
	charLimit?: number;
};

export default function SmTextField(props: SmTextFieldProps) {
	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (props.charLimit) {
			e.target.value = e.target.value.slice(0, props.charLimit);
		}
		if (props.onChange) {
			props.onChange(e);
		}
	};

	return (
		<TextField
			{...props}
			SelectProps={{ notched: true, ...props.SelectProps }}
			InputLabelProps={{ shrink: true, ...props.InputLabelProps }}
			margin={props.margin ?? 'normal'}
			sx={{ marginTop: '16px', ...props.sx }}
			onChange={handleOnChange}
		/>
	);
}
