import axios, { AxiosError, AxiosResponse } from 'axios';
import { apiToken, currentTestUserId } from './testUtils';

export const apiVersion = 'v3';
export const apiPrefix = `/api/${apiVersion}`;

export const baseUrl = `${process.env.REACT_APP_BACKEND_URI}${apiPrefix}`;

export const jsonHeaders = {
	headers: {
		'Content-Type': 'application/json',
	},
};

export const formDataHeaders = {
	headers: {
		'Content-Type': 'multipart/form-data;',
	},
};

export const buildHeaders = (headers: { headers: any }) => ({
	headers: {
		...headers.headers,
		'test-user-id': headers.headers['test-user-id'] ?? currentTestUserId,
	},
});

export const buildExternalHeaders = (headers: { headers: {} }) => ({
	headers: {
		...headers.headers,
		'api-token': apiToken,
	},
});

const handleError = async (
	error: AxiosError,
	url: string,
	errorCallback?: (error?: AxiosError) => void,
) => {
	if (errorCallback) {
		console.log(error, url);
		errorCallback(error);
	} else {
		console.error(error, url);
		throw error;
	}
};

export const safeGet = async (
	url: string,
	headers: { headers: any },
	errorCallback?: (error?: AxiosError) => void,
): Promise<AxiosResponse | undefined> => {
	return await axios
		.get(url, { headers: headers.headers, withCredentials: true })
		.catch(async (error: AxiosError) => {
			await handleError(error, url, errorCallback);
			return undefined;
		});
};

export const safePut = async (
	url: string,
	data: any,
	headers: { headers: any },
	errorCallback?: (error?: AxiosError) => void,
): Promise<AxiosResponse | undefined> => {
	return await axios
		.put(url, data, { headers: headers.headers, withCredentials: true })
		.catch(async (error: AxiosError) => {
			await handleError(error, url, errorCallback);
			return undefined;
		});
};

export const safePost = async (
	url: string,
	data: any,
	headers: { headers: any },
	errorCallback?: (error?: AxiosError) => void,
): Promise<AxiosResponse | undefined> => {
	return await axios
		.post(url, data, { headers: headers.headers, withCredentials: true })
		.catch(async (error: AxiosError) => {
			await handleError(error, url, errorCallback);
			return undefined;
		});
};

export const safeDelete = async (
	url: string,
	headers: { headers: any },
	errorCallback?: (error?: AxiosError) => void,
): Promise<AxiosResponse | undefined> => {
	return await axios
		.delete(url, { headers: headers.headers, withCredentials: true })
		.catch(async (error: AxiosError) => {
			await handleError(error, url, errorCallback);
			return undefined;
		});
};
