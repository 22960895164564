import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ReactNode, createElement } from 'react';
import { universalDateTimeString } from '../../core/utils';
import { EmailCommunication } from '../../shared/classes/talent';

interface ReviewerCommunicationsHistoryProps {
	history?: EmailCommunication[];
}

export function ReviewerCommunicationsHistory({
	history,
}: ReviewerCommunicationsHistoryProps) {
	const addHtmlLineBreaks = (plaintext: string): ReactNode => {
		const elements: ReactNode[] = [];

		plaintext.split(/\r?\n/).forEach((line) => {
			elements.push(line);
			elements.push(createElement('br'));
		});

		return <div>{elements.map((el) => el)}</div>;
	};

	return history && history?.length ? (
		<div className="max-width-1500 margin-50">
			<div>
				{history?.map((email: EmailCommunication) => {
					const sentTime = universalDateTimeString(email.sentAt);

					return (
						<>
							<Accordion>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel1-content"
									id="panel1-header">
									<strong>{sentTime}</strong>&nbsp;-{' '}
									{email.emailTemplate.subject}
								</AccordionSummary>
								<AccordionDetails>
									<div>
										{addHtmlLineBreaks(email.emailTemplate.bodyPlainText!)}
									</div>
								</AccordionDetails>
							</Accordion>
						</>
					);
				})}
			</div>
		</div>
	) : null;
}
