import { Fragment } from 'react';
import { ProfilePreviewSectionNamesEnum } from '../../core/enums';
import { ScrumMaturity } from '../../shared/classes/talent';
import useScrumMaturityZustand from '../../zustand/ScrumMaturityZustand';
import GetInTouchButton from '../SmButtons/GetInTouchButton';
import './InsightsBox.css';
import OutlinedBox from './OutlinedBox';
import ScrumMaturityGraph from './ScrumMaturityGraph';

interface InsightsBoxProps {
	scrumMaturity: ScrumMaturity;
	isRecruiter: boolean;
	successManagerNotes?: string;
}

export default function InsightsBox({
	scrumMaturity,
	isRecruiter,
	successManagerNotes,
}: InsightsBoxProps) {
	const { scrumMaturityLookup } = useScrumMaturityZustand();
	const maturityIndicatorSection = (
		<div>
			<h4>Scrum Master maturity</h4>
			<ScrumMaturityGraph maturity={scrumMaturity} />
		</div>
	);

	const NewlineString = ({ text }: { text: string }) => {
		const textWithBreaks = text.split('\n').map((line, index) => (
			<Fragment key={index}>
				{line}
				{index < text.split('\n').length - 1 && <br />}{' '}
				{/* Add <br/> except for the last line */}
			</Fragment>
		));

		return <div>{textWithBreaks}</div>;
	};

	const advisorNotesSection = successManagerNotes ? (
		<>
			<h4>Advisor notes</h4>
			<NewlineString text={successManagerNotes} />
		</>
	) : null;

	return (
		<OutlinedBox
			title={ProfilePreviewSectionNamesEnum.SHOULD_HIRE}
			classes="sm-profile-preview-pink-outline-box sm-profile-preview-insights-box"
			slot={
				<div>
					{scrumMaturityLookup[scrumMaturity].shouldHireNode}
					{maturityIndicatorSection}
					{/* <h4>Employer maturity</h4>
					<EmployerScrumMaturityGraph isRecruiter={isRecruiter} /> */}
					{advisorNotesSection}
					<br />
					<br />
					<div className="margin-bottom-20-media-919">
						<GetInTouchButton isRecruiter={isRecruiter} />
					</div>
				</div>
			}
		/>
	);
}
