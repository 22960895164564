import {
	Alert,
	Checkbox,
	FormControlLabel,
	Snackbar,
	SxProps,
} from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	acceptRecruiterTerms,
	getRecruiterTermsChangeReasons,
	hasAcceptedRecruiterTerms,
	loginRecruiter,
} from '../../api/recruiters';
import { COOKIE_CONFIG, SM_AUTH_COOKIE } from '../../core/consts';
import { CookieUserTypeEnum, PathsEnum } from '../../core/enums';
import { RecruiterTermsChangeReasons } from '../../core/types';
import { buildSmAuthCookie } from '../../core/utils';
import { Profile } from '../../shared/classes/profile';
import { SmLocation } from '../../shared/classes/smLocation';
import { LoginProviderEnum } from '../../shared/classes/talent';
import useRecruiterProfileZustand from '../../zustand/RecruiterProfileZustand';
import '../SmForms/SmFormComponents/SmFormComponents.css';
import './RecruiterLoginForm.css';
import ErrorPinkBox from './SmFormComponents/ErrorPinkBox';
import SmLogin from './SmFormComponents/SmLogin';
import SmTextField from './SmFormComponents/SmTextField';
import SmLocationAutocomplete from './SmFormComponents/SmWorkLocationAutocomplete';
import TosChangeReasonAlert from './SmFormComponents/TosChangeReasonAlert';

interface ReviewerLoginFormProps {
	isSignup?: boolean;
}

export default function RecruiterLoginForm({
	isSignup = false,
}: ReviewerLoginFormProps) {
	const [, setCookies, removeCookie] = useCookies([SM_AUTH_COOKIE]);
	const [agreed, setAgreed] = useState(false);
	const [showTermsOfServiceCheckbox] = useState(isSignup);
	const [showTosChangeReasonAlert, setShowTosChangeReasonAlert] =
		useState(false);
	const [showErrorMessage, setShowErrorMessage] = useState(false);
	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
	const [disableTermsOfServiceCheckbox, setDisableTermsOfServiceCheckbox] =
		useState(false);
	const [errorSnackbarText] = useState('Something went wrong.');
	const [termsChangeReasons, setTermsChangeReasons] = useState<
		RecruiterTermsChangeReasons | undefined
	>(undefined);
	const { recruiterProfile, setRecruiterProfile } =
		useRecruiterProfileZustand();
	const navigate = useNavigate();
	const location = useLocation();

	const isLoginDisabled =
		!agreed && showTermsOfServiceCheckbox && !showTosChangeReasonAlert;

	const recruiterTermsCheckbox = (disabled: boolean) => {
		return (
			<div className="flex-column">
				<div className="terms-of-service-checkbox-label">
					<FormControlLabel
						disabled={disabled}
						control={
							<Checkbox onChange={(event) => setAgreed(event.target.checked)} />
						}
						label={
							<p className="sm-checkbox-label">
								<span>I accept the </span>
								<a
									href={PathsEnum.RECRUITER_TERMS_OF_USE}
									target="_blank"
									rel="noreferrer">
									terms of use
								</a>
								<span>, </span>

								<a
									href={PathsEnum.PRIVACY_POLICY}
									target="_blank"
									rel="noreferrer">
									privacy policy
								</a>
								<span> and </span>
								<a
									href={PathsEnum.COOKIE_POLICY}
									target="_blank"
									rel="noreferrer">
									cookie policy
								</a>
								<span>.</span>
							</p>
						}
					/>
				</div>
			</div>
		);
	};

	const handleLogin = async (password: string) => {
		try {
			await loginRecruiter(password);
			if (agreed) {
				await acceptRecruiterTerms();
			}
			if (await hasAcceptedRecruiterTerms()) {
				navigate({
					pathname: PathsEnum.RECRUITER_HOME,
					search: location.search,
				});
			} else {
				const changeReasons = await getRecruiterTermsChangeReasons();
				setTermsChangeReasons(changeReasons);
				setShowTosChangeReasonAlert(true);
			}
		} catch (error) {
			setShowErrorMessage(true);
			removeCookie(SM_AUTH_COOKIE, COOKIE_CONFIG);
		}
	};

	const handleScrumMatchLoginSuccess = async (
		access_token: string,
		password: string,
	) => {
		if (!access_token) {
			return;
		}
		const tokens = { access_token };
		setCookies(
			SM_AUTH_COOKIE,
			buildSmAuthCookie(
				LoginProviderEnum.SCRUMMATCH,
				CookieUserTypeEnum.RECRUITER,
				tokens,
			),
			COOKIE_CONFIG,
		);
		await handleLogin(password);
	};

	const handleChange = (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => {
		const name = event.target.name as keyof Profile;
		const value = event.target.value;
		setRecruiterProfile({
			...recruiterProfile!,
			[name]: value,
		});
	};

	const handleHiringLocationChange = (locations: SmLocation[]) => {
		setRecruiterProfile({ ...recruiterProfile!, hiringLocations: locations });
	};

	const recruiterProfileFields = (disabled: boolean) => {
		const style: SxProps = { maxWidth: '420px', width: '90%' };
		if (disabled) setDisableTermsOfServiceCheckbox(disabled);
		return (
			<>
				<SmTextField
					disabled={isLoginDisabled || disabled}
					required
					name="firstName"
					label="First name"
					variant="outlined"
					onChange={handleChange}
					value={recruiterProfile?.firstName}
					sx={{
						...style,
						margin: '0 auto 40px',
					}}
				/>
				<SmTextField
					disabled={isLoginDisabled || disabled}
					required
					name="surnames"
					label="Surnames"
					variant="outlined"
					onChange={handleChange}
					value={recruiterProfile?.surnames}
					sx={{
						...style,
						margin: '0 auto 20px',
					}}
				/>
				<SmTextField
					disabled={isLoginDisabled || disabled}
					required
					name="companyName"
					label="Company name"
					variant="outlined"
					onChange={handleChange}
					value={recruiterProfile?.companyName}
					sx={{
						...style,
						margin: '20px auto',
					}}
				/>
				<SmTextField
					disabled={isLoginDisabled || disabled}
					required
					name="companyPhone"
					label="Your work phone number"
					variant="outlined"
					type="tel"
					onChange={handleChange}
					value={recruiterProfile?.companyPhone}
					sx={{
						...style,
						margin: '20px auto -3px',
					}}
					helperText="Please include country code, i.e. +1 for the US."
				/>
				<SmTextField
					disabled={isLoginDisabled || disabled}
					required
					name="companyWebsite"
					label="Company website"
					variant="outlined"
					onChange={handleChange}
					value={recruiterProfile?.companyWebsite}
					sx={{
						...style,
						margin: '20px auto',
					}}
				/>
				<SmLocationAutocomplete
					disabled={isLoginDisabled || disabled}
					required={
						!recruiterProfile?.hiringLocations ||
						recruiterProfile.hiringLocations.length === 0
					}
					label="Where do you want to hire?"
					value={recruiterProfile?.hiringLocations ?? []}
					onChange={handleHiringLocationChange}
					sx={{
						...style,
						margin: '12px auto 32px',
					}}
					notched={true}
					placeholder="Add as many as you like."
				/>
			</>
		);
	};

	const handleAcceptChangedTos = async () => {
		await acceptRecruiterTerms();
		setShowTosChangeReasonAlert(false);
	};

	return (
		<div className="flex-column flex-center">
			<h2 className="font-size-responsive-heading text-align-center">
				{isSignup ? (
					<>
						Sign-up and hire true&#160;Scrum&#160;Masters
						<br />
						who&#160;actually deliver&#160;value!
					</>
				) : (
					'Recruiter login'
				)}
			</h2>

			<>
				{showTermsOfServiceCheckbox
					? recruiterTermsCheckbox(disableTermsOfServiceCheckbox)
					: null}

				{showTosChangeReasonAlert ? (
					<TosChangeReasonAlert
						handleAcceptTerms={handleAcceptChangedTos}
						termChangeReason={termsChangeReasons}
					/>
				) : null}

				<SmLogin
					disabled={isLoginDisabled}
					handleScrumMatchLoginSuccess={handleScrumMatchLoginSuccess}
					isSignup={isSignup}
					additionalFields={recruiterProfileFields}
				/>

				{showErrorMessage ? (
					<ErrorPinkBox
						errorNode={
							isSignup ? (
								<p>
									Something went wrong. Please contact support at{' '}
									<a
										href="mailto:support@scrummatch.com"
										target="_blank"
										rel="noreferrer">
										support@scrummatch.com
									</a>
								</p>
							) : (
								<p>
									We couldn't find your user,{' '}
									<a href={PathsEnum.RECRUITER_SIGN_UP}>please go to sign-up</a>{' '}
									to create an account.
								</p>
							)
						}
					/>
				) : null}

				<div className="sm-info-text">
					{isSignup ? (
						<p>
							ALREADY HAVE AN ACCOUNT?&nbsp;
							<a href={PathsEnum.RECRUITER_LOGIN}>LOGIN HERE</a>
						</p>
					) : (
						<p>
							DON'T HAVE AN ACCOUNT YET?&nbsp;
							<a href={PathsEnum.RECRUITER_SIGN_UP}>SIGN UP HERE</a>
						</p>
					)}
					<p>NEED HELP? CONTACT SUPPORT@SCRUMMATCH.COM</p>
				</div>
			</>

			<Snackbar
				open={showErrorSnackbar}
				onClose={() => setShowErrorSnackbar(false)}>
				<Alert
					onClose={() => setShowErrorSnackbar(false)}
					severity="error"
					sx={{ width: '100%' }}>
					{errorSnackbarText}
				</Alert>
			</Snackbar>
		</div>
	);
}
