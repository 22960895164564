import Footer from '../components/LayoutComponents/Footer';
import Navbar from '../components/LayoutComponents/Navbar';
import Sidebar from '../components/LayoutComponents/Sidebar';
import TalentSidebarMenu from '../components/LayoutComponents/SidebarComponents/TalentSidebarMenu';
import { ReactNode } from 'react';

interface ProfileLayoutProps {
	content: ReactNode;
	navbar?: ReactNode;
	footer?: ReactNode;
}

export default function ProfileLayout({
	content,
	navbar = (
		<Navbar navbarMenu={<Sidebar sidebarMenu={<TalentSidebarMenu />} />} />
	),
	footer = <Footer />,
}: ProfileLayoutProps) {
	return (
		<div className="sm-profile">
			<div className="sm-profile-navbar">{navbar}</div>
			<div className="sm-profile-body">
				<div className="sm-profile-body-form">{content}</div>
			</div>
			{footer ? <div className={'sm-footer'}>{footer}</div> : null}
		</div>
	);
}
