export const toSha256 = async (input: string) => {
	try {
		// Convert the input string to a Uint8Array
		const encoder = new TextEncoder();
		const data = encoder.encode(input);

		// Use the native crypto.subtle.digest method to compute the SHA-256 hash
		const hashBuffer = await crypto.subtle.digest('SHA-256', data);

		// Convert the hash result to a hexadecimal string
		const hashArray = Array.from(new Uint8Array(hashBuffer));
		return hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');
	} catch (error) {
		console.error('Error hashing string:', error);
		throw error;
	}
};

export const generateRandomNumber = (min = 0, max = 1000000000000) => {
	return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const generateRandomNumberPaddedString = (length: number) => {
	const min = 0;
	const max = Math.pow(10, length);
	return generateRandomNumber(min, max).toString().padStart(length, '0');
};
