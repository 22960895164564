import { ScrumMaturity } from '../../shared/classes/talent';
import './ScrumMaturityDisplay.css';
import ScrumMaturityGraph from './ScrumMaturityGraph';

type ScrumMaturityDisplayProps = React.DetailedHTMLProps<
	React.HTMLAttributes<HTMLDivElement>,
	HTMLDivElement
> & {
	scrumMaturity: ScrumMaturity;
};

export default function ScrumMaturityDisplay({
	scrumMaturity,
	className,
	...divProps
}: ScrumMaturityDisplayProps) {
	return (
		<div
			{...divProps}
			className={
				'sm-preferred-white-outline-box sm-scrum-maturity-display-box ' +
				className
			}>
			<h2>Scrum maturity</h2>
			<div style={{ width: '100%' }}>
				<ScrumMaturityGraph maturity={scrumMaturity} />
			</div>
		</div>
	);
}
