import {
	Autocomplete,
	Chip,
	FormControl,
	SxProps,
	Theme,
	createFilterOptions,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Fragment, useEffect, useState } from 'react';
import { findLocations } from '../../../api/locations';
import { SmLocation } from '../../../shared/classes/smLocation';
import SmTextField from './SmTextField';

interface SmWorkLocationAutocompleteProps {
	label: string;
	value: SmLocation[];
	onChange: (value: SmLocation[]) => void;
	required: boolean;
	containerClass?: string;
	sx?: SxProps<Theme>;
	notched?: boolean;
	disabled?: boolean;
	placeholder?: string;
}
export default function SmWorkLocationAutocomplete({
	label,
	value,
	onChange,
	required,
	containerClass = '',
	sx,
	notched = false,
	disabled = false,
	placeholder,
}: SmWorkLocationAutocompleteProps) {
	enum PopOutTextEnum {
		LOADING = 'Loading...',
		NONE_FOUND = 'No locations found.',
		TYPE_MORE = 'Type more to see locations.',
	}

	const [inputValue, setInputValue] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [options, setOptions] = useState<SmLocation[]>([]);
	const [noOptionsText, setNoOptionsText] = useState(PopOutTextEnum.TYPE_MORE);
	const [searchDelayValue, setSearchDelayValue] = useState('');

	useEffect(() => {
		if (noOptionsText === PopOutTextEnum.LOADING && options.length === 0) {
			setIsLoading(true);
		} else {
			setIsLoading(false);
		}
	}, [PopOutTextEnum.LOADING, isLoading, noOptionsText, options]);

	useEffect(() => {
		const fetchOptions = async () => {
			const cities: SmLocation[] = await findLocations(inputValue);
			return cities.slice(0, 50);
		};
		if (inputValue.length > 1) {
			setOptions([]);
			setNoOptionsText(PopOutTextEnum.LOADING);
			setTimeout(() => {
				setSearchDelayValue(inputValue);
			}, 500);
			if (inputValue === searchDelayValue) {
				fetchOptions().then((newOptions) => {
					setOptions(newOptions);
					if (newOptions.length === 0) {
						setNoOptionsText(PopOutTextEnum.NONE_FOUND);
					}
				});
			}
		}
	}, [
		PopOutTextEnum.LOADING,
		PopOutTextEnum.NONE_FOUND,
		inputValue,
		searchDelayValue,
		value,
	]);

	useEffect(() => {
		if (inputValue.length < 2) {
			setNoOptionsText(PopOutTextEnum.TYPE_MORE);
		}
	}, [options, inputValue, PopOutTextEnum.TYPE_MORE]);

	const filterOptions = createFilterOptions({
		trim: true,
		stringify: (opt: SmLocation) => opt.label,
	});

	return (
		<FormControl
			className={`${notched ? '' : 'sm-label '}${containerClass}`}
			sx={{ minWidth: 300, ...sx }}>
			<Autocomplete
				clearOnBlur={false}
				multiple
				disabled={disabled}
				id={label.toLowerCase().replace(/ /g, '-')}
				options={options}
				noOptionsText={noOptionsText}
				getOptionLabel={(opt: SmLocation) => opt.label}
				filterOptions={filterOptions}
				onChange={(_, val) => onChange(val)}
				value={value}
				renderTags={(value, getTagProps) =>
					value.map((option, index) => (
						<Chip
							label={typeof option === 'string' ? option : option.label}
							{...getTagProps({ index })}
							className={`${
								getTagProps({ index }).className
							} sm-multiline-chip`}
						/>
					))
				}
				inputValue={inputValue}
				onInputChange={(_, newInputValue) => {
					setInputValue(newInputValue);
				}}
				popupIcon={false}
				renderInput={(params) => (
					<SmTextField
						className={
							'sm-profile-form-single-line-input-field sm-input-label sm-show-placeholder'
						}
						required={required}
						{...params}
						label={label}
						SelectProps={{ notched }}
						InputLabelProps={{ shrink: notched, className: 'sm-label-bold' }}
						type="text"
						disabled={disabled}
						placeholder={placeholder}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<Fragment>
									{isLoading ? (
										<CircularProgress color="inherit" size={20} />
									) : null}
									{params.InputProps.endAdornment}
								</Fragment>
							),
						}}
					/>
				)}
			/>
		</FormControl>
	);
}
