import { create } from 'zustand';
import { EmployerTalentFilter } from '../shared/classes/employerTalentFilter';

interface EmployerTalentFilterZustandType {
	filter: EmployerTalentFilter;
	setFilter: (filter: EmployerTalentFilter) => void;
}

const useEmployerTalentFilterZustand = create<EmployerTalentFilterZustandType>(
	(set) => ({
		filter: new EmployerTalentFilter(),
		setFilter: (filter: EmployerTalentFilter) => {
			set({ filter });
		},
	}),
);

export default useEmployerTalentFilterZustand;
