import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PathsEnum } from '../../core/enums';
import {
	canSubmitProfile,
	canUpdateProfile,
} from '../../shared/classes/profile';
import useProfileZustand from '../../zustand/ProfileZustand';
import './ScrumMatchIntro.css';

export default function ScrumMatchIntro() {
	const navigate = useNavigate();
	const { profile, fetchProfileStatus } = useProfileZustand();
	const [profileStatus, setProfileStatus] = useState(profile?.status);

	useEffect(() => {
		if (!profile) {
			fetchProfileStatus().then((status) => {
				setProfileStatus(status);
			});
		} else {
			setProfileStatus(profile.status);
		}
	}, [fetchProfileStatus, profile]);

	return (
		<div className={'sm-scrummatch-intro'}>
			<h1>How ScrumMatch works</h1>
			<p>
				<strong>1: Create your Scrum Master profile:</strong> Your profile is
				the first thing employers will see about you. They can see an anonymized
				version of your profile without your personally identifiable
				information. When you talk to an employer, they can see your full
				profile.
			</p>
			<p>
				<strong>2: Complete the ScrumMatch review and get feedback:</strong> As
				Scrum practitioners, transparency is important to us. That is why we
				want to give you and employers a transparent view of your Scrum
				maturity. Our Scrum professionals will review your profile and your
				Scrum maturity through an interview and a case study. They will also
				give you personal career advice on what you need to do to increase your
				Scrum maturity.
			</p>
			<p>
				<strong>3: Become visible and get interviews with employers:</strong>{' '}
				Once you have passed the review and received feedback, your profile will
				become visible to employers. Employers can contact you and invite you to
				an interview, and you can contact employers and invite them to talk.
			</p>
			<p>
				<strong>4: Meet employers and get job offers:</strong> If you and an
				employer agree to meet, you will have an interview with them. If this
				goes well, you will get a job offer.
			</p>
			<p>
				<strong>5: Accept the best job offer:</strong> :-)
			</p>
			<div style={{ marginTop: '25px' }} className="flex gap-50">
				{canSubmitProfile(profile?.status) ? (
					<div className="sm-button-container">
						<Button
							onClick={() => navigate(PathsEnum.PROFILE_FORM)}
							variant="gradient"
							className={'sm-scrummatch-intro-button'}>
							Work on your profile
						</Button>
					</div>
				) : (
					<div className={'sm-profile-form-already-submitted'}>
						{canUpdateProfile(profileStatus)
							? null
							: 'You have submitted your profile and it is currently being reviewed.'}
						<div className="flex gap-25">
							<Button
								variant="gradient"
								onClick={() => navigate(PathsEnum.PROFILE)}>
								view submitted profile
							</Button>

							{canUpdateProfile(profileStatus) ? (
								<Button
									onClick={() => navigate(PathsEnum.PROFILE_FORM)}
									variant="gradient-outlined">
									Update your profile
								</Button>
							) : null}
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
