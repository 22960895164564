import { Button, SelectChangeEvent } from '@mui/material';
import { Dayjs } from 'dayjs';
import { ChangeEvent, StrictMode, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	fetchEmailTemplatesForReviewerAndUrlId,
	fetchTalentByUrlIdForReviewer,
	isCurrentUserReviewer,
	updateAnonymizedFieldsByUrlId,
	updateEmailCommunicationsCurrentDraftByUrlId,
	updateEvalDataByUrlId,
	updateStatusByUrlId,
} from '../../api/reviewers';
import Navbar from '../../components/LayoutComponents/Navbar';
import Sidebar from '../../components/LayoutComponents/Sidebar';
import ReviewerSidebarMenu from '../../components/LayoutComponents/SidebarComponents/ReviewerSidebarMenu';
import { ReviewerCommunicationsHistory } from '../../components/ReviewerComponents/ReviewerCommunicationsHistory';
import LoadingButton from '../../components/SmForms/SmFormComponents/LoadingButton';
import SmDialog from '../../components/SmForms/SmFormComponents/SmDialog';
import TalentReviewForm from '../../components/SmForms/TalentReviewForm';
import SmInterceptor from '../../components/SmInterceptor';
import ProfilePreview from '../../components/TalentComponents/ProfilePreview';
import { PathsEnum } from '../../core/enums';
import { findScrumMaturityByValue } from '../../core/lookupUtils';
import ProfileLayout from '../../layouts/ProfileLayout';
import {
	AnonymizedFields,
	ProfileStatusesEnum,
	WorkExperience,
} from '../../shared/classes/profile';
import {
	EmailCommunications,
	EmailTemplate,
	EvalData,
	ScrumMaturity,
	TalentForReviewers,
} from '../../shared/classes/talent';
import useScrumMaturityZustand from '../../zustand/ScrumMaturityZustand';

export default function ReviewerTalentsPage() {
	const urlId = useParams<{ urlId: string }>().urlId;
	const [talent, setTalent] = useState<TalentForReviewers>();
	const [openDialog, setOpenDialog] = useState<boolean>(false);
	const navigate = useNavigate();
	const [isUserReviewer, setIsUserReviewer] = useState(false);
	const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>([]);
	const { scrumMaturityLookup } = useScrumMaturityZustand();

	useEffect(() => {
		window.scrollTo(0, 0);
		isCurrentUserReviewer()
			.then(setIsUserReviewer)
			.catch((error) => console.log('is current user rev. error: ', error));
	}, []);

	const sortEmailTemplates = (templates: EmailTemplate[] | undefined) => {
		if (!templates) {
			return;
		}
		const emailTemplateOrder = templates.map((email) => email.name);
		const emptyTemplate: EmailTemplate = {
			name: '-',
			subject: '',
			bodyHtml: '',
			bodyPlainText: '',
		};
		return emailTemplateOrder.map((templateName) => {
			return (
				templates.find((templ) => templ.name === templateName) ?? emptyTemplate
			);
		});
	};

	useEffect(() => {
		if (urlId) {
			fetchTalentByUrlIdForReviewer(urlId)
				.then((talent) => {
					setTalent({
						...talent!,
						evalData: new EvalData(talent?.evalData),
						emailCommunications: new EmailCommunications(
							talent?.emailCommunications,
						),
					});
				})
				.catch((error) => console.log('get talent by url: ', error));
			fetchEmailTemplatesForReviewerAndUrlId(urlId).then((templates) => {
				const sortedTemplates = sortEmailTemplates(templates);
				setEmailTemplates(sortedTemplates ?? []);
			});
		}
	}, [urlId]);

	const handleEvalNotesChange = (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => {
		setTalent({
			...talent!,
			evalData: {
				...talent!.evalData,
				evalNotes: event.target.value,
			},
		});
	};

	const handleSuccessManagerNotesChange = (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => {
		setTalent({
			...talent!,
			evalData: {
				...talent!.evalData,
				successManagerNotes: event.target.value,
			},
		});
	};

	const handleScrumMaturityChange = (event: SelectChangeEvent<string>) => {
		let scrumMaturity: ScrumMaturity = findScrumMaturityByValue(
			scrumMaturityLookup,
			event.target.value,
		);
		setTalent({
			...talent!,
			evalData: {
				...talent!.evalData,
				scrumMaturity: scrumMaturity,
			},
		});
	};

	const handleStatusChange = (event: SelectChangeEvent<string>) => {
		const value = event.target.value as ProfileStatusesEnum;
		const isValid = Object.values(ProfileStatusesEnum).includes(value);
		if (!isValid) {
			console.error('SM Error: Profile Status is invalid');
			return;
		}
		setTalent({
			...talent!,
			profile: {
				...talent!.profile,
				status: value,
			},
		});
	};

	const handleScheduledEvalDateChange = (event: Dayjs | null) => {
		const date = event?.toISOString();
		setTalent({
			...talent!,
			evalData: {
				...talent!.evalData,
				scheduledEvalDate: date ?? '',
			},
		});
	};

	const handleAnonymizedFieldsChange =
		(fieldName: keyof AnonymizedFields) =>
		(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			setTalent({
				...talent!,
				profile: {
					...talent!.profile,
					anonymizedFields: {
						...talent!.profile.anonymizedFields,
						[fieldName]: event.target.value,
					},
				},
			});
		};

	const handleWorkExperiencesAnonymousChange = (
		newWorkExperiencesAnonymous: WorkExperience[],
	) => {
		setTalent({
			...talent!,
			profile: {
				...talent!.profile,
				anonymizedFields: {
					...talent!.profile.anonymizedFields,
					workExperiencesAnonymous: newWorkExperiencesAnonymous,
				},
			},
		});
	};

	const handleSave = async () => {
		if (urlId && talent?.profile?.status) {
			await updateEvalDataByUrlId(urlId, talent?.evalData);
			await updateStatusByUrlId(urlId, talent?.profile?.status);
			await updateAnonymizedFieldsByUrlId(
				urlId,
				talent?.profile?.anonymizedFields,
			);
			await updateEmailCommunicationsCurrentDraftByUrlId(
				urlId,
				talent?.emailCommunications.currentDraft,
			);
			setOpenDialog(false);
		}
	};

	const handleEmailCommunicationsChange = (
		emailCommunications: EmailCommunications,
	) => {
		setTalent({
			...talent!,
			emailCommunications: emailCommunications,
		});
	};

	return (
		<StrictMode>
			<SmInterceptor redirectTo={PathsEnum.REVIEWER_LOGIN} />
			<ProfileLayout
				navbar={
					<Navbar
						showMenuAlways={true}
						navbarMenu={<Sidebar sidebarMenu={<ReviewerSidebarMenu />} />}
					/>
				}
				content={
					isUserReviewer ? (
						<>
							<ProfilePreview userId={urlId} isEvalPage={true} />
							<div className="margin-50">
								<TalentReviewForm
									talent={talent}
									urlId={urlId}
									handleStatusChange={handleStatusChange}
									handleScheduledEvalDateChange={handleScheduledEvalDateChange}
									handleEvalNotesChange={handleEvalNotesChange}
									handleSuccessManagerNotesChange={
										handleSuccessManagerNotesChange
									}
									handleScrumMaturityChange={handleScrumMaturityChange}
									handleAnonymizedFieldsChange={handleAnonymizedFieldsChange}
									handleWorkExperiencesAnonymousChange={
										handleWorkExperiencesAnonymousChange
									}
									handleEmailCommunicationsChange={
										handleEmailCommunicationsChange
									}
									emailTemplates={emailTemplates}
								/>
								<div className="sm-button-container">
									<LoadingButton
										variant="contained"
										color="secondary"
										disableElevation
										onClick={handleSave}>
										Save
									</LoadingButton>
									<Button
										variant="gradient"
										color="primary"
										onClick={() => setOpenDialog(true)}>
										Save & exit
									</Button>
								</div>
							</div>
							<SmDialog
								open={openDialog}
								onClose={() => setOpenDialog(false)}
								title={<>Save & Exit?</>}
								content={
									<>
										Are you sure you want to save these changes to{' '}
										{talent?.profile?.firstName}'s profile?
										{!!talent?.emailCommunications.currentDraft.bodyPlainText?.trim()
											?.length ? (
											<strong className="text-purple">
												<br />
												<br />
												You haven't sent the email yet!
											</strong>
										) : null}
									</>
								}
								button1Text={'Yes. Save away'}
								button1OnClick={async () => {
									await handleSave();
									navigate(PathsEnum.REVIEWER_HOME);
								}}
								button2Text={'Not yet'}
								button2OnClick={() => setOpenDialog(false)}
							/>
							<ReviewerCommunicationsHistory
								history={talent?.emailCommunications?.history.reverse()}
							/>
						</>
					) : (
						<p style={{ height: '50vh', padding: '50px' }}>Access Denied.</p>
					)
				}
			/>
		</StrictMode>
	);
}
