import SmTextField from './SmTextField';

interface ProfileFormNameProps {
	handleChange: (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => void;
	firstName?: string;
	middleName?: string;
	surnames?: string;
}
export default function NameInput({
	handleChange,
	firstName,
	middleName,
	surnames,
}: ProfileFormNameProps) {
	return (
		<div className="sm-form-name-container">
			<SmTextField
				name="firstName"
				label="First name"
				variant="outlined"
				required
				fullWidth
				onChange={handleChange}
				value={firstName || ''}
				className={'sm-form-half-row'}
			/>
			<SmTextField
				name="middleName"
				label="Middle name"
				variant="outlined"
				fullWidth
				onChange={handleChange}
				value={middleName || ''}
				className={'sm-form-half-row'}
			/>
			<SmTextField
				name="surnames"
				label="Surnames"
				variant="outlined"
				required
				fullWidth
				onChange={handleChange}
				value={surnames || ''}
			/>
		</div>
	);
}
