import './Sidebar.css';

interface SidebarProps {
	sidebarMenu?: JSX.Element;
}

export default function Sidebar({ sidebarMenu }: SidebarProps) {
	const mobileSidebar = (
		<div
			style={{
				width: '100vw',
				height: `${document.body.scrollHeight}px`,
				position: 'absolute',
				right: 0,
				top: 81,
				zIndex: 100,
				maxWidth: '520px',
			}}
			className="sm-sidebar-gradient overflow-scroll not-desktop">
			{sidebarMenu ? sidebarMenu : null}
		</div>
	);

	const desktopSidebar = (
		<div
			style={{ width: '100%', height: '100%' }}
			className="sm-sidebar-gradient overflow-scroll desktop-only">
			{sidebarMenu ? sidebarMenu : null}
		</div>
	);

	return (
		<>
			{mobileSidebar}
			{desktopSidebar}
		</>
	);
}
