import { Alert, Button, Container, Snackbar } from '@mui/material';
import { AxiosResponse } from 'axios';
import { Dayjs } from 'dayjs';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	acceptTermsOfService,
	getReviewResults,
	getTalentTermsChangeReasons,
	hasAcceptedNewestTerms,
	saveProfile,
	submitProfile,
	updateProfile,
} from '../../api/talents';
import { uneditableProfileStatuses } from '../../core/consts';
import {
	PathsEnum,
	ProfileFieldNamesEnum,
	ProfilePreviewSectionNamesEnum,
} from '../../core/enums';
import { TalentTermsChangeReasons } from '../../core/types';
import {
	Profile,
	SalaryExpectations,
	WorkExperience,
	canSaveProfile,
	canSubmitProfile,
	canUpdateProfile,
} from '../../shared/classes/profile';
import { ReviewResults } from '../../shared/classes/talent';
import useProfileZustand from '../../zustand/ProfileZustand';
import SmSpinner from '../SmSpinner';
import './ProfileForm.css';
import ContractTypeAndSalaryExpectationsInput from './SmFormComponents/ContractTypeAndSalaryExpectationsInput';
import LoadingButton from './SmFormComponents/LoadingButton';
import NameInput from './SmFormComponents/NameInput';
import SmChipInput from './SmFormComponents/SmChipInput';
import SmCurrentLocationAutocomplete from './SmFormComponents/SmCurrentLocationAutocomplete';
import SmDialog from './SmFormComponents/SmDialog';
import SmHeading from './SmFormComponents/SmHeading';
import SmLanguageAutocomplete from './SmFormComponents/SmLanguagesAutocomplete';
import SmNumberField from './SmFormComponents/SmNumberField';
import SmTextArea from './SmFormComponents/SmTextArea';
import SmTextField from './SmFormComponents/SmTextField';
import SmWorkExperiencesInput from './SmFormComponents/SmWorkExperiencesInput';
import SmWorkLocationAutocomplete from './SmFormComponents/SmWorkLocationAutocomplete';
import { StartDateInput } from './SmFormComponents/StartDateInput';
import TosChangeReasonAlert from './SmFormComponents/TosChangeReasonAlert';
import WorkModelInput from './SmFormComponents/WorkModelInput';
import WorkPermitInput from './SmFormComponents/WorkPermitInput';

const characterLimits = {
	maxIntro: 900,
	minIntro: 50,
	maxEvalAnswer: 900,
	minEvalAnswer: 100,
	maxPersonalInterests: 200,
};

export default function ProfileForm() {
	const [showSubmitSuccessSnackbar, setShowSubmitSuccessSnackbar] =
		useState(false);
	const [showSubmitErrorSnackbar, setShowSubmitErrorSnackbar] = useState(false);
	const [showUpdateSuccessSnackbar, setShowUpdateSuccessSnackbar] =
		useState(false);
	const navigate = useNavigate();
	const [pendingEducation, setPendingEducation] = useState('');
	const [pendingCertification, setPendingCertification] = useState('');
	const [showSubmissionConfirmation, setShowSubmissionConfirmation] =
		useState(false);
	const [showUpdateConfirmation, setShowUpdateConfirmation] = useState(false);
	const { profile, setProfile, loadMyProfile, loadAndInitializeMyProfile } =
		useProfileZustand();
	const [reviewResults, setReviewResults] = useState<
		ReviewResults | undefined
	>();
	const [agreedNewestTerms, setAgreedNewestTerms] = useState(false);
	const [termsChangeReasons, setTermsChangeReasons] = useState<
		TalentTermsChangeReasons | undefined
	>(undefined);
	const isInitialized = useRef(false);

	useEffect(() => {
		if (profile?.status && uneditableProfileStatuses.includes(profile.status)) {
			navigate(PathsEnum.HOME);
		}
	}, [profile?.status, navigate]);

	useEffect(() => {
		if (!isInitialized.current) {
			isInitialized.current = true;
			loadAndInitializeMyProfile();
			getReviewResults()
				.then((results) =>
					setReviewResults(results ? new ReviewResults(results) : undefined),
				)
				.catch((error) => console.log('review results error 1: ', error));
			hasAcceptedNewestTerms().then(setAgreedNewestTerms);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!agreedNewestTerms) {
			getTalentTermsChangeReasons().then(setTermsChangeReasons);
		}
	}, [agreedNewestTerms]);

	const handleChange = (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => {
		const name = event.target.name as keyof Profile;
		const value = event.target.value;
		setProfile({
			...profile!,
			[name]: value,
		});
	};

	const handleSalaryExpectationsChange = (
		newSalaryExpectations: SalaryExpectations,
	) => {
		setProfile({ ...profile!, salaryExpectations: newSalaryExpectations });
	};

	const handleContractTypeChange = (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		checked: boolean,
	) => {
		const attribute = event.target.name;
		setProfile({
			...profile!,
			contractType: {
				...profile!.contractType,
				[attribute]: checked,
			},
		});
	};

	const handleWorkModelChange = (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		checked: boolean,
	) => {
		const attribute = event.target.name;
		setProfile({
			...profile!,
			workModel: {
				...profile!.workModel,
				[attribute]: checked,
			},
		});
	};

	const handleScrumExperienceInYearsChange = (value: number | null) => {
		setProfile({
			...profile!,
			scrumExperienceInYears: value,
		});
	};

	const handleStringPropertyChange = (
		propertyName: keyof Profile,
		value: string,
	) => {
		setProfile({
			...profile!,
			[propertyName]: value,
		});
	};

	const validateChip = (
		newChip: string,
	): { isError: boolean; textError: string } => {
		return {
			isError: !newChip || newChip.length < 1 || newChip.includes(';'),
			textError: 'Cannot be empty or contain ";"',
		};
	};

	const addChipToString = (
		chips: string | undefined,
		newChip: string,
	): string => {
		if (!chips) {
			return newChip;
		}
		return `${chips};${newChip}`;
	};

	const addPendingChips = (profileToEdit: Profile): Profile => {
		if (!validateChip(pendingEducation).isError) {
			profileToEdit.education = addChipToString(
				profileToEdit.education,
				pendingEducation,
			);
		}
		if (!validateChip(pendingCertification).isError) {
			console.log('pendingCertification: ', pendingCertification);
			profileToEdit.certifications = addChipToString(
				profileToEdit.certifications,
				pendingCertification,
			);
		}
		return profileToEdit;
	};

	const handleWorkPermitChange = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		checked: boolean,
	) => {
		const attribute = event.target.name;
		setProfile({
			...profile!,
			workPermit: {
				haveIt: false,
				wouldNeedIt: false,
				dontNeedIt: false,
				[attribute]: checked,
			},
		});
	};

	const handleStartDateChange = (value: Dayjs | null): void => {
		if (!value) {
			return;
		}
		try {
			const startDateValue = value?.toISOString() ?? '';
			setProfile({
				...profile!,
				startDate: startDateValue,
			});
		} catch (e) {}
	};

	const handleWorkExperiencesChange = (value: WorkExperience[]) => {
		setProfile({ ...profile!, workExperiences: value });
	};

	const handlePressSubmitButton = async (
		event: React.FormEvent<HTMLFormElement>,
	) => {
		event.preventDefault();
		if (canUpdateProfile(profile?.status)) {
			setShowUpdateConfirmation(true);
		} else if (canSubmitProfile(profile?.status)) {
			setShowSubmissionConfirmation(true);
		}
	};

	const handleSave = async () =>
		await prepareProfileAndSave(saveProfile, () =>
			setShowSubmitSuccessSnackbar(true),
		);

	const handleSubmit = async () =>
		prepareProfileAndSave(
			async (profile: Profile) => {
				await saveProfile(profile);
				return await submitProfile();
			},
			() => setShowSubmitSuccessSnackbar(true),
		);

	const handleUpdate = async () =>
		await prepareProfileAndSave(updateProfile, () =>
			setShowUpdateSuccessSnackbar(true),
		);

	const prepareProfileAndSave = async (
		saveCallback: (profile: Profile) => Promise<AxiosResponse | undefined>,
		successCallback: () => void,
	) => {
		let profileToSave = { ...profile! };
		if (!profileToSave) {
			return;
		}
		addPendingChips(profileToSave);
		if (await hasAcceptedNewestTerms()) {
			const result = await saveCallback(profileToSave);
			if (result) {
				successCallback();
				await loadMyProfile();
				return true;
			} else {
				setShowSubmitErrorSnackbar(true);
				return false;
			}
		} else {
			setAgreedNewestTerms(false);
			return false;
		}
	};

	const handleTermsAcceptance = async () => {
		await acceptTermsOfService();
		setAgreedNewestTerms(true);
	};

	const anonymizationMessage =
		' We will anonymize this for the anonymous version of your profile.';

	return (
		<Container className="container">
			<h1>Profile</h1>
			{!profile ? (
				<SmSpinner />
			) : (
				<form className="sm-form" onSubmit={handlePressSubmitButton}>
					<NameInput
						handleChange={handleChange}
						firstName={profile?.firstName}
						middleName={profile?.middleName}
						surnames={profile?.surnames}
					/>
					<SmTextField
						required
						name="tagLine"
						label={ProfileFieldNamesEnum.SLOGAN}
						variant="outlined"
						fullWidth
						onChange={handleChange}
						placeholder="This is me as a Scrum Master in one line."
						value={profile?.tagLine || ''}
					/>

					<div className="sm-profile-form-work-with-me-container">
						<SmHeading
							title={ProfilePreviewSectionNamesEnum.SCRUM_MASTER_INFORMATION}
						/>
						<SmTextArea
							id="about-me"
							required={true}
							label={ProfileFieldNamesEnum.INTRODUCTION}
							subHeading={
								<>
									<p>
										This is the first impression employers will get about you.
										Consider telling them a bit about your background and how
										you became a Scrum Master.
									</p>
									<p>{anonymizationMessage}</p>
									<br />
								</>
							}
							value={profile?.intro}
							name="intro"
							handleChange={handleChange}
							maxCharacters={characterLimits.maxIntro}
							minCharacters={characterLimits.minIntro}
						/>

						<SmWorkExperiencesInput
							workExperiences={profile?.workExperiences}
							onChange={handleWorkExperiencesChange}
							containerStyle={{ marginBottom: '35px' }}
							showExtraInfo={true}
						/>

						<SmWorkLocationAutocomplete
							label={ProfileFieldNamesEnum.PREFERRED_WORK_LOCATIONS}
							value={profile.preferredWorkLocations ?? []}
							onChange={(value) => {
								setProfile({ ...profile, preferredWorkLocations: value });
							}}
							required={
								!profile.preferredWorkLocations ||
								profile.preferredWorkLocations.length === 0
							}
							notched={true}
							sx={{ marginTop: '14px' }}
						/>
						<WorkPermitInput
							handleChange={handleWorkPermitChange}
							workPermit={profile?.workPermit}
						/>

						<ContractTypeAndSalaryExpectationsInput
							handleContractTypeChange={(event, checked) =>
								handleContractTypeChange(event, checked)
							}
							contractType={profile?.contractType}
							salaryExpectations={profile?.salaryExpectations}
							handleSalaryExpectationsChange={handleSalaryExpectationsChange}
						/>

						<WorkModelInput
							handleChange={handleWorkModelChange}
							workModel={profile?.workModel}
						/>

						<StartDateInput
							startDate={profile?.startDate!}
							handleChange={handleStartDateChange}
							required={true}
							notched={true}
							sx={{ marginTop: '4px' }}
						/>

						<SmLanguageAutocomplete
							label={ProfileFieldNamesEnum.WORK_LANGUAGES}
							value={profile.preferredWorkLanguages ?? []}
							onChange={(value) => {
								setProfile({ ...profile, preferredWorkLanguages: value });
							}}
							required={
								!profile.preferredWorkLanguages ||
								profile.preferredWorkLanguages.length === 0
							}
							notched={true}
							sx={{ marginTop: '10px' }}
						/>

						<SmNumberField
							label={ProfileFieldNamesEnum.SCRUM_EXPERIENCE}
							value={profile?.scrumExperienceInYears}
							handleChange={handleScrumExperienceInYearsChange}
							name="scrumExperienceInYears"
							required={true}
							notched={true}
						/>

						<SmChipInput
							handleChange={(value) =>
								handleStringPropertyChange('education', value)
							}
							value={profile?.education}
							validateChip={validateChip}
							inputValue={pendingEducation}
							setInputValue={setPendingEducation}
							// placeholder="Enter a description (e.g. BA in Communication, XYZ University) and press enter."
							label={ProfileFieldNamesEnum.EDUCATION}
							required={false}
							notched={true}
						/>

						<SmChipInput
							handleChange={(value) =>
								handleStringPropertyChange('certifications', value)
							}
							value={profile?.certifications}
							validateChip={validateChip}
							inputValue={pendingCertification}
							setInputValue={setPendingCertification}
							// placeholder="Enter a certification (e.g. PSM II) and press enter."
							label={ProfileFieldNamesEnum.CERTIFICATIONS}
							required={false}
							notched={true}
						/>

						<SmTextField
							value={profile?.socialMediaLink ?? ''}
							name="socialMediaLink"
							label={`Link to your social media or website (recommended)`}
							variant="outlined"
							fullWidth
							onChange={handleChange}
							placeholder="e.g. LinkedIn, GitHub, personal website"
						/>

						<SmTextField
							value={profile?.referenceContact ?? ''}
							name="referenceContact"
							label={`Reference contact (recommended)`}
							variant="outlined"
							fullWidth
							onChange={handleChange}
							placeholder="e.g. name, email, phone number of a reference contact"
						/>

						<SmCurrentLocationAutocomplete
							label={ProfileFieldNamesEnum.CURRENT_LOCATION}
							value={profile.currentLocation ?? ''}
							onChange={(value) => {
								setProfile({ ...profile, currentLocation: value });
							}}
							required={
								!profile.currentLocation ||
								profile.currentLocation.label.length === 0
							}
							notched={true}
							sx={{ marginTop: '10px' }}
						/>

						<SmTextArea
							id="personal-interests"
							label={ProfileFieldNamesEnum.PERSONAL_INTERESTS}
							subHeading={<p>{anonymizationMessage}</p>}
							value={profile?.personalInterests}
							name="personalInterests"
							handleChange={handleChange}
							maxCharacters={characterLimits.maxPersonalInterests}
							rows={6}
						/>

						<div style={{ height: 50 }} />

						<h1>Review</h1>

						<SmHeading
							title={ProfileFieldNamesEnum.DELIVERING_VALUE}
							marginTop="0"
						/>
						<SmTextArea
							id="eval-answer"
							required={true}
							label={'My example'}
							subHeading={
								<>
									<p style={{ margin: 0 }}>
										Describe your practical experience as a Scrum Master.{' '}
										<strong>Use a specific example.</strong> What we are looking
										for is:
									</p>
									<ul>
										<li>
											What you did in the events and activities that happened.
										</li>
										<li>
											The stances you took and why you chose them over
											alternatives.
										</li>
										<li>The Scrum principles you (were) led by.</li>
										<li>
											A clear explanation of the business value generated and
											evidence that supports your claims.
										</li>
									</ul>
									<p>
										We will anonymize your answer for the anonymous version of
										your profile.
									</p>
									<br />
								</>
							}
							value={profile?.evalAnswer}
							name="evalAnswer"
							handleChange={handleChange}
							maxCharacters={characterLimits.maxEvalAnswer}
							minCharacters={characterLimits.minEvalAnswer}
							showCharacterCountExplanation={true}
						/>
					</div>

					{reviewResults?.evalNotes ? (
						<div>
							<h1>Feedback for you</h1>
							<p>{reviewResults.evalNotes}</p>
						</div>
					) : null}

					{agreedNewestTerms ? null : (
						<TosChangeReasonAlert
							handleAcceptTerms={handleTermsAcceptance}
							termChangeReason={termsChangeReasons}
						/>
					)}

					<div className="sm-button-container">
						{canUpdateProfile(profile.status) ? (
							<Button
								type="submit"
								disabled={!agreedNewestTerms}
								variant={'gradient'}>
								Update Profile
							</Button>
						) : (
							<>
								{canSaveProfile(profile.status) ? (
									<LoadingButton
										disabled={!agreedNewestTerms}
										variant={'outlined'}
										onClick={async () => {
											await handleSave();
										}}>
										Save
									</LoadingButton>
								) : null}
								<LoadingButton
									disabled={!agreedNewestTerms}
									variant={'outlined'}
									onClick={async () => {
										const wasSaveSuccessful = await handleSave();
										if (wasSaveSuccessful) {
											navigate(PathsEnum.PROFILE);
										}
									}}>
									Preview
								</LoadingButton>
								{canSubmitProfile(profile.status) ? (
									<Button
										disabled={!agreedNewestTerms}
										variant="contained"
										color="primary"
										type="submit"
										formEncType="multipart/form-data">
										Submit
									</Button>
								) : null}
							</>
						)}
					</div>
					<Snackbar
						open={showSubmitSuccessSnackbar}
						autoHideDuration={6000}
						onClose={() => setShowSubmitSuccessSnackbar(false)}>
						<Alert
							onClose={() => setShowSubmitSuccessSnackbar(false)}
							severity="success"
							sx={{ width: '100%' }}>
							Profile saved successfully.
						</Alert>
					</Snackbar>
					<Snackbar
						open={showUpdateSuccessSnackbar}
						autoHideDuration={6000}
						onClose={() => setShowUpdateSuccessSnackbar(false)}>
						<Alert
							onClose={() => setShowUpdateSuccessSnackbar(false)}
							severity="success"
							sx={{ width: '100%' }}>
							Profile updated successfully.
						</Alert>
					</Snackbar>
					<Snackbar
						open={showSubmitErrorSnackbar}
						onClose={() => setShowSubmitErrorSnackbar(false)}>
						<Alert
							onClose={() => setShowSubmitErrorSnackbar(false)}
							severity="error"
							sx={{ width: '100%' }}>
							There was an error saving your profile.
						</Alert>
					</Snackbar>
				</form>
			)}
			<SmDialog
				open={showSubmissionConfirmation}
				onClose={() => setShowSubmissionConfirmation(false)}
				title={'Submit profile'}
				content={
					<>
						By submitting your profile, you will start your review. Until the
						review is complete, you will not be able to change your profile.
						<br />
						<br />
						<strong>Are you ready to submit your profile now?</strong>
					</>
				}
				button1Text={'Yes'}
				button1OnClick={async () => {
					const wasSubmitSuccessful = await handleSubmit();
					if (wasSubmitSuccessful) {
						navigate(PathsEnum.HOME);
					}
				}}
				button2Text={'Not yet'}
				button2OnClick={() => setShowSubmissionConfirmation(false)}
				showLoadingSpinner={true}
			/>
			<SmDialog
				open={showUpdateConfirmation}
				onClose={() => setShowUpdateConfirmation(false)}
				title={'Update profile'}
				content={
					<>
						These changes will immediately be visible to reviewers and
						employers.
						<br />
						<br />
						<strong>Do you want to update your profile?</strong>
					</>
				}
				button1Text={'Update'}
				button1OnClick={async () => {
					await handleUpdate();
					setShowUpdateConfirmation(false);
				}}
				button2Text={'Cancel'}
				button2OnClick={() => setShowUpdateConfirmation(false)}
				showLoadingSpinner={true}
			/>
		</Container>
	);
}
