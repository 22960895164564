import { Alert, Snackbar } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	fetchTalentForRecruiters,
	fetchTalentsForRecruiters,
	toggleBookmarkTalent,
	toggleHideTalent,
} from '../../api/recruiters';
import '../../components/RecruiterComponents/TalentCard.css';
import { isMatchingTalent } from '../../shared/classes/employerTalentFilter';
import { TalentForRecruiters } from '../../shared/classes/talent';
import useEmployerTalentFilterZustand from '../../zustand/EmployerTalentFilterZustand';
import RecruiterFilters from './RecruiterFilters';
import './RecruiterTalentsList.css';
import TalentCard from './TalentCard';

export default function RecruiterTalentsList() {
	const navigate = useNavigate();
	const { filter } = useEmployerTalentFilterZustand();
	const [talents, setTalents] = useState<TalentForRecruiters[]>([]);
	const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
	const [successSnackbarText, setSuccessSnackbarText] = useState('');
	const [hasAlreadyScrolled, setHasAlreadyScrolled] = useState(false);

	useEffect(() => {
		fetchTalentsForRecruiters().then((talents) => {
			setTalents(talents ?? []);
		});
	}, [navigate]);

	const sortNoSalaryAtTheBottom = (t: TalentForRecruiters) =>
		!!t.talent.profile.salaryExpectations?.hourlyGross ||
		!!t.talent.profile.salaryExpectations?.yearlyGross
			? -1
			: 1;

	const talentsToDisplay: TalentForRecruiters[] = talents
		.filter((talent) => isMatchingTalent(filter, talent))
		.sort(sortNoSalaryAtTheBottom);

	const updateTalent = async (
		talentId: string,
	): Promise<TalentForRecruiters | undefined> => {
		const updatedTalent = await fetchTalentForRecruiters(talentId);
		if (updatedTalent) {
			const updatedTalents = talents.map((talent) =>
				talent.talent.urlId === talentId ? updatedTalent : talent,
			);
			setTalents(updatedTalents);
			return updatedTalent;
		}
	};

	const handleBookmark = async (talentId: string) => {
		try {
			await toggleBookmarkTalent(talentId);
			await updateTalent(talentId);
		} catch (err) {
			console.error('Unable to bookmark talent');
		}
	};

	const handleHide = async (talentId: string) => {
		try {
			await toggleHideTalent(talentId);
			const updatedTalent = await updateTalent(talentId);
			setSuccessSnackbarText(
				updatedTalent?.isHidden ? 'Talent hidden' : 'Talent revealed',
			);
			setShowSuccessSnackbar(true);
		} catch (err) {
			console.error('Unable to hide talent');
		}
	};

	return (
		<>
			<h1 style={{ marginBottom: -10 }}>Find your true Scrum Master:</h1>

			<RecruiterFilters totalShown={talentsToDisplay.length} />
			<div>
				<div className="talent-cards-container">
					{talentsToDisplay ? (
						talentsToDisplay.map((talentInfo) => (
							<TalentCard
								talentInfo={talentInfo}
								key={talentInfo.talent.urlId}
								handleBookmark={handleBookmark}
								handleHide={handleHide}
								hasAlreadyScrolled={hasAlreadyScrolled}
								setHasAlreadyScrolled={setHasAlreadyScrolled}
							/>
						))
					) : (
						<p style={{ fontSize: '20px' }}>
							Check back soon for more talents!
						</p>
					)}
				</div>
			</div>

			<Snackbar
				open={showSuccessSnackbar}
				autoHideDuration={6000}
				onClose={() => setShowSuccessSnackbar(false)}>
				<Alert
					onClose={() => setShowSuccessSnackbar(false)}
					severity="success"
					sx={{ width: '100%' }}>
					{successSnackbarText}
				</Alert>
			</Snackbar>
		</>
	);
}
