import { SmRegionsEnum } from '../enums';
import { allIso3166Countries } from './all-iso3166-countries';

const getRegion = (country: {
	region: string;
	'sub-region': string;
}): SmRegionsEnum => {
	if (country.region === 'Americas') {
		return SmRegionsEnum.AMERICAS;
	} else if (
		country.region === 'Europe' ||
		country.region === 'Africa' ||
		country['sub-region'] === 'Western Asia'
	) {
		return SmRegionsEnum.EMEA;
	} else if (
		(country.region === 'Asia' && country['sub-region'] !== 'Western Asia') ||
		country.region === 'Oceania'
	) {
		return SmRegionsEnum.APAC;
	} else {
		return SmRegionsEnum['N/A'];
	}
};

const generateCountryCodeToRegionMap = (): Record<string, SmRegionsEnum> => {
	const countryToRegionMap: Record<string, SmRegionsEnum> = {};
	allIso3166Countries.forEach((country) => {
		countryToRegionMap[country['alpha-2']] = getRegion(country);
	});
	return countryToRegionMap;
};

export const countryCodeToRegionMap = generateCountryCodeToRegionMap();

const generateRegionToCountriesMap = (): Record<SmRegionsEnum, string[]> => {
	const regionToCountriesMap: Record<SmRegionsEnum, string[]> = {
		[SmRegionsEnum.AMERICAS]: [],
		[SmRegionsEnum.EMEA]: [],
		[SmRegionsEnum.APAC]: [],
		[SmRegionsEnum['N/A']]: [],
	};
	allIso3166Countries.forEach((country) => {
		const region = getRegion(country);
		regionToCountriesMap[region].push(country.name);
	});
	return regionToCountriesMap;
};

export const regionToCountriesMap = generateRegionToCountriesMap();

const generateRegionToCountryCodesMap = (): Record<SmRegionsEnum, string[]> => {
	const regionToCountryCodesMap: Record<SmRegionsEnum, string[]> = {
		[SmRegionsEnum.AMERICAS]: [],
		[SmRegionsEnum.EMEA]: [],
		[SmRegionsEnum.APAC]: [],
		[SmRegionsEnum['N/A']]: [],
	};
	allIso3166Countries.forEach((country) => {
		const region = getRegion(country);
		regionToCountryCodesMap[region].push(country['alpha-2']);
	});
	return regionToCountryCodesMap;
};

export const regionToCountryCodesMap = generateRegionToCountryCodesMap();

export const regionsToCountries = (regions: string[]): string[] => {
	const countries: string[] = [];
	if (regions) {
		regions.forEach((region) => {
			if (regionToCountriesMap[region as SmRegionsEnum]) {
				countries.push(...regionToCountriesMap[region as SmRegionsEnum]);
			}
		});
	}

	return countries;
};

export const regionsToCountryCodes = (regions: string[]): string[] => {
	const countryCodes: string[] = [];
	if (regions) {
		regions.forEach((region) => {
			if (regionToCountryCodesMap[region as SmRegionsEnum]) {
				countryCodes.push(...regionToCountryCodesMap[region as SmRegionsEnum]);
			}
		});
	}

	return countryCodes;
};

export const countryToAlpha2Code = (country: string): string | undefined => {
	const countryObj = allIso3166Countries.find((c) => c.name === country);
	return countryObj?.['alpha-2'];
};
