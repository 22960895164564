import { SearchOutlined } from '@mui/icons-material';
import { MiscTalentFiltersEnum } from '../../core/enums';
import { sortedDropdownLabels } from '../../core/lookupUtils';
import { ProfileStatusesEnum } from '../../shared/classes/profile';
import { SmLocation } from '../../shared/classes/smLocation';
import { SmRegionsEnum } from '../../shared/enums';
import useReviewerTalentFilterZustand from '../../zustand/ReviewerTalentFilterZustand';
import useScrumMaturityZustand from '../../zustand/ScrumMaturityZustand';
import SmCheckboxDropdown from '../SmForms/SmFormComponents/SmCheckboxDropdown';
import SmInput from '../SmForms/SmFormComponents/SmInput';
import SmWorkLocationAutocomplete from '../SmForms/SmFormComponents/SmWorkLocationAutocomplete';
import './ReviewerFilters.css';

interface ReviewerFiltersProps {
	totalResults: number;
	permittedRegions: SmRegionsEnum[];
}

export default function ReviewerFilters({
	totalResults,
	permittedRegions: regions,
}: ReviewerFiltersProps) {
	const { filters, setFilters } = useReviewerTalentFilterZustand();
	const { scrumMaturityLookup } = useScrumMaturityZustand();

	const filterByProfileStatus = (statuses: ProfileStatusesEnum[]) => {
		setFilters({ ...filters, statuses });
	};

	const filterByRegions = (regions: SmRegionsEnum[]) => {
		setFilters({ ...filters, regions });
	};

	const filterByMaturity = (scrumMaturities: string[]) => {
		setFilters({ ...filters, scrumMaturities });
	};

	const filterByMisc = (misc: MiscTalentFiltersEnum[]) => {
		setFilters({ ...filters, misc });
	};

	const filterByNameAndEmail = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => {
		setFilters({ ...filters, nameAndEmail: event.target.value });
	};

	const filterByWorkLocations = (preferredWorkLocations: SmLocation[]) => {
		setFilters({ ...filters, preferredWorkLocations });
	};

	const scrumMaturityItems = sortedDropdownLabels(scrumMaturityLookup);

	return (
		<div className="sm-filters-container">
			<div className="sm-form-inline-container">
				<SmInput
					id={'filterNameAndEmail'}
					placeholder={'Name / email'}
					value={filters.nameAndEmail}
					containerClass="sm-filter-input"
					handleChange={filterByNameAndEmail}
					inputProps={{ endAdornment: <SearchOutlined /> }}
				/>
			</div>
			<div className="sm-form-inline-container sm-drop-filter-container sm-form-inline-recruiter-container">
				<SmCheckboxDropdown
					items={Object.values(ProfileStatusesEnum)}
					value={filters.statuses}
					label="Status"
					handleChange={(values: string[]) =>
						filterByProfileStatus(values as ProfileStatusesEnum[])
					}
				/>
				<SmCheckboxDropdown
					items={regions}
					value={filters.regions}
					label="Region"
					handleChange={(values) => filterByRegions(values as SmRegionsEnum[])}
				/>
			</div>
			<div className="sm-form-inline-container sm-drop-filter-container sm-form-inline-recruiter-container">
				<SmCheckboxDropdown
					items={scrumMaturityItems}
					value={filters.scrumMaturities}
					label="Scrum maturity"
					handleChange={(values) => filterByMaturity(values)}
				/>
				<SmCheckboxDropdown
					items={Object.values(MiscTalentFiltersEnum)}
					value={filters.misc}
					label={'Miscellaneous'}
					handleChange={(values) =>
						filterByMisc(values as MiscTalentFiltersEnum[])
					}
				/>
			</div>
			<div className="sm-form-inline-container">
				<SmWorkLocationAutocomplete
					label="Work location"
					required={false}
					containerClass="sm-filter-input"
					value={filters.preferredWorkLocations}
					onChange={(values) => filterByWorkLocations(values)}
				/>
			</div>
			<p className="sm-reviewer-filters-result">
				<strong>Total results: </strong>
				{totalResults}
			</p>
		</div>
	);
}
