import { StrictMode, useEffect, useState } from 'react';
import {
    activateMyProfile,
    getMyRegion,
    hasSubmittedBeforePwyw,
} from '../../api/talents';
import TalentLoginForm from '../../components/SmForms/TalentLoginForm';
import { PathsEnum } from '../../core/enums';
import SidebarLayout from '../../layouts/SidebarLayout';

export default function TalentProfileActivatePage() {
	const [showActivationLogin, setShowActivationLogin] = useState(false);

	useEffect(() => {
		attemptActivation();
	}, []);

	const attemptActivation = async () => {
		try {
			const res = await activateMyProfile();
			if (res?.status === 200) {
				const region = await getMyRegion().catch((_err) => undefined);
				const isBeforePwyw = await hasSubmittedBeforePwyw().catch(
					(_err) => false,
				);
				window.location.href = `${PathsEnum.LANDING_PAGE_ACTIVATION_CONFIRMATION}?${region ? `region=${region.toString().toLowerCase()}&` : ''}${isBeforePwyw ? 'pwyw=false' : ''}`;
			} else {
				window.location.href = PathsEnum.HOME;
			}
		} catch (err: any) {
			if (err?.response?.status === 401) {
				setShowActivationLogin(true);
			} else {
				window.location.href = PathsEnum.HOME;
			}
		}
	};

	const handleLoginSuccess = async () => {
		await attemptActivation();
	};

	return (
		<StrictMode>
			<SidebarLayout
				menu={undefined}
				content={
					showActivationLogin ? (
						<TalentLoginForm handleLoginSuccess={handleLoginSuccess} />
					) : (
						<></>
					)
				}
				centerContent
			/>
		</StrictMode>
	);
}
