import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import HistoryIcon from '@mui/icons-material/History';
import IosShareIcon from '@mui/icons-material/IosShare';
import {
	Button,
	FormControl,
	IconButton,
	SelectChangeEvent,
} from '@mui/material';
import { Dayjs } from 'dayjs';
import { ChangeEvent, useState } from 'react';
import { sortedDropdownLabels } from '../../core/lookupUtils';
import {
	AnonymizedFields,
	ProfileStatusesEnum,
	WorkExperience,
} from '../../shared/classes/profile';
import {
	EmailCommunications,
	EmailTemplate,
	TalentForReviewers,
} from '../../shared/classes/talent';
import useScrumMaturityZustand from '../../zustand/ScrumMaturityZustand';
import OutlinedBox from '../TalentComponents/OutlinedBox';
import SmDatePicker from './SmFormComponents/SmDatePicker';
import SmSelect from './SmFormComponents/SmSelect';
import SmTextareaAutosize from './SmFormComponents/SmTextareaAutosize';
import SmWorkExperiencesInput from './SmFormComponents/SmWorkExperiencesInput';
import TalentReviewEmailForm from './TalentReviewEmailForm';
import './TalentReviewForm.css';

interface TalentReviewFormProps {
	talent: TalentForReviewers | undefined;
	urlId: string | undefined;
	handleStatusChange: (event: SelectChangeEvent<string>) => void;
	handleScheduledEvalDateChange: (event: Dayjs | null) => void;
	handleEvalNotesChange: (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => void;
	handleSuccessManagerNotesChange: (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => void;
	handleScrumMaturityChange: (event: SelectChangeEvent<string>) => void;
	handleAnonymizedFieldsChange: (
		fieldName: keyof AnonymizedFields,
	) => (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	handleWorkExperiencesAnonymousChange: (
		newWorkExperiences: WorkExperience[],
	) => void;
	handleEmailCommunicationsChange: (
		emailCommunications: EmailCommunications,
	) => void;
	emailTemplates: EmailTemplate[];
}

export default function TalentReviewForm({
	talent,
	urlId,
	handleStatusChange,
	handleScheduledEvalDateChange,
	handleEvalNotesChange,
	handleSuccessManagerNotesChange,
	handleScrumMaturityChange,
	handleAnonymizedFieldsChange,
	handleWorkExperiencesAnonymousChange,
	handleEmailCommunicationsChange,
	emailTemplates,
}: TalentReviewFormProps) {
	const { scrumMaturityLookup } = useScrumMaturityZustand();
	const [showLastEvalAnswer, setShowLastEvalAnswer] = useState(false);
	const profileStatuses = Object.values(ProfileStatusesEnum);

	const scrumMaturityOptions = sortedDropdownLabels(scrumMaturityLookup);

	const lastEvalAnswer = talent?.activityLog?.evalAnswerHistory?.at(-2);

	const lastEvalAnswerModalButton = (
		<IconButton
			onClick={() => setShowLastEvalAnswer(!showLastEvalAnswer)}
			sx={{
				fontSize: 15,
				color: '#555',
				border: '1px solid #bfbfbf',
				borderRadius: 20,
				backgroundColor: '#e7e7e7',
				marginBottom: '15px',
				padding: '5px 10px',
			}}>
			{showLastEvalAnswer ? 'Hide' : 'Show'} last submission&nbsp;
			<HistoryIcon />
		</IconButton>
	);

	let workExperienceBoxMaxWidth = '47%';

	return (
		<>
			<OutlinedBox
				title="Review"
				classes="sm-profile-preview-pink-outline-box"
				slot={
					<div className="sm-talent-review-container">
						<div className="sm-talent-review-status-container sm-form-inline-container">
							{talent?.profile?.status && (
								<div>
									<div className="flex-space-between">
										<Button
											className="sm-half-screen no-margin"
											variant="contained"
											disableElevation
											sx={{
												minHeight: '40px',
												maxWidth: 'fit-content',
												marginBottom: '15px',
												marginX: 'auto',
											}}
											startIcon={<ForwardToInboxIcon />}
											href={`mailto:${talent?.email}?body=Hi ${talent?.profile?.firstName},`}
											target="_blank"
											rel="noreferrer">
											{talent?.email}
										</Button>
										<Button
											className="sm-half-screen no-margin"
											variant="contained"
											disableElevation
											sx={{
												minHeight: '40px',
												maxWidth: 'fit-content',
												marginBottom: '15px',
												marginX: 'auto',
											}}
											startIcon={<IosShareIcon />}
											onClick={() => {
												navigator.clipboard.writeText(
													window.location
														.toString()
														.replace('reviewer/talents', 'profile'),
												);
											}}>
											Share profile
										</Button>
									</div>
									<FormControl>
										<div className="sm-form-inline-container sm-y-center">
											<SmSelect
												containerClass="sm-third-screen"
												label="Status"
												id="profile.status"
												value={talent?.profile.status}
												handleChange={handleStatusChange}
												options={
													talent?.profile.status === ProfileStatusesEnum.ACTIVE
														? profileStatuses
														: profileStatuses.filter(
																(status) =>
																	status !== ProfileStatusesEnum.ACTIVE,
															)
												}
											/>
											<SmSelect
												containerClass="sm-third-screen"
												label="Scrum maturity"
												id="evalData.scrumMaturity"
												value={
													scrumMaturityLookup[talent?.evalData?.scrumMaturity]
														.dropdownLabel
												}
												handleChange={handleScrumMaturityChange}
												options={scrumMaturityOptions}
											/>
											<SmDatePicker
												label="Review date"
												containerClass="sm-third-screen"
												fieldClass="sm-third-screen"
												handleChange={(newValue) =>
													handleScheduledEvalDateChange(newValue)
												}
												value={talent?.evalData?.scheduledEvalDate}
											/>
										</div>
									</FormControl>
								</div>
							)}
						</div>
						<div className="sm-form-inline-container">
							<SmTextareaAutosize
								containerClass="sm-half-screen"
								placeholder={`Feedback for ${talent?.profile?.firstName}`}
								id="evalNotes"
								handleChange={handleEvalNotesChange}
								value={talent?.evalData?.evalNotes}
								textareaAutosizeProps={{
									minRows: 20,
								}}
							/>
							<SmTextareaAutosize
								containerClass="sm-half-screen"
								placeholder="Success manager notes"
								id="successManagerNotes"
								handleChange={handleSuccessManagerNotesChange}
								value={talent?.evalData?.successManagerNotes}
								textareaAutosizeProps={{
									minRows: 20,
								}}
							/>
						</div>
						<div className="sm-form-inline-container">
							<SmTextareaAutosize
								containerClass="sm-half-screen"
								placeholder="About me"
								id="intro"
								value={talent?.profile?.intro}
							/>
							<SmTextareaAutosize
								containerClass="sm-half-screen"
								placeholder="About me anonymous"
								id="intro-anonymous"
								handleChange={handleAnonymizedFieldsChange('introAnonymous')}
								value={talent?.profile?.anonymizedFields?.introAnonymous}
							/>
						</div>
						{talent?.profile?.workExperiences?.length ? (
							<div
								className="sm-form-inline-container"
								style={{
									justifyContent: 'flex-start',
									gap: 50,
								}}>
								<SmWorkExperiencesInput
									workExperiences={talent?.profile?.workExperiences}
									onChange={() => {}}
									containerStyle={{ maxWidth: workExperienceBoxMaxWidth }}
									flexColumn={true}
									disabled={true}
									hideRemoveButton={true}
								/>
								<SmWorkExperiencesInput
									workExperiences={
										talent?.profile?.anonymizedFields?.workExperiencesAnonymous
									}
									onChange={handleWorkExperiencesAnonymousChange}
									containerStyle={{ maxWidth: workExperienceBoxMaxWidth }}
									flexColumn={true}
									hideRemoveButton={true}
									title={'Work experience anonymous'}
								/>
							</div>
						) : null}
						<div>
							{lastEvalAnswer ? lastEvalAnswerModalButton : null}
							{showLastEvalAnswer ? (
								<div
									className="sm-form-inline-container"
									style={{
										marginBottom: '25px',
										border: '1px solid #bfbfbf',
										borderRadius: '20px',
										padding: '20px',
										backgroundColor: '#e7e7e7',
									}}>
									<div>
										<p style={{ marginTop: 0 }}>Old</p>
										<em>{lastEvalAnswer?.evalAnswer}</em>
									</div>

									<div>
										<p style={{ marginTop: 0 }}>New</p>
										<strong>{talent?.profile?.evalAnswer}</strong>
									</div>
								</div>
							) : null}
							<div className="sm-form-inline-container">
								<SmTextareaAutosize
									containerClass="sm-half-screen"
									placeholder="Delivering value"
									id="eval-answer"
									value={talent?.profile?.evalAnswer}
								/>
								<SmTextareaAutosize
									containerClass="sm-half-screen"
									placeholder="Delivering value anonymous"
									id="eval-answer-anonymous"
									handleChange={handleAnonymizedFieldsChange(
										'evalAnswerAnonymous',
									)}
									value={talent?.profile?.anonymizedFields?.evalAnswerAnonymous}
								/>
							</div>
						</div>
						<div className="sm-form-inline-container">
							<SmTextareaAutosize
								containerClass="sm-half-screen"
								placeholder="What I enjoy doing outside of work"
								id="personal-interests"
								value={talent?.profile?.personalInterests}
							/>
							<SmTextareaAutosize
								containerClass="sm-half-screen"
								placeholder="What I enjoy doing outside of work anonymous"
								id="personal-interests-anonymous"
								value={
									talent?.profile?.anonymizedFields?.personalInterestsAnonymous
								}
								handleChange={handleAnonymizedFieldsChange(
									'personalInterestsAnonymous',
								)}
							/>
						</div>
						<hr />
						<TalentReviewEmailForm
							urlId={urlId}
							emailCommunications={talent?.emailCommunications}
							handleEmailCommunicationsChange={handleEmailCommunicationsChange}
							emailTemplates={emailTemplates}
						/>
					</div>
				}
			/>
		</>
	);
}
