import { ChangeEventHandler } from 'react';
import SmTextField from './SmTextField';

interface SmNumberFieldProps {
	label: string;
	name: string;
	value: number | null;
	handleChange: (value: number | null) => void;
	placeholder?: string;
	required?: boolean;
	notched?: boolean;
	maxValue?: number;
}

export default function SmNumberField({
	label,
	value,
	handleChange,
	name,
	placeholder = '',
	required = false,
	notched = false,
	maxValue = 9999999,
}: SmNumberFieldProps) {
	const handleNumberChange: ChangeEventHandler<
		HTMLInputElement | HTMLTextAreaElement
	> = (event) => {
		if (event.target.value === '') {
			handleChange(null);
			return;
		}
		const newValue = Number(event.target.value.replaceAll(',', '.'));
		if (!isNaN(newValue) && newValue >= 0 && newValue <= maxValue) {
			handleChange(newValue);
		}
	};

	return (
		<SmTextField
			aria-label={label}
			inputProps={{ inputMode: 'decimal' }}
			id={name}
			onChange={handleNumberChange}
			placeholder={placeholder}
			value={value ?? ''}
			name={name}
			label={label}
			required={required}
			SelectProps={{ notched }}
			InputLabelProps={{ shrink: notched }}
		/>
	);
}
