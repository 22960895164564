import { Button } from "@mui/material";
import { debugSendRecruiterDailyTalentUpdateEmail } from "../../api/admin";

export default function DebugForm() {
	return (
		<div>
			<h2>Debug</h2>
            <p>Press this button to send test recruiter update emails to dev@scrummatch.com. Will send many emails.</p>
			<Button
				variant="contained"
				onClick={() => {
					debugSendRecruiterDailyTalentUpdateEmail();
				}}>
				Test Recruiter Daily Talent Update Email
			</Button>
		</div>
	);
}
