import { Button, ButtonProps } from '@mui/material';
import { authenticateWithScrumMatchForRecruiter } from '../../api/auth';
import CircularProgress from '@mui/material/CircularProgress';
import { Fragment, ReactNode, useState } from 'react';

type ScrumMatchLoginButtonProps = ButtonProps & {
	label?: ReactNode;
	handleSuccess: (access_token: string) => Promise<void>;
	handleError: (error: any) => void;
	email: string;
	password: string;
	oneTimePassword: string;
	disabled: boolean;
};
export default function ScrumMatchLoginButton({
	label = 'Sign up with your email',
	disabled = false,
	handleSuccess,
	handleError,
	email,
	password,
	oneTimePassword,
}: ScrumMatchLoginButtonProps) {
	const [isLoading, setIsLoading] = useState(false);
	const handleLogin = async () => {
		try {
			setIsLoading(true);
			const response = await authenticateWithScrumMatchForRecruiter(
				email,
				password,
				oneTimePassword,
			);
			const access_token = response?.data?.access_token;
			await handleSuccess(access_token);
		} catch (error: any) {
			handleError(error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Button
			onClick={handleLogin}
			variant="login"
			disableElevation
			disabled={disabled}
			className="google-login-button"
			startIcon={
				<img
					src={require('../../assets/sm-logo.png')}
					alt={'ScrumMatch logo'}
					style={{ maxHeight: '24px' }}
				/>
			}>
			{label}
			<Fragment>
				{isLoading ? <CircularProgress color="inherit" size={20} /> : null}
			</Fragment>
		</Button>
	);
}
