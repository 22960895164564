import { StrictMode, useEffect } from 'react';
import TalentSidebarMenu from '../../components/LayoutComponents/SidebarComponents/TalentSidebarMenu';
import SmInterceptor from '../../components/SmInterceptor';
import AccountSettings from '../../components/TalentComponents/AccountSettings';
import SidebarLayout from '../../layouts/SidebarLayout';

export default function ManageAccountPage() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<StrictMode>
			<SmInterceptor />
			<SidebarLayout
				menu={<TalentSidebarMenu />}
				content={<AccountSettings />}
			/>
		</StrictMode>
	);
}
