import {
	FormControl,
	InputLabel,
	OutlinedInput,
	OutlinedInputProps,
} from '@mui/material';
import { ChangeEvent } from 'react';

type SmInputProps = {
	placeholder: string;
	id: string;
	value?: string;
	containerClass?: string;
	handleChange?: (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => void;
} & { inputProps?: OutlinedInputProps };

export default function SmInput({
	placeholder,
	id,
	value = '',
	containerClass = 'sm-full-screen',
	handleChange = undefined,
	inputProps,
}: SmInputProps) {
	return (
		<FormControl
			className={`sm-label ${containerClass}`}
			sx={{ minWidth: 300 }}>
			<InputLabel
				htmlFor={id}
				variant="standard"
				shrink={false}
				className="sm-input-label">
				{placeholder}
			</InputLabel>
			<OutlinedInput
				id={id}
				onChange={handleChange}
				value={value}
				area-label={placeholder}
				disabled={!handleChange}
				style={{
					backgroundColor: handleChange ? '#fff' : '#f0f0f0',
					borderRadius: '5px',
				}}
				{...inputProps}
			/>
		</FormControl>
	);
}
