import { Button, ButtonProps, CircularProgress } from '@mui/material';
import { useState } from 'react';

type LoadingButtonProps = ButtonProps & {
	onClick: () => Promise<void>;
};

export default function LoadingButton(props: LoadingButtonProps) {
	const [isLoading, setIsLoading] = useState(false);

	const handleClick = async () => {
		setIsLoading(true);
		await props.onClick().finally(() => setIsLoading(false));
	};

	return (
		<Button {...props} onClick={handleClick}>
			{isLoading ? (
				<CircularProgress color="inherit" size={22} />
			) : (
				props.children
			)}
		</Button>
	);
}
