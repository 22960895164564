import { Checkbox, FormControlLabel, Tooltip } from '@mui/material';

import InfoIcon from '@mui/icons-material/Info';
import { ChangeEvent } from 'react';

interface SmCheckboxProps {
	checkboxLabel: string;
	handleChange: (
		event: ChangeEvent<HTMLInputElement>,
		checked: boolean,
	) => void;
	tooltipText?: string;
	checkboxName?: string;
	isChecked?: boolean;
	isRequired?: boolean;
	showInfoIcon?: boolean;
	disabled?: boolean;
	noMargin?: boolean;
}
export default function SmCheckbox({
	checkboxLabel,
	handleChange,
	tooltipText = '',
	checkboxName = checkboxLabel.toLowerCase(),
	isChecked = false,
	isRequired = false,
	showInfoIcon = false,
	disabled = false,
	noMargin = false,
}: SmCheckboxProps) {
	const checkboxField = (
		<FormControlLabel
			label={checkboxLabel}
			control={
				<Checkbox
					required={isRequired}
					checked={isChecked ?? false}
					onChange={handleChange}
					name={checkboxName}
					disabled={disabled}
				/>
			}
		/>
	);
	return (
		<>
			{showInfoIcon ? (
				<Tooltip title={tooltipText} arrow>
					<div className={`sm-checkbox${noMargin ? ' no-margin' : ''}`}>
						{checkboxField}
						<InfoIcon className="sm-checkbox-icon" />
					</div>
				</Tooltip>
			) : (
				<div className={`sm-checkbox${noMargin ? ' no-margin' : ''}`}>{checkboxField}</div>
			)}
		</>
	);
}
