import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	FormLabel,
} from '@mui/material';
import { useState } from 'react';
import { ProfileFieldNamesEnum } from '../../../core/enums';
import { WorkModel } from '../../../shared/classes/profile';

interface WorkModelInputProps {
	handleChange: (
		event: React.ChangeEvent<HTMLInputElement>,
		checked: boolean,
	) => void;
	workModel?: WorkModel;
}

export default function WorkModelInput({
	handleChange,
	workModel,
}: WorkModelInputProps) {
	const [hasBeenTouched, setHasBeenTouched] = useState(false);
	const isNoneSelected =
		!workModel ||
		!(workModel?.hybrid || workModel?.onSite || workModel?.remote);
	const error = hasBeenTouched && isNoneSelected;

	const handleUpdate = (
		event: React.ChangeEvent<HTMLInputElement>,
		checked: boolean,
	) => {
		setHasBeenTouched(true);
		handleChange(event, checked);
	};

	return (
		<FormControl
			className="sm-custom-input"
			required
			error={error}
			component="fieldset"
			variant="standard">
			<FormLabel component="legend">
				{ProfileFieldNamesEnum.WORK_MODEL}
			</FormLabel>
			<FormGroup row>
				<FormControlLabel
					control={
						<Checkbox
							required={isNoneSelected}
							checked={workModel?.remote ?? false}
							onChange={handleUpdate}
							name={'remote'}
						/>
					}
					label="Remote"
				/>
				<FormControlLabel
					control={
						<Checkbox
							required={isNoneSelected}
							checked={workModel?.hybrid ?? false}
							onChange={handleUpdate}
							name={'hybrid'}
						/>
					}
					label="Hybrid"
				/>
				<FormControlLabel
					control={
						<Checkbox
							required={isNoneSelected}
							checked={workModel?.onSite ?? false}
							onChange={handleUpdate}
							name={'onSite'}
						/>
					}
					label="On-site"
				/>
			</FormGroup>
			<FormHelperText>Please select at least one</FormHelperText>
		</FormControl>
	);
}
